import { DataSets } from "../dataset/DataSets";
import { MatchingContactInformation } from "./MatchingContactInformation";
import { MatchingDate } from "./MatchingDate";
import { MatchingDigitalCurrency } from "./MatchingDigitalCurrency";
import { MatchingField } from "./MatchingField";
import { MatchingIdDoc } from "./MatchingIdDoc";
import { MatchingLink } from "./MatchingLink";
import { MatchingLocation } from "./MatchingLocation";
import { MatchingMrz } from "./MatchingMrz";
import { MatchingName } from "./MatchingName";
import { MatchingRisk } from "./MatchingRisk";
import { MatchingSource } from "./MatchingSource";

export class MatchingData {

    public id!:string;
	public dataID!:string;
	public entityType!:string;
	public names!:MatchingName[];
	public categories!:string[];
	public titles!:string[];
	public positions!:string[];
	public sex!:string;
	public originalScript!:string;
	public score!:number;
	public label!:string;
	public dataset!:DataSets;
	public bics!:string[];
	public leis!:string[];	
	public datesOfRegistry!:MatchingDate[];
	public placesOfRegistry!:MatchingLocation[]; 
	public cultureCodes!:string[];
	public datesOfBuild!:MatchingDate[];
	public flags!:MatchingLocation[];
	public nationalities!:MatchingLocation[];
	public datesOfBirth!:MatchingDate[];
	public addresses!:MatchingLocation[];
	public placesOfBirth!:MatchingLocation[];
	public mrzs!:MatchingMrz[];
	public identificationDocuments!:MatchingIdDoc[];
	public identityDocuments!:MatchingIdDoc[];
	public active!:Boolean;
	public datasets!:DataSets[];	
	public keywords!:string[];
	public sources!:MatchingSource[];
	public threshold!:number;
	public dataSetId!:string;
	public revision!:number;
	public createDT!:string;
	public updateDT!:string;
	public deleteDT!:string;
	public deceased!:string;
	public lastCheckedDT!:string;
	public digitalCurrencies!:MatchingDigitalCurrency[];
	public contactInformations!:MatchingContactInformation[];
	public additionalInformations!:MatchingField[];
	public risks!:MatchingRisk[];
	public links!:MatchingLink[];
	public version!:number;

}