import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { Request } from "../../model/common/Request";
import { OrganizationUnit } from "../../model/user/OrganizationUnit";
import { createOrganizationUnits, updateOrganizationUnits } from "../../store/orgunit/action";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { ApiKey } from "../../model/screena/ApiKey";

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,
    orgUnit?:OrganizationUnit,
    apikey?:ApiKey,
    create?:boolean,
    closePopup?:() => void,
    paginationRequest?:PaginationRequest
}

interface IntState {
    orgUnitName:string,  
    orgUnitDescription:string,
    orgUnitCode:string,
    orgunitApikey:string,
    orgunitSearchTreshold:number,
    orgunitSearchTresholdtxt:string,
    errors:{
        orgUnitName:string,
        orgUnitCode:string,
        orgunitSearchTreshold:string
    }
}

class OrgunitDetailsPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {
            orgUnitName:'',   
            orgUnitDescription:'',
            orgUnitCode:'',
            orgunitApikey:'',
            orgunitSearchTresholdtxt:'',
            orgunitSearchTreshold:0.6,
            errors:{
                orgUnitName:'',
                orgUnitCode:'',
                orgunitSearchTreshold:''
            }       
        }
    }

    public componentWillReceiveProps(nextProps: IntProps) {
        if (nextProps.create) {
            this.setState({
                orgUnitName : '',
                orgUnitCode : '',
                orgunitApikey:'',
                orgUnitDescription : '',
                orgunitSearchTresholdtxt : '',
                orgunitSearchTreshold: 0.6
            })
        } else if (nextProps.orgUnit) {             
          this.setState({
                orgUnitName : nextProps.orgUnit.name,
                orgUnitDescription : nextProps.orgUnit.description,
                orgUnitCode : nextProps.orgUnit.code,
                orgunitApikey : nextProps.orgUnit.apiKey,
                orgunitSearchTreshold : nextProps.orgUnit.defaultSearchScore,
                orgunitSearchTresholdtxt : Number(nextProps.orgUnit.defaultSearchScore).toString(),
          })

        }
           
      }

    public render() {    
 
        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "d-modal" : "c-popover c-lc-popover js-lc-context"} style={{ width: "50rem", height: "24rem" }}>
                    <div className="d-modal__view js-modal-view">
                        <div className="d-modal__head">
                            <legend className="d-modal__title">
                                {formatMessage('alert_details_information_title')}
                            </legend>
                            <div>                               
                                <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                    <svg className="o-icon o-icon--close o-icon--prepended">
                                        <title>{IconsEnum.CLOSE.title}</title>
                                        <use href={IconsEnum.CLOSE.url} />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="d-modal__view js-modal-view" style={{ margin: "1rem" }}>
                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_name')}*
                                </label>
                                <input
                                    className={this.state.errors && this.state.errors["orgUnitName"]? "o-input error" : "o-input"}
                                    name="orgUnitName"
                                    placeholder={formatMessage('common_name')}
                                    value={this.state.orgUnitName}
                                    onChange={this.changeOrgUnitName}
                                    style={{ marginTop: '2px' }}
                                />                                
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('orgunit_code')}*
                                </label>
                                <input
                                    className={this.state.errors && this.state.errors["orgUnitCode"]? "o-input error" : "o-input"}
                                    name="orgUnitCode"
                                    placeholder={formatMessage('orgunit_code')}
                                    value={this.state.orgUnitCode}
                                    onChange={this.changeOrgUnitCode}
                                    style={{ marginTop: '2px' }}
                                />                                
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_apikey')}
                                </label>
                                <input
                                    className={"o-input"}
                                    name="orgUnitApiKey"
                                    placeholder={formatMessage('common_apikey')}
                                    value={this.state.orgunitApikey}
                                    onChange={this.changeOrgUnitApiKey}
                                    type="password"
                                    style={{ marginTop: '2px' }}
                                />                                
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_searchtreshold')}
                                </label>
                                <input
                                    className={this.state.errors && this.state.errors["orgunitSearchTreshold"]? "o-input error" : "o-input"}
                                    name="orgUnitSearchTreshold"
                                    placeholder={formatMessage('common_searchtreshold')}
                                    value={this.state.orgunitSearchTresholdtxt}
                                    onChange={this.changeOrgUnitSearchTreshold}
                                    style={{ marginTop: '2px' }}
                                />                                
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_description')}
                                </label>
                                <input
                                    className="o-input"
                                    name="orgUnitDescription"
                                    placeholder={formatMessage('common_description')}
                                    value={this.state.orgUnitDescription}
                                    onChange={this.changeOrgUnitDescription}
                                    style={{ marginTop: '2px' }}
                                />
                            </div>

                        </div>

                        <div style={{display : 'flex'}}>
                                <button className="m-button m-button--small-margin-bottom" onClick={this.props.closePopup} style={{margin: '5px'}}>
                                    {formatMessage('button_cancel')}
                                </button> 
                                
                                <button className="m-button m-button--small-margin-bottom" onClick={this.saveOrgUnit} style={{margin: '5px'}}>
                                    {formatMessage('button_save')}
                                </button> 
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private changeOrgUnitName = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            orgUnitName: event.target.value
        });
    }

    private changeOrgUnitSearchTreshold = (event: { target: { value: any; }; }):void => {

        let errors = this.state.errors;
        if (Number.isNaN(Number(event.target.value))) {            
            errors.orgunitSearchTreshold = 'Not a valid treshold';
            
            this.setState({                        
                ...this.state,                     
                orgunitSearchTresholdtxt : event.target.value,
                errors:errors
                
            });
        } else {            
            errors.orgunitSearchTreshold = '';
            this.setState({                        
                ...this.state,      
                orgunitSearchTreshold: Number(event.target.value),
                orgunitSearchTresholdtxt : event.target.value
            });
        }
    }

    private changeOrgUnitApiKey = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            orgunitApikey: event.target.value
        });
    }

    private changeOrgUnitCode = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            orgUnitCode: event.target.value
        });
    }

    private changeOrgUnitDescription = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            orgUnitDescription: event.target.value
        });
    }

    private saveOrgUnit = () : void => {
        let noError:boolean = true;

        let errors = {
            orgUnitName  : '',
            orgUnitCode  : '',
            orgunitSearchTreshold : ''
        };

        if (!this.state.orgUnitName || this.state.orgUnitName==='') {
            errors.orgUnitName = 'No orgunit name';    
            noError = false;       
        }

        if (!this.state.orgUnitCode || this.state.orgUnitCode==='') {
            errors.orgUnitCode = 'No orgunit code';  
            noError = false;          
        }

        if (!noError) {
            this.setState({
                ...this.state, 
                errors:errors       
           })
    
           return;
        }
        
        let request:Request = new Request();
        if (this.props.create) {
            let orgunit:OrganizationUnit = new OrganizationUnit();
            orgunit.name = this.state.orgUnitName;
            orgunit.code = this.state.orgUnitCode;
            orgunit.description = this.state.orgUnitDescription;
            orgunit.apiKey = this.state.orgunitApikey;
            orgunit.defaultSearchScore = this.state.orgunitSearchTreshold;
            request.organizationUnit = orgunit;
            createOrganizationUnits(request, this.props.paginationRequest);
        } else if (this.props.orgUnit) {
            let orgunit:OrganizationUnit = this.props.orgUnit;
            orgunit.name = this.state.orgUnitName;
            orgunit.code = this.state.orgUnitCode;
            orgunit.description = this.state.orgUnitDescription;
            orgunit.apiKey = this.state.orgunitApikey;
            orgunit.defaultSearchScore = this.state.orgunitSearchTreshold;
            request.organizationUnit = orgunit;
            updateOrganizationUnits(request, this.props.paginationRequest);
           
        }

        if (this.props.closePopup) {
            this.props.closePopup();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    apikey : state.searchScreenaDatasetStore.apiKeyStat
})

export const OrgunitDetailsPopup = connect(mapStateToProps, null)(OrgunitDetailsPopupClass);