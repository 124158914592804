import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError, manageAxiosErrorScreena } from "../../common/helper/AxiosErrorManagement";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Request } from "../../model/common/Request";
import { Response } from "../../model/common/Response";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { Constants } from "./constant";

export const getPaginatedRiskPerson = (request:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.riskPersonsList, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.PERSONPAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getAmountOfRiskPerson = (request:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.riskPersonsCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.PERSONTOTALAMOUNT
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getRiskConfiguration = (screenakey? : string) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Segrate-API-Key': '' + screenakey
        }
    }

    Axios.get(URLConstants.segRateGetConfigurations, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data.responseDataObject[0],
            type: Constants.GETRISKCONFIGURATION
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);
        stopLoadingStatus();
    })
}

export const saveRiskConfiguration = (request:Request, screenakey? : string) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Segrate-API-Key': '' + screenakey
        }
    }

    Axios.post(URLConstants.segRateUploadConfigurations, JSON.stringify(request),axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        getRiskConfiguration(screenakey);
        return store.dispatch({
            payload: response.data,
            type: Constants.SAVERISKCONFIGURATION
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);
        stopLoadingStatus();
    })
}

export const deleteRiskConfiguration = (riskMappingId:string, screenakey? : string) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Segrate-API-Key': '' + screenakey
        }
    }

    Axios.delete(URLConstants.segRateDeleteRiskMapping + riskMappingId, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();      
        getRiskConfiguration(screenakey);
        return store.dispatch({
            payload: response.data,
            type: Constants.DELETERISKCONFIGURATION
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);
        stopLoadingStatus();
    })
}

// Global Risk Category Create and Update

export const saveGlobalCalcRiskConfiguration = (request:Request, scenario:string, screenakey? : string) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Segrate-API-Key': '' + screenakey
        }
    }

    Axios.post(URLConstants.segRateGlobalScoreCategoryCreate + scenario, JSON.stringify(request),axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        getRiskConfiguration(screenakey);
        return store.dispatch({
            payload: response.data,
            type: Constants.SAVEGLOBALCALCRISKCONFIGURATION
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);
        stopLoadingStatus();
    })
}

export const updateGlobalCalcRiskConfiguration = (request:Request, screenakey? : string) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Segrate-API-Key': '' + screenakey
        }
    }

    Axios.post(URLConstants.segRateGlobalScoreCategoryUpdate, JSON.stringify(request),axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        getRiskConfiguration(screenakey);
        return store.dispatch({
            payload: response.data,
            type: Constants.UPDATEGLOBALCALCRISKCONFIGURATION
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);
        stopLoadingStatus();
    })
}

// All scenario
export const getAllScenario = (screenakey? : string) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Segrate-API-Key': '' + screenakey
        }
    }

    Axios.get(URLConstants.segRateAllScenario, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data.responseDataObject[0],
            type: Constants.ALLSCENARIO
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);
        stopLoadingStatus();
    })
}

// Risk Global Score configuration
export const getRiskGlobalScoreConfiguration = (scenario:string, screenakey? : string) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Segrate-API-Key': '' + screenakey
        }
    }

    Axios.get(URLConstants.segRateRiskGlobalScoreConfiguration+scenario, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data.responseDataObject[0],
            type: Constants.GETRISKGLOBALSCORECONFIGURATION
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);
        stopLoadingStatus();
    })
}

export const getRiskPersonAsCSV = (request:PaginationRequest) => {
    displayLoadingStatus();
        
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Authorization': '' + localStorage.getItem('jwt'),             
            'Content-Type': 'application/json',
        },
        responseType : "blob"
    }

    Axios.post(URLConstants.riskPersonsasCsv, JSON.stringify(request), axioConf).then((response: AxiosResponse<any>) => {        
        stopLoadingStatus();        
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'person.zip'); //any other extension
        document.body.appendChild(link);
        link.click();  
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);       
        stopLoadingStatus();
    })
}

// 