import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { formatMessage } from "../../common/translate/Translate";
import { IRootState } from "../../store";

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,
    setName?:(name:string) => void,
    cancelPopup?:() => void,
    savePopup?:() => void
}

interface IntState {
    
}

class SavedSearchNamePopupClass extends React.Component<IntProps, IntState> {

  
    public render() {          
        return (
                <React.Fragment>    
                    <div className={this.props.visible===true?"d-modal": "c-popover c-lc-popover js-lc-context"} style={{width: "20rem", height: "7rem"}}>
                        <label className="o-label">{formatMessage('saved_search_name')}</label>
                        <input
                               className="o-input"
                               name="keyword"
                               placeholder={formatMessage('saved_search_name')}    
                               onChange={this.changeName}                          
                               style={{marginTop: '2px'}}              
                          /> 
                          <div style={{display : 'flex'}}>
                                <button className="m-button m-button--small-margin-bottom" onClick={this.props.cancelPopup} style={{margin: '5px'}}>
                                    {formatMessage('button_cancel')}
                                </button> 
                                <button className="m-button m-button--small-margin-bottom" onClick={this.props.savePopup} style={{margin: '5px'}}>
                                    {formatMessage('button_save')}
                                </button> 
                        </div>
                    </div>                
                </React.Fragment>
        );
    }

    private changeName = (event: { target: { value: any; }; }):void => {
        if (this.props.setName) {
            this.props.setName(event.target.value);
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
})

export const SavedSearchNamePopup = connect(mapStateToProps, null)(SavedSearchNamePopupClass);