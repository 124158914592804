import React from "react";
import { connect } from "react-redux";

import '../../common/css/search.css'
import { getPersonNameFromMatchingName } from "../../common/helper/NameHelper";
import { formatMessage } from "../../common/translate/Translate";
import { AttributeDisplay } from "../../components/attribute/AttributeDisplay";
import { MultipleAttributeDisplay } from "../../components/attribute/MultipleAttributeDisplay";
import { MultipleAttributeDisplayMatchingName } from "../../components/attribute/MultipleAttributeDisplayMatchingName";
import { DataSets } from "../../model/dataset/DataSets";

import { MatchingResult } from "../../model/screena/MatchingResult";
import { IRootState } from "../../store";

interface IntProps {
    person?: MatchingResult,
    fullListOfDatasets?: DataSets[];
    searchName?: string,

    showTooltip?: (title: string[], textTooltip: string[]) => void
}

interface IntState {
    viewMore: boolean,
    viewWhy: boolean
}

class ResultCardClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = {
            viewMore: false,
            viewWhy: false
        }

    }

    public render() {
        let secondaryAttributesTotal: number = 0;
        if (this.props.person?.targetData[0].placesOfBirth && this.props.person?.targetData[0].placesOfBirth.length > 0) {
            secondaryAttributesTotal += 1;
        }

        if (this.props.person?.targetData[0].keywords && this.props.person?.targetData[0].keywords.length > 0) {
            secondaryAttributesTotal += 1;
        }

        if (this.props.person?.targetData[0].dataID && this.props.person?.targetData[0].dataID !== '') {
            secondaryAttributesTotal += 1;
        }

        if (this.props.person?.targetData[0].identificationDocuments && this.props.person?.targetData[0].identificationDocuments.length > 0) {
            secondaryAttributesTotal += 1;
        }

        if (this.props.person?.targetData[0].identityDocuments && this.props.person?.targetData[0].identityDocuments.length > 0) {
            secondaryAttributesTotal += 1;
        }

        if (this.props.person?.targetData[0].sources && this.props.person?.targetData[0].sources.length > 0) {
            secondaryAttributesTotal += 1;
        }

        if (this.props.person?.targetData[0].categories && this.props.person?.targetData[0].categories.length > 0) {
            secondaryAttributesTotal += 1;
        }

        if (this.props.person?.targetData[0].additionalInformations && this.props.person?.targetData[0].additionalInformations.length > 0) {
            secondaryAttributesTotal += 1;
        }

        if (this.props.person?.targetData[0].contactInformations && this.props.person?.targetData[0].contactInformations.length > 0) {
            secondaryAttributesTotal += 1;
        }

        if (this.props.person?.targetData[0].digitalCurrencies && this.props.person?.targetData[0].digitalCurrencies.length > 0) {
            secondaryAttributesTotal += 1;
        }

        if (this.props.person?.targetData[0].positions && this.props.person?.targetData[0].positions.length > 0) {
            secondaryAttributesTotal += 1;
        }

        if (this.props.person?.targetData[0].risks && this.props.person?.targetData[0].risks.length > 0) {
            secondaryAttributesTotal += 1;
        }

        if (this.props.person?.targetData[0].links && this.props.person?.targetData[0].links.length > 0) {
            secondaryAttributesTotal += 1;
        }
        
        let classification: string = this.getClassificationFromDatasetLabel();

        return (
            <li style={{padding:'0px', margin:'0px' }}>
                <div className="card" style={{marginLeft:'0px'}}>
                    <div className="card header">
                        <h3 className="card header main-name">{getPersonNameFromMatchingName(this.props.person?.matchingNames[0])}</h3>
                        <div className="card-header-labels">
                            <div className="card-header-label-entity-type">{this.props.person?.targetData[0].entityType}</div>
                            {classification && classification !== '' && <div className="card-header-label-classification">{classification}</div>}
                            {this.props.person?.targetData[0].dataset &&
                                <div className="card-header-label-dataset">{this.props.person?.targetData[0].dataset.label}</div>
                            }
                        </div>
                    </div>
                    <div className="card body">
                        {this.props.person?.targetData[0].names && this.props.person?.targetData[0].names.length > 0 &&
                            <MultipleAttributeDisplayMatchingName field={formatMessage('person_details_name')} value={this.props.person?.targetData[0].names[0]} />
                        }

                        {this.props.person?.targetData[0].names && this.props.person?.targetData[0].names.length > 1 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_alias')} value={this.props.person?.targetData[0].names.slice(1)} />
                        }

                        {this.props.person?.targetData[0].titles && this.props.person?.targetData[0].titles.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_titles')} value={this.props.person?.targetData[0].titles} noUpperCase={true} />
                        }

                        {this.props.person?.targetData[0].sex && this.props.person?.targetData[0].sex !== 'u' &&
                            <AttributeDisplay field={formatMessage('person_details_sex')} value={this.props.person?.targetData[0].sex} sex={true} />
                        }

                        {this.props.person?.targetData[0].bics &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_bic')} value={this.props.person?.targetData[0].bics} />
                        }

                        {this.props.person?.targetData[0].leis &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_lei')} value={this.props.person?.targetData[0].leis} />
                        }

                        {this.props.person?.targetData[0].nationalities && this.props.person?.targetData[0].nationalities.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_nationality')} value={this.props.person?.targetData[0].nationalities} />
                        }

                        {this.props.person?.targetData[0].datesOfBirth && this.props.person?.targetData[0].datesOfBirth.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_dob')} value={this.props.person?.targetData[0].datesOfBirth} />
                        }

                        {this.props.person?.targetData[0].datesOfBuild && this.props.person?.targetData[0].datesOfBuild.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_dobuild')} value={this.props.person?.targetData[0].datesOfBuild} />
                        }

                        {this.props.person?.targetData[0].datesOfRegistry && this.props.person?.targetData[0].datesOfRegistry.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_doregistry')} value={this.props.person?.targetData[0].datesOfRegistry} />
                        }

                        {this.props.person?.targetData[0].flags && this.props.person?.targetData[0].flags.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_flags')} value={this.props.person?.targetData[0].flags} />
                        }

                        {this.props.person?.targetData[0].addresses && this.props.person?.targetData[0].addresses.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_location')} value={this.props.person?.targetData[0].addresses} location={true} />
                        }

                        {secondaryAttributesTotal > 0 && this.state.viewMore &&
                            <React.Fragment>
                                {this.props.person?.targetData[0].placesOfBirth && this.props.person?.targetData[0].placesOfBirth.length > 0 &&
                                    <MultipleAttributeDisplay field={formatMessage('person_details_pobs')} value={this.props.person?.targetData[0].placesOfBirth} location={true} />
                                }

                                {this.props.person?.targetData[0].placesOfRegistry && this.props.person?.targetData[0].placesOfRegistry.length > 0 &&
                                    <MultipleAttributeDisplay field={formatMessage('person_details_pors')} value={this.props.person?.targetData[0].placesOfRegistry} location={true} />
                                }

                                {this.props.person?.targetData[0].identificationDocuments && this.props.person?.targetData[0].identificationDocuments.length > 0 &&
                                    <MultipleAttributeDisplay field={formatMessage('person_details_idDoc')} value={this.props.person?.targetData[0].identificationDocuments} identificationDocument={true} />
                                }

                                {this.props.person?.targetData[0].identityDocuments && this.props.person?.targetData[0].identityDocuments.length > 0 &&
                                    <MultipleAttributeDisplay field={formatMessage('person_details_idDoc')} value={this.props.person?.targetData[0].identityDocuments} identificationDocument={true} />
                                }

                                {this.props.person?.targetData[0].categories && this.props.person?.targetData[0].categories.length > 0 &&
                                    <MultipleAttributeDisplay field={formatMessage('person_details_categories')} value={this.props.person?.targetData[0].categories} />
                                }

                                {this.props.person?.targetData[0].positions && this.props.person?.targetData[0].positions.length > 0 &&
                                    <MultipleAttributeDisplay field={formatMessage('person_details_positions')} value={this.props.person?.targetData[0].positions} />
                                }

                                {this.props.person?.targetData[0].keywords && this.props.person?.targetData[0].keywords.length > 0 &&
                                    <MultipleAttributeDisplay field={formatMessage('person_details_programs')} value={this.props.person?.targetData[0].keywords} />
                                }

                                {this.props.person?.targetData[0].risks && this.props.person?.targetData[0].risks.length > 0 &&
                                    <MultipleAttributeDisplay field={formatMessage('person_details_risks')} value={this.props.person?.targetData[0].risks} />
                                }

                                {this.props.person?.targetData[0].links && this.props.person?.targetData[0].links.length > 0 &&
                                    <MultipleAttributeDisplay field={formatMessage('person_details_links')} value={this.props.person?.targetData[0].links} />
                                }

                                {this.props.person?.targetData[0].sources && this.props.person?.targetData[0].sources.length > 0 &&
                                    <MultipleAttributeDisplay field={formatMessage('person_details_sources')} value={this.props.person?.targetData[0].sources} urls={true} sources={true}/>
                                }
                                {this.props.person?.targetData[0].additionalInformations && this.props.person?.targetData[0].additionalInformations.length > 0 &&
                                    <MultipleAttributeDisplay field={formatMessage('person_details_addinfo')} value={this.props.person?.targetData[0].additionalInformations} noUpperCase={true} />
                                }
                                {this.props.person?.targetData[0].contactInformations && this.props.person?.targetData[0].contactInformations.length > 0 &&
                                    <MultipleAttributeDisplay field={formatMessage('person_details_contactinfo')} value={this.props.person?.targetData[0].contactInformations} noUpperCase={true} />
                                }
                                {this.props.person?.targetData[0].digitalCurrencies && this.props.person?.targetData[0].digitalCurrencies.length > 0 &&
                                    <MultipleAttributeDisplay field={formatMessage('person_details_digitalcurrency')} value={this.props.person?.targetData[0].digitalCurrencies} digitalCurrencies={true} noUpperCase={true} />
                                }

                                {this.props.person?.targetData[0].dataID &&
                                    <AttributeDisplay field={formatMessage('person_details_dataid')} value={this.props.person?.targetData[0].dataID} />
                                }
                            </React.Fragment>
                        }
                        <div className="card-body-btn-view-more-wrapper">
                            <button
                                className="card-body-btn-view-more"
                                onClick={this.setViewMoreOpened}
                            >
                                {secondaryAttributesTotal > 0 ? (
                                    (this.state.viewMore) ?
                                        <span className="card-body-btn-view-more opened">{formatMessage('search_hideallsecondaryattr')}</span>
                                        :
                                        <span className="card-body-btn-view-more closed">
                                            View {secondaryAttributesTotal} more attributes
                                        </span>
                                ) : null}

                            </button>
                        </div>
                    </div>
                    <div className="card footer">
                        <div className={this.state.viewWhy ? "card footer footerTop opened" : "card footer footerTop"} onClick={this.setViewWhyOpened}>
                            {formatMessage('search_whyisitshown')}
                        </div>

                        {this.state.viewWhy &&
                            <div className="card footer footerDetail">
                                <ul className="card footer footerDetail ul">
                                    {this.props.person?.score &&
                                        <li className="card footer footerDetail li">
                                            <span className="card footer footerDetail label">{formatMessage('search_score')}</span>
                                            <span className="card footer footerDetail value">
                                                <span className="card footer footerDetail value bar">
                                                    <span className="card footer footerDetail value bar-fill" style={
                                                        { width: `${Math.floor(this.props.person?.score * 100)}%` }
                                                    }></span>
                                                </span>
                                                {Math.floor(this.props.person?.score * 100)} %
                                            </span>
                                        </li>
                                    }
                                    <li className="card footer footerDetail li">
                                        <span className="card footer footerDetail label">
                                            {formatMessage('search_searched')}  <span className="separator separator-clear" /> {formatMessage('search_returned')}
                                        </span>
                                        <span className="card footer footerDetail value">
                                            {this.props.searchName}  <span className="separator" /> {getPersonNameFromMatchingName(this.props.person?.matchingNames[0])}
                                        </span>
                                    </li>
                                    <li className="card footer footerDetail li">
                                        <span className="card footer footerDetail label">
                                            {formatMessage('search_normalized')}
                                            &nbsp;
                                            <span
                                                className="tooltip-icon"
                                                onClick={this.showNormalizedToolTip}
                                            />
                                        </span>

                                        <span className="card footer footerDetail value normalized">
                                            {this.props.person?.sourceData && this.props.person?.sourceData.length > 0 && this.props.person?.sourceData[0].names
                                                && this.props.person?.sourceData[0].names.length > 0 && this.props.person?.sourceData[0].names[0].normalized}
                                            <span className="separator" />
                                            {this.props.person?.matchingNames && this.props.person?.matchingNames[0] && this.props.person?.matchingNames[0].normalized}
                                        </span>

                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </li>
        );
    }

    private showNormalizedToolTip = () => {
        if (this.props.showTooltip) {
            this.props.showTooltip(
                ['Before processing names, Screena automatically generates normalized versions of name tokens.',
                    'Normalization ensures consistency in how your name data is matched by:'],
                ['Switching all characters to lower case',
                    'Removing all diacritics (e.g.: accents)',
                    'Removing punctuation within words (e.g.: apostrophes)',
                    'Managing punctuation between words',
                    'Using transliteration mechanisms (when required). Screena will always return a romanized name.',
                    'Removing prefixes and suffixes (e.g.: Jr., Sr., Mr., PLC, www,...)',
                    'Harmonizing numbers in digits and letters (e.g.: 3 = three, 3rd = third, ...)'
                ]
            );
        }
    }

    private setViewMoreOpened = (e: any) => {
        this.setState({
            ...this.state,
            viewMore: !this.state.viewMore
        })
    }

    private setViewWhyOpened = (e: any) => {
        this.setState({
            ...this.state,
            viewWhy: !this.state.viewWhy
        })
    }

    private getClassificationFromDatasetLabel = (): string => {
        if (this.props.fullListOfDatasets) {
            for (let i = 0; i < this.props.fullListOfDatasets.length; i++) {
                if (this.props.fullListOfDatasets[i].label === this.props.person?.targetData[0].label) {
                    if (this.props.fullListOfDatasets[i].category !== '') {
                        return this.props.fullListOfDatasets[i].category;
                    }
                }
            }
        }

        return '';
    }
}


const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
    fullListOfDatasets: state.searchScreenaDatasetStore.datasets,
})

export const ResultCard = connect(mapStateToProps, null)(ResultCardClass);