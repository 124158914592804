export class HeaderConstant {

    // Main Tab
    public static SEARCH : string = "SEARCH";
    public static GENERAL : string = "GENERAL";
    public static ALERTS  : string = "ALERTS";
    public static APIKEY  : string = "APIKEY";

    public static RISKS  : string = "RISKS";
    public static ADMIN  : string = "ADMIN";
    public static ADDTAB  : string = "ADDTAB";
    public static DATASETS : string = "DATASETS";
    public static CUSTOMER : string = "CUSTOMER";
    public static WATCHLIST : string = "WATCHLIST";

    // Alert Popup Tab
    public static WKFCOMMENT : string = "WKFCOMMENT";
    public static COMMENT : string = "COMMENT";
    public static FILES : string = "FILES";
    public static TRANSACTIONS : string = "TRANSACTIONS";

    // Audit Tab Header
    public static USER : string = "USER";
    public static SCHEDULER_EXECUTION : string = "SCHEDULER_EXECUTION";
    public static SCREENA_CRAWL : string ="SCREENA_CRAWL";
    public static SCREENA_APPLICATION : string ="SCREENA_APPLICATION";    
    public static SCREENA_IMPORT : string = "SCREENA_IMPORT";
    public static SCREENA_WATCHLISTIMPORT : string = "SCREENA_WATCHLISTIMPORT";
    public static SCREENA_TASKS : string = "SCREENA_TASKS";
    public static SEGRATE_CONFIGURATION : string = "SEGRATE_CONFIGURATION";
    public static SEGRATE_ENTITY_RISK : string = "SEGRATE_ENTITY_RISK";
    public static SEGRATE_RULE_HIT : string = "SEGRATE_RULE_HIT";

    // Customer Tab Header
    public static RELATED_PERSONS : string = "RELATED_PERSONS";
    public static RELATED_ALERTS : string = "RELATED_ALERTS";
    public static RELATED_ACCOUNTS : string = "RELATED_ACCOUNTS";
    public static RELATED_TRXNS : string = "RELATED_TRXNS";
    public static RISK_DETAILS : string = "RISK_DETAILS";

    // Watchlist Tab Header
    public static RELATED_SOURCES : string = "RELATED_SOURCES";
    public static RELATED_ADDINFOS : string = "RELATED_ADDINFOS";
    public static RELATED_KEYWORDS: string = "RELATED_KEYWORDS";
    public static RELATED_COMMENTS: string = "RELATED_COMMENTS";
    public static RELATED_FILES: string = "RELATED_FILES";

    // Risk Configuration
    public static RULE : string = "RULES";
    public static GLOBALCALC  : string = "GLOBALCALC";
}