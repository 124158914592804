export interface IntIcon {
	name: string,
	url: string,
	title: string
}

export class IconsEnum {
	public static imageUrl: string = '/svg/';

	public static CARETLEFTCLOSE: IntIcon = { name: 'left', title: 'left', url: IconsEnum.imageUrl + 'caret-left.svg#caret-left' };
    public static DROPDOWN: IntIcon = { name: 'sort', title: 'sort', url: IconsEnum.imageUrl + 'dropdown.svg#dropdown' };

	public static MAXIMIZE: IntIcon = { name: 'maximize', title: 'maximize', url: IconsEnum.imageUrl + 'maximize.svg#maximize' };
	public static CLOSE: IntIcon = { name: 'close', title: 'close', url: IconsEnum.imageUrl + 'close.svg#close' };

	public static USER: IntIcon = { name: 'user', title: 'user', url: IconsEnum.imageUrl + 'sprite.svg#user' };

	public static LOGO: IntIcon = { name: 'logo', title: 'logo', url: IconsEnum.imageUrl + 'logo.svg#svg' };
	public static LOGOPNG: IntIcon = { name: 'logo', title: 'logo', url: IconsEnum.imageUrl + 'logo.png' };

	// Search Images
	public static BACKGROUND: IntIcon = { name: 'background-search', title: '', url: IconsEnum.imageUrl + 'hero.svg#user' };
	public static NOSEARCH: IntIcon = { name: 'no-search', title: '', url: IconsEnum.imageUrl + 'no-search.svg#user' };
}