import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { AxiosJson } from "../../common/service/AxiosConfig";
import { JwTResponse } from "../../model/user/JwTResponse";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { Constants } from "./constant";
import { JwTRequest } from "../../model/user/JwTRequest";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";

export const authenticateUser = (request:JwTRequest) => {
    displayLoadingStatus();
    Axios.post(URLConstants.login, JSON.stringify(request), AxiosJson).then((response: AxiosResponse<JwTResponse>) => {    
        stopLoadingStatus();       
            return store.dispatch({
                payload: response.data,
                type: Constants.AUTHENTICATE
            });   
    })
    .catch((error: AxiosError<String>) => {
        stopLoadingStatus();
        return store.dispatch({
            payload: error.response,
            type: Constants.FAILEDAUTHENTICATE
        }); 
    })
}


export const setToken = (token:string | null) => {  
    return store.dispatch({
              payload: token?.replace('Bearer ',''),
             type: Constants.SETAUTHENTICATE
    });   
}

export const logout = () => {
    displayLoadingStatus();
    Axios.post(URLConstants.logout, '{}', AxiosJson).then((response: AxiosResponse<JwTResponse>) => {    
        stopLoadingStatus();       
            return store.dispatch({
                payload: response.data,
                type: Constants.LOGOUT
            });   
    })
    .catch((error: AxiosError<String>) => {
        stopLoadingStatus();
        return store.dispatch({
            payload: error.response,
            type: Constants.LOGOUT
        }); 
    })  
}

export const getScreenApiKey = () => {
    displayLoadingStatus();

    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.screenaApiKey, axioConf).then((response: AxiosResponse<JwTResponse>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.SCREENAAPIKEY
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}