import React from "react";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { formatMessage } from "../../common/translate/Translate";
import { DatasetConstant } from "../../common/constant/DatasetConstant";

interface IntProps {
    visible?:boolean,
    type?:string,
    x?:number,
    y?:number,
    showCustomerDetails?: (e: any) => void,
}

interface IntState {
    
}

export class DataSetContextMenu extends React.Component<IntProps, IntState> {

     public render() {    
        return (
                <React.Fragment>   
                        <div className={this.props.visible===true?"c-popover-right-click c-lc-popover.is-visible js-lc-context" : "c-popover c-lc-popover js-lc-context"}
                                        style={{position: 'absolute' , left:this.props.x + 'px', top:this.props.y+'px'}}>
                            <div className="c-popover__body">
                                <ul className="d-list d-list--borders">
                                    <li className="d-list__item">
                                        <ul className="d-list d-list--borders">
                                            <li className="d-list__item">                                                
                                                <button className="m-button m-button--inline" type="button" onClick={this.props.showCustomerDetails}>
                                                    {this.props.type && this.props.type === DatasetConstant.WATCHLIST 
                                                        ?formatMessage('alert_context_showwatchlist_details')
                                                        :formatMessage('alert_context_showcustomer_details')
                                                    }
                                                </button>                                                                                                                     
                                            </li>
                                        </ul>
                                    </li>
                                </ul>                                
                            </div>
                        </div>
                </React.Fragment>
        );
    }
}