export class ApiKey {

    public apiKey!:string;
    public organization!:string;
    public email!:string;
    public purchaseOrder!:string;

    public dailyAmountOfRequest!:number;
    public monthlyAmountOfRequest!:number;

}
