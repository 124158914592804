import { Alerts } from "../alerts/Alerts";
import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/tabs.css'
import '../../common/css/tooltip.css'
import { IRootState } from "../../store";
import { HeaderConstant } from "../../common/constant/HeaderConstant";
import { formatMessage } from "../../common/translate/Translate";
import { Risk } from "../risk/Risk";
import BottomNotification from "../notification/BottomNotification";
import { Administration } from "../administration/Administration";
import { getFullListOfGroups } from "../../store/groups/action";
import { getFullListOfUsers, getUserDashboardPreferences } from "../../store/user/action";
import { Datasets } from "../datasets/Datasets";
import { CustomerTab } from "../customer/CustomerTab";
import { TabData } from "../../model/tab/TabData";
import { Person } from "../../model/persons/Person";
import { WatchlistTab } from "../watchlist/WatchlistTab";
import { ChangePasswordPopup } from "../user/ChangePassword";
import { UserMenu } from "../user/UserMenu";
import { UserPreferencesPopup } from "../user/UserPreferences";
import { RedirectToLginPage } from "../../common/helper/AxiosErrorManagement";
import { Dashboard } from "../dashboard/Dashboard";
import { getAlertStats, getPaginatedAlert, getTotalAmountOfAlert } from "../../store/alerts/action";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { getAmountOfRiskPerson, getPaginatedRiskPerson } from "../../store/risk/action";
import { getSavedSearch } from "../../store/savedsearch/action";
import { getAllDatasets, getCustomerDatasets } from "../../store/dataset/action";
import { User } from "../../model/user/User";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { getAllCountries } from "../../store/country/action";
import { isUserHasPermission } from "../../common/helper/PermissionHelper";
import { Permissions } from "../../common/constant/Permissions";
import { Search } from "../search/Search";
import { ToolTip } from "../../components/tooltip/ToolTip";
import { getFullListOfWkfStatus } from "../../store/workflow/action";
import { getScreenaApiKeyStats, getScreenaDataSets } from "../../store/search/action";
import { logout } from "../../store/login/action";
import { deleteCookie } from "../../common/helper/CookieHelper";

interface IntProps {
    isLoading?: Boolean,
    token?: string,
    currentUser?: User,
    screenaKey?: string
}

interface IntState {
    tabSelected: string,
    tabDatas: TabData[],

    userMenuVisible: boolean,
    userMenuVisibleX: number,
    userMenuVisibleY: number,

    changePasswordVisible: boolean,
    userPreferenceVisible: boolean,

    showToolTip: boolean,
    titleToolTip: string[],
    textToolTip: string[]
}

class Main extends React.Component<IntProps, IntState> {

    private changeTab: boolean = true;
    private firstLoad: boolean = true;

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)

        this.state = {
            tabSelected: HeaderConstant.SEARCH,
            tabDatas: [],
            changePasswordVisible: false,
            userPreferenceVisible: false,
            userMenuVisibleX: 0,
            userMenuVisibleY: 0,
            userMenuVisible: false,

            showToolTip: false,
            titleToolTip: [],
            textToolTip: []
        }

        getFullListOfGroups();
        getFullListOfUsers();
        getFullListOfWkfStatus();
        getAllCountries();
        getUserDashboardPreferences();

        let paginationRequest: PaginationRequest = new PaginationRequest();
        paginationRequest.pageNumber = 0;
        paginationRequest.maxPerPage = 10;
        getTotalAmountOfAlert(paginationRequest);
        getPaginatedAlert(paginationRequest);  
        
        getScreenaApiKeyStats(this.props.screenaKey);
    }

    public render() {

        if (!this.props.currentUser || !this.props.token) {
            RedirectToLginPage();
            return;
        }

        let header: Array<{ id: string, name: string, visibleTab: string, closeAble: boolean }> = this.getHeader();

        if (this.firstLoad) {
            let pageSelected : string | null = localStorage.getItem('Page');

            if (pageSelected && pageSelected!==null) {
                this.changeTab = true;
                this.firstLoad = false;
                this.setState({
                    ...this.state,
                    tabSelected: pageSelected
                });
            }
        }

        this.firstLoad = false;

        if (this.changeTab) {
            this.changeTab = false;
            if (this.state.tabSelected!==HeaderConstant.SEARCH) {
                localStorage.setItem('Page' , this.state.tabSelected);
            }
            if (this.state.tabSelected === HeaderConstant.GENERAL) {
                let paginationRequest: PaginationRequest = new PaginationRequest();
                paginationRequest.date1 = '01/01/1970';
                paginationRequest.date2 = '01/01/2050';
                getAlertStats(paginationRequest);
            } else if (this.state.tabSelected === HeaderConstant.ALERTS) {

                if (this.props.currentUser) {
                    getSavedSearch(this.props.currentUser.id);
                }

                getAllDatasets();

            } else if (this.state.tabSelected === HeaderConstant.RISKS) {
                getCustomerDatasets();

                let paginationRequest: PaginationRequest = new PaginationRequest();
                paginationRequest.pageNumber = 0;
                paginationRequest.maxPerPage = 10;
                getPaginatedRiskPerson(paginationRequest);
                getAmountOfRiskPerson(paginationRequest);
            } else if (this.state.tabSelected === HeaderConstant.SEARCH && this.props.screenaKey) {
                getScreenaDataSets(this.props.screenaKey);
            }
        }

        return (
            <React.Fragment>

                <UserMenu visible={this.state.userMenuVisible}
                    x={this.state.userMenuVisibleX} y={this.state.userMenuVisibleY}
                    showUserPreferences={this.showUserPreferences}
                    showChangePassword={this.showChangePassword}
                    logout={this.logout}
                />
                <ChangePasswordPopup visible={this.state.changePasswordVisible}
                    closePopup={this.showChangePassword}
                />
                <UserPreferencesPopup visible={this.state.userPreferenceVisible}
                    closePopup={this.showUserPreferences}
                />

                <div className={`d-loader js-loader ${this.props.isLoading ? 'is-loading' : ''}`} />
                <main className="c-main" onClick={this.resetMenu}>
                    <div className={this.state.showToolTip ? "normalizedTooltipBackdrop" : "normalizedTooltipBackdrop hidden"}
                        onClick={this.closeToolTip}
                    />
                    <ToolTip
                        show={this.state.showToolTip}
                        tolltipTitle={this.state.titleToolTip}
                        tooltiptext={this.state.textToolTip}
                        closeToolTip={this.closeToolTip}
                    />
                    <div className="c-content">
                        <div className="main-header" style={{ paddingTop: '24px', paddingBottom: '24px'}}>
                            <span style={{paddingLeft : '5rem', paddingRight: '20rem'}}>                                
                                <img src={IconsEnum.LOGOPNG.url} alt=" " />
                            </span>
                            <div className="main-up-menu">
                                <nav className="c-tabs__mainnav" style={{ marginLeft: '0px', marginRight: '0px', width: '100%', textAlign: 'left' }}>
                                    <ul className="c-tabs__list">
                                        {header.map((head, index) => {
                                            return (
                                                <li className="c-tabs__title" key={index}>
                                                    <button className={`c-tabs__link js-tabs-link ${this.state.tabSelected === head.visibleTab ? 'is-active' : ''}`}
                                                        id={head.id}
                                                        name={head.id} onClick={this.changeVisibility}
                                                        style={{ paddingTop: 0, fontSize:'0.5rem', marginRight: '0px' }}>
                                                        <span id={head.id} className="nav mainnav">{head.name}</span>
                                                    </button>
                                                    {head.closeAble &&
                                                        <button className={`c-tabs__link js-tabs-link ${this.state.tabSelected === head.visibleTab ? 'is-active' : ''}`}
                                                            style={{ marginLeft: '0px', paddingTop: 0 }}
                                                            id={head.id}
                                                            name={head.id}
                                                            onClick={this.closeTab}
                                                        >
                                                            <span id={head.id} className="nav mainnav">X</span>
                                                        </button>
                                                    }
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </nav>
                            </div>
                            <div className="main-up-menu" style={{ width: 'fit-content' }}>
                                <span onClick={this.showUserMenu} style={{ paddingRight: '10px' }}>
                                    <svg id="user" width="14px" height="12px" className="icon user" >
                                        <title></title>
                                        <use href={IconsEnum.USER.url} />
                                    </svg>
                                </span>
                            </div>
                        </div>

                        <div className="main" style={{ height: '100%', marginTop: '12px', marginLeft: '10px', marginRight: '5px', width: '94%' }}>
                             <div className={`${this.state.tabSelected !== HeaderConstant.SEARCH ? 'divHidden' : 'divFlex'}`} style={{ height: '100%', width: '100%'}}>
                                 <Search
                                     showTooltip={this.setShowToolTip}
                                 />
                             </div>
                            
                            <div className={`${this.state.tabSelected !== HeaderConstant.GENERAL ? 'divHidden' : 'divFlex'}`} style={{ height: '100%', width: '100%'}}>
                                <Dashboard />
                            </div>

                            <div className={`${this.state.tabSelected !== HeaderConstant.ALERTS ? 'divHidden' : 'divFlex'}`} style={{ height: '100%', width: '100%'}}>
                                <Alerts addPersonTab={this.addPersonTab} toRefresh={this.state.tabSelected === HeaderConstant.ALERTS} />
                            </div>

                            <div className={`${this.state.tabSelected !== HeaderConstant.RISKS ? 'divHidden' : 'divFlex'}`} style={{ height: '100%', width: '100%'}}>
                                <Risk addPersonTab={this.addPersonTab} />
                                </div>

                            <div className={`${this.state.tabSelected !== HeaderConstant.ADMIN ? 'divHidden' : 'divFlex'}`} style={{ height: '100%', width: '100%'}}>
                                <Administration />
                            </div>

                            <div className={`${this.state.tabSelected !== HeaderConstant.DATASETS ? 'divHidden' : 'divFlex'}`} style={{ height: '100%', width: '100%'}}>
                                <Datasets addPersonTab={this.addPersonTab} addWatchlistPersonTab={this.addWatchlistPersonTab} />
                            </div>

                            {this.state.tabDatas && this.state.tabDatas.map((value, index) => {
                                if (this.state.tabSelected === value.tabType + value.tabNumber) {

                                    if (value.tabType === HeaderConstant.WATCHLIST) {
                                        return (
                                            <WatchlistTab watchlistPerson={value.watchListPerson} visible={true} />
                                        );
                                    } else {
                                        return (
                                            <CustomerTab customerId={value.personId} visible={true} />
                                        );
                                    }
                                }

                                return '';
                            })

                            }

                        </div>
                        <BottomNotification />
                    </div>
                </main>
            </React.Fragment>
        )
    }

    private resetMenu = () => {
        if (this.state.userMenuVisible) {
            this.setState({
                ...this.state,
                userMenuVisible: false
            })
        }

    }

    private logout = () => {
        localStorage.removeItem('jwt');
        localStorage.removeItem('user');
        logout();
        deleteCookie("SESSION");
        RedirectToLginPage();
    }


    private setShowToolTip = (title: string[], textTooltip: string[]) => {
        this.setState({
            ...this.state,
            showToolTip: !this.state.showToolTip,
            titleToolTip: title,
            textToolTip: textTooltip
        })
    }

    private closeToolTip = () => {
        if (this.state.showToolTip) {
            this.setState({
                ...this.state,
                showToolTip: false,
                titleToolTip: [],
                textToolTip: []
            })
        }

    }

    private addPersonTab = (personId: number) => {
        let newTabDatas: TabData[] = this.state.tabDatas;

        // Check if the tab doesn't already exist
        let alreadyExist: boolean = false;
        let tabToBeSelected: string = '';
        for (var existingTab of newTabDatas) {
            if (existingTab.personId === personId && existingTab.tabType === HeaderConstant.CUSTOMER) {
                alreadyExist = true;
                tabToBeSelected = existingTab.tabType + existingTab.tabNumber;
            }
        }

        if (!alreadyExist) {
            let newTabData: TabData = new TabData();
            newTabData.tabNumber = newTabDatas.length;
            newTabData.tabType = HeaderConstant.CUSTOMER;
            newTabData.tabLabel = formatMessage('main_tab_header_customer');
            newTabData.personId = personId;
            newTabDatas.push(newTabData);
            tabToBeSelected = newTabData.tabType + newTabData.tabNumber;
        }

        this.setState({
            ...this.state,
            tabDatas: newTabDatas,
            tabSelected: tabToBeSelected
        })
    }

    private addWatchlistPersonTab = (personId: number, watchlistPerson: Person) => {
        let newTabDatas: TabData[] = this.state.tabDatas;

        let newTabData: TabData = new TabData();
        newTabData.tabNumber = newTabDatas.length;
        newTabData.tabType = HeaderConstant.WATCHLIST;
        newTabData.watchListPerson = watchlistPerson;
        newTabData.tabLabel = formatMessage('main_tab_header_watchlist');
        newTabData.personId = personId;
        newTabDatas.push(newTabData);

        this.setState({
            ...this.state,
            tabDatas: newTabDatas
        })
    }

    private changeVisibility = (event: any) => {
        this.changeTab = true;
        this.setState({
            ...this.state,
            userMenuVisible: false,
            tabSelected: event.target.id
        })
    }

    private showUserMenu = (event: any) => {
        getScreenaApiKeyStats(this.props.screenaKey);
        this.setState({
            ...this.state,
            userMenuVisible: !this.state.userMenuVisible,
            userMenuVisibleX: event.pageX,
            userMenuVisibleY: event.pageY,            
        })
    }

    private showUserPreferences = () => {
        this.setState({
            ...this.state,
            userMenuVisible: false,
            userPreferenceVisible: !this.state.userPreferenceVisible
        })
    }

    private showChangePassword = () => {
        this.setState({
            ...this.state,
            userMenuVisible: false,
            changePasswordVisible: !this.state.changePasswordVisible
        })
    }

    private closeTab = (event: any) => {
        let newTabDatas: TabData[] = [];

        for (var tabData of this.state.tabDatas) {
            let tabId: string = tabData.tabType + tabData.tabNumber;
            if (tabId !== String(event.target.id)) {
                newTabDatas.push(tabData);
            }
        }

        this.setState({
            ...this.state,
            tabDatas: newTabDatas,
            tabSelected:HeaderConstant.DATASETS
        })

    }

    private getHeader = () => {
        const header: Array<{ id: string, name: string, visibleTab: string, closeAble: boolean }> = [];

        if (isUserHasPermission(this.props.currentUser, Permissions.SEARCH)) {
            header.push({ id: HeaderConstant.SEARCH, name: formatMessage('main_tab_header_search'), visibleTab: HeaderConstant.SEARCH, closeAble: false });
        }

        if (isUserHasPermission(this.props.currentUser, Permissions.GENERAL)) {
            header.push({ id: HeaderConstant.GENERAL, name: formatMessage('main_tab_header_general'), visibleTab: HeaderConstant.GENERAL, closeAble: false });
        }

        if (isUserHasPermission(this.props.currentUser, Permissions.ALERTS)) {
            header.push({ id: HeaderConstant.ALERTS, name: formatMessage('main_tab_header_alerts'), visibleTab: HeaderConstant.ALERTS, closeAble: false });
        }

        if (isUserHasPermission(this.props.currentUser, Permissions.RISKS)) {
            header.push({ id: HeaderConstant.RISKS, name: formatMessage('main_tab_header_risks'), visibleTab: HeaderConstant.RISKS, closeAble: false });
        }

        if (isUserHasPermission(this.props.currentUser, Permissions.DATASETS)) {
            header.push({ id: HeaderConstant.DATASETS, name: formatMessage('main_tab_header_datasets'), visibleTab: HeaderConstant.DATASETS, closeAble: false });
        }

        if (isUserHasPermission(this.props.currentUser, Permissions.ADMINISTRATION)) {
            header.push({ id: HeaderConstant.ADMIN, name: formatMessage('main_tab_header_admin'), visibleTab: HeaderConstant.ADMIN, closeAble: false });
        }

        for (var tabData of this.state.tabDatas) {
            header.push({ id: tabData.tabType + tabData.tabNumber, name: tabData.tabLabel, visibleTab: tabData.tabType + tabData.tabNumber, closeAble: true });
        }

        return header;
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
    currentUser: state.userStore.currentUser,
    screenaKey: state.loginStore.screenaapikey,
    token: state.loginStore.jwtToken
})

export const MainHOC = connect(mapStateToProps, null)(Main);