import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError, manageAxiosErrorScreena } from "../../common/helper/AxiosErrorManagement";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Response } from "../../model/common/Response";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { Constants } from "./constant";

export const getUserPaginatedAudit = (request:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.auditUserList, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getUserTotalAmountOfAudit = (request:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.auditUserCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.TOTALAMOUNTOFAUDITS
        });        
    })
    .catch((error: AxiosError<String>) => {  
        manageAxiosError(error);      
        stopLoadingStatus();
    })
}

// Application Audit
export const getApplicationPaginatedAudit = (request:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.auditApplicationList, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        console.log(response.data);
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getApplicationTotalAmountOfAudit = (request:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.auditApplicationCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        console.log(response.data);
        return store.dispatch({
            payload: response.data,
            type: Constants.TOTALAMOUNTOFAUDITS
        });        
    })
    .catch((error: AxiosError<String>) => {  
        manageAxiosError(error);      
        stopLoadingStatus();
    })
}


// Scheduler Audit
export const getSchedulerPaginatedAudit = (request:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.auditSchedulerList, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getSchedulerTotalAmountOfAudit = (request:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.auditSchedulerCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.TOTALAMOUNTOFAUDITS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);        
        stopLoadingStatus();
    })
}

// SegRate
export const getSegRateConfigurationAudit = (request:PaginationRequest, screenakey:string) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'X-Segrate-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.segRateRiskConfigurationAudit, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);
        stopLoadingStatus();
    })
}

export const getSegRateConfigurationAuditCount = (request:PaginationRequest, screenakey:string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'X-Segrate-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.segRateRiskConfigurationAuditCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.TOTALAMOUNTOFAUDITS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);        
        stopLoadingStatus();
    })
}

export const getSegRateEntityRiskAudit = (request:PaginationRequest, screenakey:string) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'X-Segrate-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.segRateEntityScoringAudit, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);
        stopLoadingStatus();
    })
}

export const getSegRateEntityRiskAuditCount = (request:PaginationRequest, screenakey:string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'X-Segrate-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.segRateEntityScoringAuditCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.TOTALAMOUNTOFAUDITS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);        
        stopLoadingStatus();
    })
}

export const getSegRateRuleHitAudit = (screenakey:string) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'X-Segrate-API-Key': screenakey
        }
    }

    Axios.get(URLConstants.segRateRuleListAudit, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTSRULEAUDIT
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);
        stopLoadingStatus();
    })
}


// Screena Audit Crawl
export const getScreenaCrawlAudit = (request:PaginationRequest, screenakey : string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaAuditCrawl, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => {   
        manageAxiosErrorScreena(error);     
        stopLoadingStatus();
    })
}

export const getScreenaCrawlAuditCount = (request:PaginationRequest, screenakey : string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaAuditCrawlCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.TOTALAMOUNTOFAUDITS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);        
        stopLoadingStatus();
    })
}

// Screena import Crawl

export const getScreenaApplicationAudit = (request:PaginationRequest, screenakey : string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaAuditSystem, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);        
        stopLoadingStatus();
    })
}

export const getScreenaApplicationAuditCount = (request:PaginationRequest, screenakey : string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaAuditSystemCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.TOTALAMOUNTOFAUDITS
        });        
    })
    .catch((error: AxiosError<String>) => { 
        manageAxiosErrorScreena(error);       
        stopLoadingStatus();
    })
}

// Import Audit
export const getScreenaImportAudit = (request:PaginationRequest, screenakey : string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaAuditImport, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => { 
        manageAxiosErrorScreena(error);       
        stopLoadingStatus();
    })
}

export const getScreenaImportAuditCount = (request:PaginationRequest, screenakey : string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaAuditImportCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.TOTALAMOUNTOFAUDITS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);        
        stopLoadingStatus();
    })
}

// Watchlist import summary
export const getScreenaWatchListSummaryImportAudit = (screenakey : string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.get(URLConstants.screenaAuditImportSummary,axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => { 
        manageAxiosErrorScreena(error);       
        stopLoadingStatus();
    })
}


export const getScreenaWatchListErrorLogSummaryImportAudit = (request:PaginationRequest, screenakey : string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaAuditWatchListImportAuditErrorLog, JSON.stringify(request),axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => { 
        manageAxiosErrorScreena(error);       
        stopLoadingStatus();
    })
}

export const getScreenaWatchListErrorLogSummaryImportAuditCount = (request:PaginationRequest, screenakey : string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaAuditWatchListImportAuditErrorLogCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.TOTALAMOUNTOFAUDITS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);        
        stopLoadingStatus();
    })
}

// Watclist Import Audit
export const getScreenaWatchListImportAudit = (request:PaginationRequest, screenakey : string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaAuditWatchListImport, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => { 
        manageAxiosErrorScreena(error);       
        stopLoadingStatus();
    })
}

export const getScreenaWatchListImportAuditCount = (request:PaginationRequest, screenakey : string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaAuditWatchListImportCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.TOTALAMOUNTOFAUDITS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);        
        stopLoadingStatus();
    })
}

export const getScreenaWatchListImportAsCSV = (screenakey : string) => {
    displayLoadingStatus();
    
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        },
        responseType : "blob"
    }
        
    Axios.get(URLConstants.screenaAuditWatchlistImportDownloadAsCsv, axioConf).then((response: AxiosResponse<any>) => {        
        stopLoadingStatus();        
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'watchlist.zip'); //any other extension
        document.body.appendChild(link);
        link.click();  
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);
        stopLoadingStatus();
    })
}


// Tasks Audit
export const getScreenaTaskAudit = (request:PaginationRequest, screenakey : string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaTaskImport, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosErrorScreena(error);        
        stopLoadingStatus();
    })
}

export const getScreenaTaskAuditCount = (request:PaginationRequest, screenakey : string) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaTaskImportCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.TOTALAMOUNTOFAUDITS
        });        
    })
    .catch((error: AxiosError<String>) => { 
        manageAxiosErrorScreena(error);       
        stopLoadingStatus();
    })
}
