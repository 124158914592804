export enum Constants {
    FULLDATASETS = 'FULLDATASETS',
    CUSTOMERDATASETS = 'CUSTOMERDATASETS',
    PAGEDDATASETS = 'PAGEDDATASETS',     
    TOTALAMOUNTOFDATASETS = 'TOTALAMOUNTOFDATASETS',
    CREATEDATASET = 'CREATEDATASET',
    DELETEDATASETCONTENT = 'DELETEDATASETCONTENT',
    IMPORTFILES = 'IMPORTFILES',
    IMPORTFILESERRORCLEAN = 'IMPORTFILESERRORCLEAN',
    IMPORTFILESIMPORTING = 'IMPORTFILESIMPORTING',
    DELETEDATASET = 'DELETEDATASET',
    FULLDATASETSFOLDER = 'FULLDATASETSFOLDER',
    CREATEDATASETSFOLDER = 'CREATEDATASETSFOLDER',
    UPDATEDATASETSFOLDER = 'UPDATEDATASETSFOLDER',
}