import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";
import { Response } from "../../model/common/Response";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { Constants } from "./constant";


export const getRelatedAccountsToCustomer = (customerId:string) => {
    displayLoadingStatus();
    
    

    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.accountsRelated + customerId,  axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();        
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}