import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { Person } from "../../model/persons/Person";
import { IRootState } from "../../store";
import { File } from "../../model/file/File";
import { RowCardFile } from "../../components/file/RowCardFile";
import { getListOfFiles, uploadFilePerson } from "../../store/files/action";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { formatMessage } from "../../common/translate/Translate";

interface IntProps {
    person?:Person, 
    files?:File[]   
}

interface IntState {
    selectedFile : any
}

export class CustomerFilesClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);      
        
        this.state = {
            selectedFile : ''            
        }
    }

    componentDidMount() {
        if (this.props.person) {
            let request:PaginationRequest = new PaginationRequest();
            request.person = this.props.person;
            getListOfFiles(request);
        }
    }

    public render() {     
        return ( 
                <React.Fragment>   
                    {this.props.person &&
                        <React.Fragment>
                       
                        <div style={{overflowY:'auto', overflowX:'hidden', height:'38rem', paddingTop:'20px'}}>
                                                
                        <div style={{height:'2rem', paddingLeft:'20px'}}>
                            <span className="m-button m-button--small-margin-bottom" style={{width:'140px', height:'1.4rem'}}>
                                {formatMessage('button_upload')}
                                <input type="file"  onChange={this.onFileChange} className="btn-file"/>
                            </span>
                        </div>
                        {this.props.files && this.props.files.map((file, j) => { 
                                        return (                                            
                                            <RowCardFile file={file}/>
                                        );
                                    }
                                )}

                        {(!this.props.files || this.props.files.length===0) &&
                                <label className="o-label">{formatMessage('file_nodata')}</label>  
                            }
                        </div>
                        <div style={{height:'2rem'}}/>
                        </React.Fragment>
                    }                  
                </React.Fragment>
        );
    }

    private onFileChange = (event:any) => {
        let file:any = event.target.files[0];

        this.setState({
            ...this.state,
            selectedFile : event.target.files[0]
        })

        let formData:FormData = new FormData();
        formData.append("file", 
                    file,
                    file.name
        );

        formData.append("description", 
                'mùmùmùùm'
        );

        if (this.props.person) {
            formData.append("personId", 
                    String(this.props.person.id)
            );
        }

        if (this.props.person) {
            uploadFilePerson(formData, this.props.person);
        }
    }


}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    person : state.personStore.currentPerson,
    files : state.fileStore.listOfFiles
})

export const CustomerFiles = connect(mapStateToProps, null)(CustomerFilesClass);