import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { formatMessage } from "../../common/translate/Translate";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { IRootState } from "../../store";
import { DataSets } from "../../model/dataset/DataSets";
import { deleteDataset, getAllDatasetFolders, getPaginatedDatasets, getTotalAmountOfDataset } from "../../store/dataset/action";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { DatasetDetailsPopup } from "./DatasetDetailPopup";
import { AdminContextMenu } from "./AdminContextMenu";
import { DataSetsFolder } from "../../model/dataset/DataSetsFolder";
import { DatasetFolderDetailsPopup } from "./DatasetFolderDetailPopup";
import Select, { ActionMeta } from 'react-select';

interface IntProps {
    isLoading?:Boolean,
    datasets?:DataSets[],
    fullListOfDatasetsFolder?:DataSetsFolder[],
    totalAmountOfDatasets?:number
}

interface IntState {
    paginationRequest : PaginationRequest,
    selectedRow:number,
    contextMenuX:number,
    contextMenuY:number,
    contextMenuVisible:boolean,   
    
    datasetDetailPopupVisible:boolean,
    datasetCreate:boolean,

    datasetFolderDetailPopupVisible:boolean,
    datasetFolderCreate:boolean,

    currentFolderId : number,

}

class DatasetsClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = {
            paginationRequest : new PaginationRequest(),
            selectedRow: -1,
            contextMenuX: -1,
            contextMenuY: -1,
            contextMenuVisible: false,
            datasetDetailPopupVisible: false,
            datasetCreate: false,
            datasetFolderDetailPopupVisible: false,
            datasetFolderCreate: false,
            currentFolderId : -1
        }

        getPaginatedDatasets(this.state.paginationRequest);
        getTotalAmountOfDataset(this.state.paginationRequest);
        getAllDatasetFolders(this.state.paginationRequest);
    }

    private maxPage:number = 0;

    public render() {
        
        if (this.props.totalAmountOfDatasets) {
            this.maxPage = Math.ceil(this.props.totalAmountOfDatasets / this.state.paginationRequest.maxPerPage);           
        } else {
            this.maxPage = 0;
        }
        
        let listOfFolder:{value: string;label: string}[] = [];
        let choosenFolder:{value: string;label: string} = {
            value:'-1',
            label:'/'
        }

        listOfFolder.push({
            value:'-1',
            label:'/'
        })

        if (this.props.fullListOfDatasetsFolder) {
            for (var dsFolder of this.props.fullListOfDatasetsFolder) {
                listOfFolder.push(
                    {
                        value: String(dsFolder.id),
                        label: dsFolder.label
                    }
                );

                if (this.state.currentFolderId===dsFolder.id) {
                    choosenFolder = {
                        value: String(dsFolder.id),
                        label: dsFolder.label
                    };
                }
            }
        }

        

        return (
            <React.Fragment> 
                <DatasetDetailsPopup visible={this.state.datasetDetailPopupVisible}
                    closePopup={this.closeDatasetDetails}
                    dataset={this.getCurrentDataset(this.state.selectedRow)}
                    create={this.state.datasetCreate}
                    paginationRequest={this.state.paginationRequest}
                    currentFolderId={this.state.currentFolderId}
                />

                <DatasetFolderDetailsPopup visible={this.state.datasetFolderDetailPopupVisible}
                    closePopup={this.closeDatasetFolderDetails}                    
                    create={this.state.datasetFolderCreate}
                    paginationRequest={this.state.paginationRequest}
                    currentFolderId={this.state.currentFolderId}
                />

                <AdminContextMenu visible={this.state.contextMenuVisible} x={this.state.contextMenuX} y={this.state.contextMenuY}
                        editAction={this.displayDataSetDetails}
                        deleteAction={this.deleteDataset}
                />
                <div className="main-grids-visible" onContextMenu={this.onGridDisplayContextMenu}>  
                    <div style={{ marginLeft: '20px', marginRight: '10px', width: '98%', display: 'flex' }}>
                        <label className="o-label title" style={{ width: '98%'}}> {formatMessage('admin_main_dataset')}</label>
                        <button className="m-button m-button--small-margin-bottom"  onClick={this.createDataset} style={{marginTop: '5px', width: '10rem'}}>
                            {formatMessage('button_create')}
                        </button>
                        <button className="m-button m-button--small-margin-bottom"  onClick={this.createDataSetFolderDetails} style={{marginLeft : '5px' , marginTop: '5px', width: '10rem'}}>
                            {formatMessage('button_create_folder')}
                        </button>
                    </div>
                    <div className="grids-display">
                        <Select name="risk_conf_fieldname"                                                                                                                     
                                            className="basic-multi-select"
                                            value={choosenFolder}
                                            options={listOfFolder}
                                            classNamePrefix="select"
                                            onChange={this.changeCurrentFolder}
                        /> 
                        <table className="c-table js-table">
                            <thead className={`c-table__head`}>
                                <tr>
                                    <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="name" display="common_name"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="type" display="common_type"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="description" display="common_description"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="createdBy" display="common_createdBy"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="createdOn" display="common_createdOn"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="updatedBy" display="common_updatedBy"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="updatedOn" display="common_updatedOn"/>                                                            
                                </tr>
                            </thead>
                            <tbody className="c-table__body">
                                {this.props.datasets &&  this.props.datasets.map((rowElem, j) => {                                   
                                        return (
                                                <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} onClick={this.selectRow}
                                                   onDoubleClick={this.displayDataSetDetails}>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.label}</td>                                                    
                                                    <td className="c-table__data c-table__smallfont">{rowElem.type}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.description}</td>                                                                                                     
                                                    <td className="c-table__data c-table__smallfont">{rowElem.createdBy}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.creationDate}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updatedBy}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updatedDate}</td>   
                                                </tr>
                                            );
                                    }                          
                                )}
                            </tbody>                            
                        </table> 
                    </div>                   
                </div>  
            </React.Fragment>
        );
    }

    private changeCurrentFolder =  (value: {value: string;label: string;} | null, actionMeta: ActionMeta<{value: string;label: string;}>):void => {

        if (value) {
            
            if (value.value && Number(value.value)!==-1) {
                let paginationReq:PaginationRequest = this.state.paginationRequest;
                paginationReq.fromParentFolder = Number(value.value);
                getPaginatedDatasets(paginationReq);
                getAllDatasetFolders(paginationReq);
                this.setState({                        
                    ...this.state,      
                    currentFolderId: Number(value.value),
                    paginationRequest : paginationReq
                }); 
            } else {
                let paginationReq:PaginationRequest = new PaginationRequest();
                getPaginatedDatasets(paginationReq);
                getAllDatasetFolders(paginationReq);
                this.setState({                        
                    ...this.state,      
                    currentFolderId:-1,
                    paginationRequest : paginationReq
                });    
            }
            
                      
        } else {
            this.setState({                        
                ...this.state,      
                currentFolderId:-1
            });
        }  
    }

    private deleteDataset = () => {
        deleteDataset(String(this.state.selectedRow), this.state.paginationRequest);
        this.setState({
            ...this.state,
            contextMenuVisible: false
        })
    }

    private displayDataSetDetails = () => {
        this.setState({
            ...this.state,
            datasetDetailPopupVisible : true,
            datasetCreate:false,
            contextMenuVisible: false

        })
    }

    private closeDatasetDetails = () => {
        this.setState({
            ...this.state,
            datasetDetailPopupVisible : false,
            datasetCreate:false
        })
    }

    private createDataSetFolderDetails = () => {
        this.setState({
            ...this.state,
            datasetFolderDetailPopupVisible : true,
            datasetFolderCreate:true,
            contextMenuVisible: false

        })
    }

    private closeDatasetFolderDetails = () => {
        this.setState({
            ...this.state,
            datasetFolderDetailPopupVisible : false,
            datasetFolderCreate:false
        })
    }

    private createDataset = () => {
        this.setState({
            ...this.state,
            selectedRow:-1,
            datasetDetailPopupVisible : true,
            datasetCreate:true
        })
    }

    private getCurrentDataset = (datasetId: number) : DataSets => {
        if (this.props.datasets) {
            for (var dataset of this.props.datasets) {
                if (dataset.id===datasetId) {
                    return dataset;
                }
            }
        }        
        return new DataSets();
    }

    private onGridDisplayContextMenu = (event: any)  => {
        event.preventDefault();            
        this.setState({
            ...this.state,
            contextMenuVisible: true,
            contextMenuX: event.pageX ,
            contextMenuY: event.pageY ,
        })
    }

    private onChangeNext = () : void => {      
        if (this.state.paginationRequest.pageNumber<this.maxPage) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber+1;
            this.setState({
                ...this.state,              
                contextMenuVisible: false,
                paginationRequest:paginationRequest                
            })
        }        
    }

    private onChangePrevious = () : void => {
        if (this.state.paginationRequest.pageNumber>0) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber-1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
        }        
    }

    private changeInput = (e: any) => {
        const value = e.target? e.target.value: e.value;
        if (value === '') {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = value-1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
          return;
        }
        if (value < 1) {
          return;
        }       
      }

    private selectRow = (e: any) => {
        const value = e.target.parentNode? e.target.parentNode.id: e.id;

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            selectedRow:Number(value)
        })
    }

    private sortHeader = (e: any) => {
       
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    datasets : state.datasetStore.listOfDatasets,
    fullListOfDatasetsFolder : state.datasetStore.fullListOfDatasetsFolder,
    totalAmountOfDatasets : state.datasetStore.amountOfDatasets
})

export const Datasets = connect(mapStateToProps, null)(DatasetsClass);