import { Alert } from "../alerts/Alert";
import { Person } from "../persons/Person";

export class TabData {

    public tabNumber!:number;
    public tabType!:string;
    public tabLabel!:string;

    public personId!:number;
    public watchListPerson!:Person;
    public alert!:Alert;

}