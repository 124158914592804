import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError, manageAxiosErrorScreena } from "../../common/helper/AxiosErrorManagement";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Request } from "../../model/common/Request";
import { RequestDataObject } from "../../model/common/RequestDataObject";
import { RequestMetaData } from "../../model/common/RequestMetaData";
import { DataSets } from "../../model/dataset/DataSets";
import { MatchingAlgo } from "../../model/screena/MatchingAlgo";
import { MatchingData } from "../../model/screena/MatchingData";
import { MatchingDate } from "../../model/screena/MatchingDate";
import { MatchingIdDoc } from "../../model/screena/MatchingIdDoc";
import { MatchingLocation } from "../../model/screena/MatchingLocation";
import { MatchingName } from "../../model/screena/MatchingName";
import { Response } from "../../model/screena/Response";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { Constants } from "./constant";


let cancelToken:any;

export const searchScreenaFullUniqueDataSets = (requestPerson:string, requestDate:string, algoDate:string, requestCountry:string[], algoCountry:string, entityType:string[], datasets:string[]
                                                , currentClassification:string
                                                , paginationRequest:PaginationRequest, searchScore:number, screenakey : string | undefined, exportCsv:boolean, exportPdf:boolean, userId:string | undefined
                                                , dateFields:string[], countryFields:string[]) => {


    if (!requestPerson || requestPerson.length===0) {
        cleanSearch();
        return;
    }

    displayLoadingStatus();

    let requestDataObject : RequestDataObject = new RequestDataObject();
    requestDataObject.sourceData = [];

    let matchingData : MatchingData = new MatchingData();
    if (entityType.includes("bic")) {
        matchingData.bics = [];
        matchingData.bics.push(requestPerson);

        requestDataObject.bicAlgo = new MatchingAlgo();
        requestDataObject.bicAlgo.type = "exact_match";
    }

    if (entityType.includes("lei")) {
        matchingData.leis = [];
        matchingData.leis.push(requestPerson);

        requestDataObject.leiAlgo = new MatchingAlgo();
        requestDataObject.leiAlgo.type = "exact_match";
    }

    if (entityType.includes("idnumber")) {
        matchingData.identityDocuments = [];
        let matchiddoc : MatchingIdDoc = new MatchingIdDoc();
        matchiddoc.number = requestPerson;
        matchingData.identityDocuments.push(matchiddoc);

        requestDataObject.identityDocumentAlgo = new MatchingAlgo();
        requestDataObject.identityDocumentAlgo.type = "exact_match";
    }

    if (!entityType || entityType.length===0) {
        let matchingDataBic : MatchingData = new MatchingData();
        matchingDataBic.bics = [];
        matchingDataBic.bics.push(requestPerson);
        requestDataObject.sourceData.push(matchingDataBic);

        requestDataObject.bicAlgo = new MatchingAlgo();
        requestDataObject.bicAlgo.type = "exact_match";

        let matchingDataId : MatchingData = new MatchingData();
        let matchiddoc : MatchingIdDoc = new MatchingIdDoc();
        matchiddoc.number = requestPerson;
        matchingDataId.identityDocuments = [];
        matchingDataId.identityDocuments.push(matchiddoc);

        requestDataObject.sourceData.push(matchingDataId);

        requestDataObject.identityDocumentAlgo = new MatchingAlgo();
        requestDataObject.identityDocumentAlgo.type = "exact_match";

        let matchingDataLei : MatchingData = new MatchingData();
        matchingDataLei.leis = [];
        matchingDataLei.leis.push(requestPerson);
        requestDataObject.sourceData.push(matchingDataLei);

        requestDataObject.leiAlgo = new MatchingAlgo();
        requestDataObject.leiAlgo.type = "exact_match";
    }

    if (requestDate && requestDate!=='') {
        let matchingDate:MatchingDate = new MatchingDate();
        matchingDate.date = requestDate;

        if (entityType.includes("individual")) {
            if (dateFields.length===0 || dateFields.includes("field_dob")) {
                matchingData.datesOfBirth = [];
                matchingData.datesOfBirth.push(matchingDate);

                requestDataObject.dateOfBirthAlgo = new MatchingAlgo();
                requestDataObject.dateOfBirthAlgo.type = algoDate;
            }
        } else if (entityType.includes("organization")) {
            if (dateFields.length===0 || dateFields.includes("field_dor")) {
                matchingData.datesOfRegistry = [];
                matchingData.datesOfRegistry.push(matchingDate);

                requestDataObject.dateOfRegistryAlgo = new MatchingAlgo();
                requestDataObject.dateOfRegistryAlgo.type = algoDate;
            }
        } else if (entityType.includes("vessel")) {
            if (dateFields.length===0 || dateFields.includes("field_dobuild")) {
                matchingData.datesOfBuild = [];
                matchingData.datesOfBuild.push(matchingDate);

                requestDataObject.dateOfBuildAlgo = new MatchingAlgo();
                requestDataObject.dateOfBuildAlgo.type = algoDate;
            }
        } else {
            
            if (dateFields.length===0 || dateFields.includes("field_dob")) {
                matchingData.datesOfBirth = [];
                matchingData.datesOfBirth.push(matchingDate);

                requestDataObject.dateOfBirthAlgo = new MatchingAlgo();
                requestDataObject.dateOfBirthAlgo.type = algoDate;
                requestDataObject.dateOfBirthAlgo.optional = true;
            }

            if (dateFields.length===0 || dateFields.includes("field_dor")) {
                matchingData.datesOfRegistry = [];
                matchingData.datesOfRegistry.push(matchingDate);

                requestDataObject.dateOfRegistryAlgo = new MatchingAlgo();
                requestDataObject.dateOfRegistryAlgo.type = algoDate;
                requestDataObject.dateOfRegistryAlgo.optional = true;                
            }

            if (dateFields.length===0 || dateFields.includes("field_dobuild")) {
                matchingData.datesOfBuild = [];
                matchingData.datesOfBuild.push(matchingDate);

                requestDataObject.dateOfBuildAlgo = new MatchingAlgo();
                requestDataObject.dateOfBuildAlgo.type = algoDate;
                requestDataObject.dateOfBuildAlgo.optional = true;
            }

            requestDataObject.minimumAmountOfOptional = 1;
        }
    }

    if (requestCountry && requestCountry.length>0) {
        let allMatchingLocation:MatchingLocation[] = [];

        for (var country of requestCountry) {
            let matchingLocation:MatchingLocation = new MatchingLocation();
            matchingLocation.country = country;
            allMatchingLocation.push(matchingLocation);
        }

        if (entityType.includes("individual")) {
            if (countryFields.length===0 || countryFields.includes("field_pob")) {
                matchingData.placesOfBirth = allMatchingLocation;

                requestDataObject.placeOfBirthAlgo = new MatchingAlgo();
                requestDataObject.placeOfBirthAlgo.type = algoCountry;
                requestDataObject.placeOfBirthAlgo.optional = true;
            }

            if (countryFields.length===0 || countryFields.includes("field_nationality")) {
                matchingData.nationalities = allMatchingLocation;

                requestDataObject.nationalityAlgo = new MatchingAlgo();
                requestDataObject.nationalityAlgo.type = algoCountry;
                requestDataObject.nationalityAlgo.optional = true;
            }

        } else if (entityType.includes("organization")) {

            if (countryFields.length===0 || countryFields.includes("field_por")) {
                matchingData.placesOfRegistry = allMatchingLocation;

                requestDataObject.placeOfRegistryAlgo = new MatchingAlgo();
                requestDataObject.placeOfRegistryAlgo.type = algoCountry;
                requestDataObject.placeOfRegistryAlgo.optional = true;
            }
        } else if (entityType.includes("vessel")) {
            if (countryFields.length===0 || countryFields.includes("field_flag")) {
                matchingData.flags = allMatchingLocation;

                requestDataObject.flagAlgo = new MatchingAlgo();
                requestDataObject.flagAlgo.type = algoCountry;
                requestDataObject.flagAlgo.optional = true;
            }
        } else {
            if (countryFields.length===0 || countryFields.includes("field_pob")) {
                matchingData.placesOfBirth = allMatchingLocation;

                requestDataObject.placeOfBirthAlgo = new MatchingAlgo();
                requestDataObject.placeOfBirthAlgo.type = algoCountry;
                requestDataObject.placeOfBirthAlgo.optional = true;
            }

            if (countryFields.length===0 || countryFields.includes("field_nationality")) {
                matchingData.nationalities = allMatchingLocation;

                requestDataObject.nationalityAlgo = new MatchingAlgo();
                requestDataObject.nationalityAlgo.type = algoCountry;
                requestDataObject.nationalityAlgo.optional = true;
            }

            if (countryFields.length===0 || countryFields.includes("field_por")) {
                matchingData.placesOfRegistry = allMatchingLocation;

                requestDataObject.placeOfRegistryAlgo = new MatchingAlgo();
                requestDataObject.placeOfRegistryAlgo.type = algoCountry;
                requestDataObject.placeOfRegistryAlgo.optional = true;
            }

            if (countryFields.length===0 || countryFields.includes("field_flag")) {
                matchingData.flags = allMatchingLocation;

                requestDataObject.flagAlgo = new MatchingAlgo();
                requestDataObject.flagAlgo.type = algoCountry;
                requestDataObject.flagAlgo.optional = true;
            }
        }

        if (countryFields.length===0 || countryFields.includes("field_location")) {
            matchingData.addresses = allMatchingLocation;
            requestDataObject.addressAlgo = new MatchingAlgo();
            requestDataObject.addressAlgo.type = algoCountry;
            requestDataObject.addressAlgo.optional = true;
        }

        requestDataObject.minimumAmountOfOptional = 1;
    }

    if (!entityType.includes("bic")
        && !entityType.includes("lei")
        && !entityType.includes("idnumber")) {
            let matchingName : MatchingName = new MatchingName();
            matchingName.fullName = requestPerson;
            matchingData.names = [];
            matchingData.names.push(matchingName);
    }

    if (entityType && entityType.length>0 
        && !entityType.includes("bic")
        && !entityType.includes("lei")
        && !entityType.includes("idnumber")) {
        requestDataObject.entityTypeAlgo = new MatchingAlgo();
        requestDataObject.entityTypeAlgo.type = "exact_match";
        requestDataObject.entityTypeAlgo.exclude = [];

        if (!entityType.includes("individual")) {
            requestDataObject.entityTypeAlgo.exclude.push("individual");
        }

        if (!entityType.includes("organization")) {
            requestDataObject.entityTypeAlgo.exclude.push("organization");
        }

        if (!entityType.includes("vessel")) {
            requestDataObject.entityTypeAlgo.exclude.push("vessel");
        }
    
        if (entityType.includes("unknwon")) {            
            if (entityType.length===0) {
                matchingData.entityType = "null";
            } else {
                requestDataObject.entityTypeAlgo.nullMatch = false;
            }
            
        } else {
            requestDataObject.entityTypeAlgo.nullMatch = true;
        }
    }

    requestDataObject.sourceData.push(matchingData);

    if ((currentClassification!=='' && currentClassification!=='ALL') || (datasets && datasets.length>0)) {

        let matchingClassificationData : MatchingData = new MatchingData();
        matchingClassificationData.datasets = [];

        if (currentClassification!=='' && currentClassification!=='ALL') {
        
            let datasetClassification : DataSets = new DataSets();
            datasetClassification.category = currentClassification;
        
            
            matchingClassificationData.datasets.push(datasetClassification);
            
        }

        if (datasets && datasets.length>0) {

            for (let i=0; i<datasets.length;i++) {
                let datasetClassification : DataSets = new DataSets();
                datasetClassification.label = datasets[i];
                matchingClassificationData.datasets.push(datasetClassification);
            }
            
        }

        requestDataObject.targetData = [];
        requestDataObject.targetData.push(matchingClassificationData);
    } 
    /*
    else {
        let matchingClassificationData : MatchingData = new MatchingData();
        matchingClassificationData.datasets = [];

            
       let datasetClassification : DataSets = new DataSets();
       datasetClassification.category = 'SANCTION';
       matchingClassificationData.datasets.push(datasetClassification);     
       requestDataObject.targetData = [];
       requestDataObject.targetData.push(matchingClassificationData);
    }
    */
    if (!Number.isNaN(searchScore)) {
        requestDataObject.threshold = searchScore;
    }

    let request:Request = new Request();
    request.queries = [];
    request.queries.push(requestDataObject);

    if (userId) {
        let requestMetatdta = new RequestMetaData();
        requestMetatdta.userId = userId;
        request.requestHeader = requestMetatdta;
    }
    
    if (paginationRequest) {
        request.paginationRequest = paginationRequest;
    }
    
    if (exportCsv) {
        let axioConf:AxiosRequestConfig = {
            withCredentials : true,
            headers: {
                'Content-Type': 'application/json', 
                'X-Screena-API-Key': '' + screenakey,            
            },
            responseType : "blob"
        }
        
        Axios.post(URLConstants.screenaSearchInDatasetsEngineExport, JSON.stringify(request), axioConf).then((response: AxiosResponse<any>) => {        
            stopLoadingStatus();        
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'search.zip'); //any other extension
            document.body.appendChild(link);
            link.click();  
        })
        .catch((error: AxiosError<String>) => {
            manageAxiosErrorScreena(error);
            stopLoadingStatus();
        })
    } else if (exportPdf) {
        let axioConf:AxiosRequestConfig = {
            withCredentials : true,
            headers: {
                'Content-Type': 'application/json', 
                'X-Screena-API-Key': '' + screenakey,            
            },
            responseType : "blob"
        }
        
        Axios.post(URLConstants.screenaSearchInDatasetsEngineExportPDF, JSON.stringify(request), axioConf).then((response: AxiosResponse<any>) => {        
            stopLoadingStatus();        
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'search.pdf'); //any other extension
            document.body.appendChild(link);
            link.click();  
        })
        .catch((error: AxiosError<String>) => {
            manageAxiosError(error);
            stopLoadingStatus();
        })
    
    } else {
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
    
        cancelToken = Axios.CancelToken.source();
    
        let axioConf:AxiosRequestConfig = {
            cancelToken: cancelToken.token,
            withCredentials : true,
            headers: {
                'Content-Type': 'application/json', 
                'X-Screena-API-Key': '' + screenakey,            
            }
        }

        Axios.post(URLConstants.screenaSearchInDatasetsEngine, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => { 
            stopLoadingStatus();
            return store.dispatch({
                payload: response.data,
                type: Constants.SCREENASEARCH
            });        
        })
        .catch((error: AxiosError<String>) => {
            stopLoadingStatus();
            manageAxiosError(error);        
        })
    }

   
}

export const getScreenaDataSets = (screenakey : string | undefined) => {
    displayLoadingStatus();

    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': '' + screenakey
        }
    }

    Axios.get(URLConstants.screenaGetDatasets, axioConf).then((response: AxiosResponse<Response>) => { 
        console.log(response);
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.SCREENADATASETS
        });        
    })
    .catch((error: AxiosError<String>) => {
        stopLoadingStatus();
        manageAxiosErrorScreena(error);        
    })
}

export const getScreenaApiKeyStats = (screenakey : string | undefined) => {
    displayLoadingStatus();

    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': '' + screenakey
        }
    }

    Axios.get(URLConstants.screenaApiKeyStats, axioConf).then((response: AxiosResponse<Response>) => { 
        console.log(response);
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.SCREENASTATS
        });        
    })
    .catch((error: AxiosError<String>) => {
        stopLoadingStatus();
        manageAxiosErrorScreena(error);        
    })
}

export const cleanSearch = () => {

    return store.dispatch({
        type: Constants.CLEANSEARCH
    });

}

export const cleanSearchStats = () => {

    return store.dispatch({
        type: Constants.CLEANSEARCHSTATS
    });

}