import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css';
import '../../common/css/button.css';
import '../../common/css/radar.css';
import '../../common/css/table.css';
import '../../common/css/pagination.css';
import '../../common/css/attribute.css'

import { formatMessage } from "../../common/translate/Translate";
import { Alert } from "../../model/alerts/Alert";
import { IRootState } from "../../store";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { HeaderConstant } from "../../common/constant/HeaderConstant";
import { AlertCommentPopup } from "./AlertCommentPopup";
import { AlertWorkflowAuditDisplay } from "./AlertWorkflowAuditDisplay";
import { Person } from "../../model/persons/Person";
import { Name } from "../../model/persons/Name";
import { AlertFiles } from "./AlertsFiles";
import { MultipleAttributeDisplay } from "../../components/attribute/MultipleAttributeDisplay";
import { AttributeDisplay } from "../../components/attribute/AttributeDisplay";
import { getSingledAlert } from "../../store/alerts/action";
import { MultipleAttributeDisplayName } from "../../components/attribute/MultipleAttributeDisplayName";
import { Pagination } from "../../components/pagination/Pagination";
import { HeaderAttributeDisplay } from "../../components/attribute/HeaderAttributeDisplay";
import { capitalizeWords } from "../../common/helper/StringHelper";

interface IntProps {
    visible?: boolean,
    x?: number,
    y?: number,
    alert?: Alert,
    alertId?: number,
    closePopup?: () => void,
    refreshData?: () => void
}

interface IntState {
    tabSelected: string,

    matchingEntityDisplayed: number
}

class AlertDetailsPopupClass extends React.Component<IntProps, IntState> {

    private componentVisible: boolean;

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)

        this.state = {
            tabSelected: HeaderConstant.GENERAL,
            matchingEntityDisplayed: 0
        }

        this.componentVisible = false;
    }

    public render() {

        if (!this.componentVisible && this.props.visible && this.props.alertId) {
            this.componentVisible = true;
            getSingledAlert(this.props.alertId);
        }

        let header: Array<{ id: string, name: string, visibleTab: string }> = this.getHeader();
        let sourceName: Name;
        let targetName: Name = new Name();

        if (this.props.alert && this.props.alert.source) {
            sourceName = this.getSourceName(this.props.alert.source);
        } else {
            sourceName = new Name();
        }

        if (this.props.alert && this.props.alert.target && this.props.alert.target[this.state.matchingEntityDisplayed] && this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons
            && this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.names && this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.names.length > 0) {
            targetName.fullName = this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.names[0].fullName;
            targetName.givenName = this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.names[0].givenName;
            targetName.surname = this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.names[0].surname;
            targetName.fatherName = this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.names[0].fatherName;
        }

        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "c-popover c-lc-popover.is-visible js-lc-context" : "c-popover c-lc-popover js-lc-context"}
                    style={{ position: 'absolute', left: this.props.x + 'px', top: this.props.y + 'px' }}>
                    <div className={"d-modal"} style={{ width: "96%", height: "94%", paddingLeft: '4px', paddingRight: '4px', maxHeight:'96%' }}>
                        <div className="d-modal__view js-modal-view" style={{ width: "100%"}}>
                            <div className="d-modal__head">
                                <span className="d-modal__title">
                                    {formatMessage('alert_details_information_title')}
                                </span>
                                <button className="m-button m-button--close d-modal__close" type="button" onClick={this.closePopup}>
                                    <svg className="o-icon o-icon--close o-icon--prepended">
                                        <title>{IconsEnum.CLOSE.title}</title>
                                        <use href={IconsEnum.CLOSE.url} />
                                    </svg>
                                </button>
                            </div>
                            <nav className="c-tabs__nav">
                                <ul className="c-tabs__list">
                                    {header.map((head, index) => {
                                        return (
                                            <li className="c-tabs__title" key={index}>
                                                <button className={`c-tabs__link js-tabs-link ${this.state.tabSelected === head.visibleTab ? 'is-active' : ''}`}
                                                    id={head.id}
                                                    name={head.id} onClick={this.changeVisibility}>
                                                    <span id={head.id} className="nav">{head.name}</span>
                                                </button>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </nav>
                            {this.state.tabSelected === HeaderConstant.GENERAL && this.props.alert &&
                                <div className="d-modal__view js-modal-view" style={{ height: '50rem' }}>
                                    <div className="attribute_header_div" style={{ paddingTop: '22px', paddingLeft: '10px' }}>
                                        <HeaderAttributeDisplay field={formatMessage('alert_details_id')} value={String(this.props.alert.id)} />
                                        <HeaderAttributeDisplay field={formatMessage('alert_alertName')} value={String(this.props.alert.name)} />
                                        <HeaderAttributeDisplay field={formatMessage('alert_alertDescription')} value={String(this.props.alert.description)} />
                                        <HeaderAttributeDisplay field={formatMessage('alert_alertScore')} value={this.props.alert.score ? String(this.props.alert.score) : ''} />
                                        <HeaderAttributeDisplay field={formatMessage('alert_alertStatus')} value={this.props.alert.status ? String(this.props.alert.status.description) : ''} />
                                        <HeaderAttributeDisplay field={formatMessage('alert_alertAssignedTo')} value={this.props.alert.assignedToUser ? String(this.props.alert.assignedToUser.userName) : ''} />
                                        <HeaderAttributeDisplay field={formatMessage('alert_details_creationDate')} value={this.props.alert.creationDate} />
                                        <HeaderAttributeDisplay field={formatMessage('alert_details_createdBy')} value={this.props.alert.createdBy} />
                                        <HeaderAttributeDisplay field={formatMessage('alert_details_updatedDate')} value={this.props.alert.updatedDate} />
                                        <HeaderAttributeDisplay field={formatMessage('alert_details_updatedBy')} value={this.props.alert.updatedBy} />
                                    </div>

                                    {this.props.alert.source &&
                                        <div className="match-display" style={{ scrollBehavior: 'auto', overflow: 'auto', height: '100%' }}>
                                            <div className="source-display">
                                                <div className="entity-card--data" style={{ height: '75%' , scrollBehavior: 'auto', width: '99%'}}>
                                                    {this.getRowCard(capitalizeWords(sourceName ? sourceName.fullNameForDisplay : '')
                                                        , undefined
                                                        , this.props.alert.source.entityType
                                                        , this.props.alert.source.dataset ? this.props.alert.source.dataset.label : ''
                                                        , undefined
                                                        , this.props.alert.source.dataset ? this.props.alert.source.dataset.classification : '')}

                                                    <AttributeDisplay field={formatMessage('person_details_dataid')} value={this.props.alert.source.dataID} />
                                                    <MultipleAttributeDisplayName field={formatMessage('person_details_fullName')}
                                                        value={this.props.alert.source.names}
                                                        amountOnTarget={
                                                            (this.props.alert.target && this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.names)
                                                                ? this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.names.length
                                                                : 0}
                                                    />
                                                    <AttributeDisplay field={formatMessage('person_details_sex')} value={this.props.alert.source.sex} sex={true} />

                                                    {this.props.alert.source.datesOfBirth &&
                                                        <MultipleAttributeDisplay field={formatMessage('person_details_dob')} value={this.props.alert.source.datesOfBirth}
                                                            amountOnTarget={
                                                                (this.props.alert.target && this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.datesOfBirth)
                                                                    ? this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.datesOfBirth.length
                                                                    : 0}
                                                        />
                                                    }

                                                    {this.props.alert.source.datesOfBuild &&
                                                        <MultipleAttributeDisplay field={formatMessage('person_details_dobuild')} value={this.props.alert.source.datesOfBuild}
                                                            amountOnTarget={
                                                                (this.props.alert.target && this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.datesOfBuild)
                                                                    ? this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.datesOfBuild.length
                                                                    : 0}
                                                        />
                                                    }

                                                    {this.props.alert.source.datesOfRegistry &&
                                                        <MultipleAttributeDisplay field={formatMessage('person_details_doregistry')} value={this.props.alert.source.datesOfRegistry}
                                                            amountOnTarget={
                                                                (this.props.alert.target && this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.datesOfRegistry)
                                                                    ? this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.datesOfRegistry.length
                                                                    : 0}
                                                        />
                                                    }

                                                    {this.props.alert.source.placesOfBirth &&
                                                        <MultipleAttributeDisplay field={formatMessage('person_details_pobs')} value={this.props.alert.source.placesOfBirth}
                                                            location={true}
                                                            amountOnTarget={
                                                                (this.props.alert.target && this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.placesOfBirth)
                                                                    ? this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.placesOfBirth.length
                                                                    : 0}
                                                        />
                                                    }

                                                    {this.props.alert.source.placesOfRegistry &&
                                                        <MultipleAttributeDisplay field={formatMessage('person_details_pors')} value={this.props.alert.source.placesOfRegistry}
                                                            location={true}
                                                            amountOnTarget={
                                                                (this.props.alert.target && this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.placesOfRegistry)
                                                                    ? this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.placesOfRegistry.length
                                                                    : 0}
                                                        />
                                                    }

                                                    {this.props.alert.source.nationalities &&
                                                        <MultipleAttributeDisplay field={formatMessage('person_details_nationality')} value={this.props.alert.source.nationalities}
                                                            amountOnTarget={
                                                                (this.props.alert.target && this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.nationalities)
                                                                    ? this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.nationalities.length
                                                                    : 0
                                                            }
                                                        />
                                                    }

                                                    {this.props.alert.source.flags &&
                                                        <MultipleAttributeDisplay field={formatMessage('person_details_flags')} value={this.props.alert.source.flags}
                                                            amountOnTarget={
                                                                (this.props.alert.target && this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.flags)
                                                                    ? this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.flags.length
                                                                    : 0
                                                            }
                                                        />
                                                    }

                                                    {this.props.alert.source.addresses &&
                                                        <MultipleAttributeDisplay field={formatMessage('person_details_location')} value={this.props.alert.source.addresses}
                                                            location={true}
                                                            amountOnTarget={
                                                                (this.props.alert.target && this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.addresses)
                                                                    ? this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.addresses.length
                                                                    : 0
                                                            }
                                                        />
                                                    }

                                                    {this.props.alert.source.bic &&
                                                                <AttributeDisplay field={formatMessage('person_details_bic')} 
                                                                            value={this.props.alert.source.bic} 
                                                                            sex={false} />
                                                    }

                                                    {this.props.alert.source.identityDocuments &&
                                                        <MultipleAttributeDisplay field={formatMessage('person_details_idDoc')} value={this.props.alert.source.identityDocuments}
                                                            identificationDocument={true}
                                                            amountOnTarget={
                                                                (this.props.alert.target && this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.identityDocuments)
                                                                    ? this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.identityDocuments.length
                                                                    : 0
                                                            }
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="target-display">
                                                {this.props.alert.target && this.props.alert.target[this.state.matchingEntityDisplayed] &&
                                                    <React.Fragment>
                                                        <div className="entity-card--data" style={{ height: '75%', scrollBehavior: 'auto', width: '100%' }}>
                                                            {this.getRowCard(capitalizeWords(targetName.fullNameForDisplay),
                                                                undefined
                                                                , this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.entityType
                                                                , this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.label
                                                                , this.props.alert.target[this.state.matchingEntityDisplayed].matchScore
                                                                , this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.dataset ? this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.dataset.classification : ''
                                                            )
                                                            }

                                                            <AttributeDisplay field={formatMessage('person_details_dataid')} value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.dataID} />
                                                            <MultipleAttributeDisplayName field={formatMessage('person_details_fullName')}
                                                                value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.names}
                                                                amountOnTarget={this.props.alert.source.names ? this.props.alert.source.names.length : 0}
                                                            />

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.sex &&
                                                                <AttributeDisplay field={formatMessage('person_details_sex')} value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.sex} 
                                                                                sex={true} />
                                                            }
                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.datesOfBirth &&
                                                                <MultipleAttributeDisplay field={formatMessage('person_details_dob')}
                                                                    value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.datesOfBirth} />
                                                            }

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.datesOfBuild &&
                                                                <MultipleAttributeDisplay field={formatMessage('person_details_dobuild')}
                                                                    value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.datesOfBuild} />
                                                            }

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.datesOfRegistry &&
                                                                <MultipleAttributeDisplay field={formatMessage('person_details_doregistry')}
                                                                    value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.datesOfRegistry} />
                                                            }

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.placesOfBirth &&
                                                                <MultipleAttributeDisplay field={formatMessage('person_details_pobs')}
                                                                    location={true}
                                                                    value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.placesOfBirth}
                                                                    amountOnTarget={this.props.alert.source.placesOfBirth ? this.props.alert.source.placesOfBirth.length : 0} />
                                                            }

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.placesOfRegistry &&
                                                                <MultipleAttributeDisplay field={formatMessage('person_details_pors')}
                                                                    location={true}
                                                                    value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.placesOfRegistry}
                                                                    amountOnTarget={this.props.alert.source.placesOfRegistry ? this.props.alert.source.placesOfRegistry.length : 0} />
                                                            }

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.nationalities &&
                                                                <MultipleAttributeDisplay field={formatMessage('person_details_nationality')}
                                                                    value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.nationalities}
                                                                    amountOnTarget={this.props.alert.source.nationalities ? this.props.alert.source.nationalities.length : 0} />
                                                            }

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.flags &&
                                                                <MultipleAttributeDisplay field={formatMessage('person_details_flags')}
                                                                    value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.flags}
                                                                    amountOnTarget={this.props.alert.source.flags ? this.props.alert.source.flags.length : 0} />
                                                            }

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.addresses &&
                                                                <MultipleAttributeDisplay field={formatMessage('person_details_location')}
                                                                    location={true}
                                                                    value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.addresses}
                                                                    amountOnTarget={this.props.alert.source.addresses ? this.props.alert.source.addresses.length : 0} />
                                                            }

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.identityDocuments &&
                                                                <MultipleAttributeDisplay field={formatMessage('person_details_idDoc')}
                                                                    identificationDocument={true}
                                                                    value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.identityDocuments}
                                                                    amountOnTarget={this.props.alert.source.identityDocuments ? this.props.alert.source.identityDocuments.length : 0} />
                                                            }

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.bic &&
                                                                <AttributeDisplay field={formatMessage('person_details_bic')} 
                                                                            value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.bic} 
                                                                            sex={false} />
                                                            }

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.keywords &&
                                                                <MultipleAttributeDisplay field={formatMessage('person_details_programs')}
                                                                    value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.keywords}
                                                                    amountOnTarget={this.props.alert.source.keywords ? this.props.alert.source.keywords.length : 0} />
                                                            }

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.sources &&
                                                                <MultipleAttributeDisplay field={formatMessage('person_details_sources')}
                                                                    value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.sources}
                                                                    amountOnTarget={this.props.alert.source.sources ? this.props.alert.source.sources.length : 0} 
                                                                    urls={true} sources={true}/>
                                                            }

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.titles &&
                                                                <MultipleAttributeDisplay field={formatMessage('person_details_titles')}
                                                                    value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.titles}
                                                                    amountOnTarget={this.props.alert.source.titles ? this.props.alert.source.titles.length : 0} />
                                                            }

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.contactInformation &&
                                                                <MultipleAttributeDisplay field={formatMessage('person_details_contactinfo')}
                                                                    value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.contactInformation}
                                                                    amountOnTarget={this.props.alert.source.contactInformation ? this.props.alert.source.contactInformation.length : 0} />
                                                            }

                                                            {this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.additionalInformations &&
                                                                <MultipleAttributeDisplay field={formatMessage('person_details_addinfo')}
                                                                    value={this.props.alert.target[this.state.matchingEntityDisplayed].watchListPersons.additionalInformations}
                                                                    amountOnTarget={this.props.alert.source.additionalInformations ? this.props.alert.source.additionalInformations.length : 0} 
                                                                    noUpperCase={true}
                                                                    />
                                                            }
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                {this.props.alert.target && this.props.alert.target.length > 1 &&
                                                    <div className="pagination-display">
                                                        <Pagination
                                                            changeInput={this.changeInput}
                                                            onChangeNext={this.onChangeNext}
                                                            onChangePrevious={this.onChangePrevious}
                                                            maxPage={this.props.alert.target.length}
                                                            pageNumber={this.state.matchingEntityDisplayed} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {this.state.tabSelected === HeaderConstant.COMMENT && this.props.alert && <AlertCommentPopup alert={this.props.alert} />}
                            {this.state.tabSelected === HeaderConstant.WKFCOMMENT && this.props.alert && <AlertWorkflowAuditDisplay alert={this.props.alert} />}
                            {this.state.tabSelected === HeaderConstant.FILES && this.props.alert && <AlertFiles alert={this.props.alert} />}

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private getSourceName = (source: Person): Name => {
        if (source.names && source.names.length > 0) {
            let name: Name = new Name();
            name.fullName = source.names[0].fullName;
            name.fatherName = source.names[0].fatherName;
            name.surname = source.names[0].surname;
            name.givenName = source.names[0].givenName;
            return name;
        }

        return new Name();
    }

    private getRowCard = (title: string, extraTitle: string | undefined, entityType: string, label: string, score: string | undefined, classification: string | undefined) => {
        return (

            <div className="entity-card__info-wrapper" style={{ height: '6rem', paddingLeft: '0.4rem', borderBottom: '1px solid rgb(212, 212, 212' }}>
                <p className="entity-card__header">
                    {title}

                    {score &&
                        <div className="value" style={{ float: 'right', paddingTop: '10px', marginRight: '10px' }}>
                            <div className="bar" style={{ width: '90px', display: 'block', height: '0.65rem' }}>
                                <div className="bar-fill" style={
                                    {
                                        width: `${Math.floor((parseFloat(score) * 100))}%`,
                                        display: 'block'
                                    }
                                }></div>
                            </div>
                            {Math.floor((parseFloat(score) * 100))} %
                        </div>
                    }
                </p>

                <p className="entity-card__label">
                    {entityType &&
                        <span className="header label-entity-type" style={{ marginLeft: '0px' }}>
                            {entityType}
                        </span>
                    }
                    {label &&
                        <span className="header label-dataset" style={{ marginLeft: '5px' }}>
                            {label}
                        </span>
                    }
                </p>
                <p className="entity-card__subject-extra">{extraTitle ? extraTitle : ''}</p>

            </div>

        )
    }

    private onChangePrevious = () => {
        this.setState({
            ...this.state,
            matchingEntityDisplayed: this.state.matchingEntityDisplayed - 1
        })
    }

    private onChangeNext = () => {
        this.setState({
            ...this.state,
            matchingEntityDisplayed: this.state.matchingEntityDisplayed + 1
        })
    }

    private changeInput = (e: any) => {
        this.setState({
            ...this.state,
            matchingEntityDisplayed: e
        })
    }

    private changeVisibility = (event: any) => {
        this.setState({
            ...this.state,
            tabSelected: event.target.id
        })
    }

    private getHeader = () => {
        const header: Array<{ id: string, name: string, visibleTab: string }> = [];

        header.push({ id: HeaderConstant.GENERAL, name: formatMessage('main_tab_header_general'), visibleTab: HeaderConstant.GENERAL });
        header.push({ id: HeaderConstant.WKFCOMMENT, name: formatMessage('alertPopup_tab_header_wkfcomment'), visibleTab: HeaderConstant.WKFCOMMENT });
        header.push({ id: HeaderConstant.COMMENT, name: formatMessage('alertPopup_tab_header_comment'), visibleTab: HeaderConstant.COMMENT });
        header.push({ id: HeaderConstant.FILES, name: formatMessage('alertPopup_tab_header_files'), visibleTab: HeaderConstant.FILES });
        return header;
    }

    private closePopup = () => {
        this.componentVisible = false;
        this.setState({
            tabSelected: HeaderConstant.GENERAL,
            matchingEntityDisplayed: 0
        });

        if (this.props.closePopup) {
            this.props.closePopup();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
    alert: state.alertStore.alert
})

export const AlertDetailsPopup = connect(mapStateToProps, null)(AlertDetailsPopupClass);