import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/card.css'
import '../../common/css/attribute.css'
import { formatMessage } from "../../common/translate/Translate";
import { Person } from "../../model/persons/Person";
import { IRootState } from "../../store";
import { AttributeDisplay } from "../../components/attribute/AttributeDisplay";
import { PersonScoreCard } from "../../model/persons/PersonScoreCard";

interface IntProps {
    person?: Person,
    personScoreCard?: PersonScoreCard
}

interface IntState {

}

export class CustomerScoreCardClass extends React.Component<IntProps, IntState> {

    public render() {
        return (
            <React.Fragment>
                {this.props.person &&
                    <div className="card attribute">
                        {this.props.personScoreCard && this.props.personScoreCard.aggregatedTransactionAmount &&
                            <AttributeDisplay field={formatMessage('person_scorecard_aggregate')}
                                value={this.props.personScoreCard ? String(this.props.personScoreCard.aggregatedTransactionAmount) : ''} />
                        }

                        {this.props.person && this.props.person.score &&
                            <AttributeDisplay field={formatMessage('person_scorecard_risk')}
                                value={this.props.person ? String(this.props.person.score) : ''} />
                        }

                        {this.props.person && this.props.person.riskSince &&
                            <AttributeDisplay field={formatMessage('person_scorecard_risk_since')}
                                value={this.props.personScoreCard ? this.props.person.riskSince : ''} />
                        }

                        {this.props.personScoreCard && !Number.isNaN(this.props.personScoreCard.amountOfOpenAlerts) &&
                            <AttributeDisplay field={formatMessage('person_scorecard_openalerts')}
                                value={this.props.personScoreCard ? String(this.props.personScoreCard.amountOfOpenAlerts) : ''} />
                        }
                    </div>
                }
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
    person: state.personStore.currentPerson,
    personScoreCard: state.personStore.currentPersonScoreCard
})

export const CustomerScoreCard = connect(mapStateToProps, null)(CustomerScoreCardClass);