import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { formatMessage } from "../../common/translate/Translate";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { IRootState } from "../../store";
import { Pagination } from "../../components/pagination/Pagination";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { AdminContextMenu } from "./AdminContextMenu";
import { RiskMapping } from "../../model/risk/RiskMapping";
import { deleteRiskConfiguration, getAllScenario, getRiskConfiguration, getRiskGlobalScoreConfiguration } from "../../store/risk/action";
import { RiskMappingDetailsPopup } from "./RiskMappingDetails";
import { RiskGlobalCalculationDetailsPopup } from "./RiskGlobalCalculationDetails"
import { HeaderConstant } from "../../common/constant/HeaderConstant";
import { RiskGlobalScoreCalculation } from "../../model/risk/RiskGlobalScoreCalculation";
import Select, { ActionMeta } from 'react-select';
import { RiskGlobalScoreCategory } from "../../model/risk/RiskGlobalScoreCategory";

interface IntProps {
    isLoading?:Boolean,
    riskMappings?:RiskMapping[],
    totalAmountOfRiskMappings?:number,
    screenakey?:string,
    riskGlobalScoreConfiguration?:RiskGlobalScoreCalculation,
    allScenario?:string[]
}

interface IntState {
    paginationRequest : PaginationRequest,
    selectedRow:number,
    contextMenuX:number,
    contextMenuY:number,
    contextMenuVisible:boolean,    

    riskMappingDetailPopupVisible:boolean,
    riskMappingCreate:boolean,

    riskGlobalCalcDetailPopupVisible:boolean,
    riskGlobalCalcCreate:boolean,

    tabSelected: string,

    currentScenario:{value: string;label: string}
}

class RiskConfigurationClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = {
            paginationRequest : new PaginationRequest(),
            selectedRow: -1,
            contextMenuX: -1,
            contextMenuY: -1,
            contextMenuVisible: false,
            riskMappingDetailPopupVisible:false,
            riskMappingCreate:false,
            riskGlobalCalcDetailPopupVisible:false,
            riskGlobalCalcCreate:false,
            tabSelected: HeaderConstant.RULE,
            currentScenario:  {
                value: '',
                label: ''
            }
        }

        getRiskConfiguration(this.props.screenakey);
        getAllScenario(this.props.screenakey);
    }

    private maxPage:number = 0;

    private prevScenario:string='';

    public render() {
        
        if (this.props.totalAmountOfRiskMappings) {
            this.maxPage = Math.ceil(this.props.totalAmountOfRiskMappings / this.state.paginationRequest.maxPerPage);           
        } else {
            this.maxPage = 0;
        }
        
        let header:Array<{id: string, name: string, visibleTab: string}> = this.getHeader();

        if (this.state.currentScenario.value.length>0 && this.state.currentScenario.value!==this.prevScenario) {
            getRiskGlobalScoreConfiguration(this.state.currentScenario.value, this.props.screenakey);
            this.prevScenario = this.state.currentScenario.value;
        }

        let listOfScenarios:{value: string;label: string}[] = [];
        if (this.props.allScenario) {
            for (var scen of this.props.allScenario) {
                listOfScenarios.push(
                    {
                        value: scen,
                        label: scen
                    }
                );
            }
           
        }

        return (
            <React.Fragment>                 
                <AdminContextMenu visible={this.state.contextMenuVisible} x={this.state.contextMenuX} y={this.state.contextMenuY}
                                    deleteAction={this.deleteRiskMapping}
                                    editAction={this.displayRiskMappingDetails}
                />

                <RiskMappingDetailsPopup visible={this.state.riskMappingDetailPopupVisible} 
                                    closePopup={this.closeRiskMappingDetails}
                                    riskMapping={this.getCurrentRiskMapping(this.state.selectedRow)}
                                    create={this.state.riskMappingCreate}
                                    />
                <RiskGlobalCalculationDetailsPopup visible={this.state.riskGlobalCalcDetailPopupVisible}
                                                    create={this.state.riskGlobalCalcCreate}
                                                    closePopup={this.closeRiskGlobalcDetails}
                                                    riskGlobalScoreCategory={this.getCurrentRiskGlobalCategory(this.state.selectedRow)}
                                                    scenario={this.state.currentScenario.value}/>

                <div className="main-grids" onContextMenu={this.onGridDisplayContextMenu}>  
                    <button className="m-button m-button--small-margin-bottom"  onClick={this.createRiskMapping} style={{marginTop: '5px', width: '10rem'}}>
                         {formatMessage('button_create')}
                    </button>
                    <button className="m-button m-button--small-margin-bottom"  onClick={this.createRiskMapping} style={{marginTop: '5px', marginLeft: '5px', width: '10rem'}}>
                         {formatMessage('button_import_dataset')}
                    </button>
                    <nav className="c-tabs__nav">
                        <ul className="c-tabs__list">
                            {header.map((head, index) => {
                            return (
                                <li className="c-tabs__title" key={index}>
                                    <button className={`c-tabs__link js-tabs-link ${this.state.tabSelected === head.visibleTab ? 'is-active' : ''}`}
                                    id={head.id}
                                    name={head.id} onClick={this.changeVisibility}>
                                    <span id={head.id}>{head.name}</span>
                                    </button>
                                </li>
                            )
                            })}
                        </ul>   
                    </nav>
                        
                    {this.state.tabSelected===HeaderConstant.RULE &&
                        <React.Fragment>
                            
                            <div className="grids-display">
                                <table className="c-table js-table">
                                    <thead className={`c-table__head`}>
                                        <tr>
                                            <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="name" display="common_name"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="description" display="common_description"/>

                                            <HeaderColumn sortHeader={this.sortHeader} id="createdBy" display="common_createdBy"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="createdOn" display="common_createdOn"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="updatedBy" display="common_updatedBy"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="updatedOn" display="common_updatedOn"/>                                                              
                                        </tr>
                                    </thead>
                                    <tbody className="c-table__body">
                                        {this.props.riskMappings &&  this.props.riskMappings.map((rowElem, j) => {                                   
                                                return (
                                                        <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} 
                                                            onClick={this.selectRow}
                                                            onDoubleClick={this.displayRiskMappingDetails}>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.dataId}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.scenarioName}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.createdBy}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.creationDate}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.updatedBy}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.updateDate}</td>
                                                        </tr>
                                                    );
                                            }                          
                                        )}
                                    </tbody>                            
                                </table> 
                            </div>
                            <div className="pagination-display">
                                {!Number.isNaN(this.maxPage) && this.maxPage>0 &&
                                    <Pagination 
                                            changeInput={this.changeInput} 
                                            onChangeNext={this.onChangeNext} 
                                            onChangePrevious={this.onChangePrevious} 
                                            maxPage={this.maxPage} 
                                            pageNumber={this.state.paginationRequest.pageNumber}/>      
                                }
                            </div>
                        </React.Fragment>  
                    }

                    {this.state.tabSelected===HeaderConstant.GLOBALCALC && 
                        <React.Fragment>
                            <Select name="risk_conf_fieldname"                                                                                                                     
                                            className="basic-multi-select"
                                            value={this.state.currentScenario}
                                            options={listOfScenarios}
                                            classNamePrefix="select"
                                            onChange={this.changeCurrentScenario}
                            /> 
                            <div className="grids-display">
                                <table className="c-table js-table">
                                    <thead className={`c-table__head`}>
                                        <tr>
                                            <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="name" display="common_name"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="description" display="common_description"/>

                                            <HeaderColumn sortHeader={this.sortHeader} id="createdBy" display="common_createdBy"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="createdOn" display="common_createdOn"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="updatedBy" display="common_updatedBy"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="updatedOn" display="common_updatedOn"/>                                                              
                                        </tr>
                                    </thead>
                                    <tbody className="c-table__body">
                                        {this.props.riskGlobalScoreConfiguration 
                                            && this.props.riskGlobalScoreConfiguration.categories 
                                            && this.props.riskGlobalScoreConfiguration.categories.map((rowElem, j) => {                                   
                                                return (
                                                        <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} 
                                                            onClick={this.selectRow}
                                                            onDoubleClick={this.displayRiskGlobalcDetails}>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.category}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.operation}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.weight}</td>                                                            
                                                        </tr>
                                                    );
                                            }                          
                                        )}
                                    </tbody>                            
                                </table> 
                            </div>                           
                        </React.Fragment>
                    }
                </div>  
                
            </React.Fragment>
        );
    }

    private changeCurrentScenario =  (value: {value: string;label: string;} | null, actionMeta: ActionMeta<{value: string;label: string;}>):void => {

        if (value) {
           
            this.setState({                        
                ...this.state,      
                currentScenario:value
            });           
        } else {
            this.setState({                        
                ...this.state,      
                currentScenario:{
                    value: '',
                    label: ''
                }
            });
        }    

    }

    private deleteRiskMapping = () => {
        deleteRiskConfiguration(String(this.state.selectedRow), this.props.screenakey);
        this.setState({
            ...this.state,
            contextMenuVisible: false
        })
    }

    private displayRiskMappingDetails = () => {
        this.setState({
            ...this.state,
            riskMappingDetailPopupVisible : true,
            riskMappingCreate:false,
            contextMenuVisible: false
        })
    }

    private closeRiskMappingDetails = () => {
        this.setState({
            ...this.state,
            riskMappingDetailPopupVisible : false,
            riskMappingCreate:false,
            contextMenuVisible: false

        })
    }

    private displayRiskGlobalcDetails = () => {
        this.setState({
            ...this.state,
            riskGlobalCalcDetailPopupVisible : true,
            riskGlobalCalcCreate:false,
            contextMenuVisible: false
        })
    }

    private closeRiskGlobalcDetails = () => {
        this.setState({
            ...this.state,
            riskGlobalCalcDetailPopupVisible : false,
            riskGlobalCalcCreate:false,
            contextMenuVisible: false

        })
    }

    private createRiskMapping = () => {

        if (this.state.tabSelected===HeaderConstant.RULE) {
            this.setState({
                ...this.state,
                selectedRow:-1,
                riskMappingDetailPopupVisible : true,
                riskMappingCreate:true
            })
        }

        if (this.state.tabSelected===HeaderConstant.GLOBALCALC) {
            this.setState({
                ...this.state,
                selectedRow:-1,
                riskGlobalCalcDetailPopupVisible : true,
                riskGlobalCalcCreate:true
            })
        }
        
    }

    private getCurrentRiskMapping = (riskId: number) : RiskMapping => {
        if (this.props.riskMappings) {
            for (var risk of this.props.riskMappings) {
                if (risk.id===riskId) {
                    return risk;
                }
            }
        }        
        return new RiskMapping();
    }

    private getCurrentRiskGlobalCategory = (riskId: number) : RiskGlobalScoreCategory => {
        if (this.props.riskGlobalScoreConfiguration && this.props.riskGlobalScoreConfiguration.categories) {
            for (var risk of this.props.riskGlobalScoreConfiguration.categories) {
                if (risk.id===riskId) {
                    return risk;
                }
            }
        }        
        return new RiskGlobalScoreCategory();
    }

    private onGridDisplayContextMenu = (event: any)  => {
        event.preventDefault();            
        this.setState({
            ...this.state,
            contextMenuVisible: true,
            contextMenuX: event.pageX ,
            contextMenuY: event.pageY ,
        })
    }

    private onChangeNext = () : void => {      
        if (this.state.paginationRequest.pageNumber<this.maxPage) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber+1;
            this.setState({
                ...this.state,              
                contextMenuVisible: false,
                paginationRequest:paginationRequest                
            })
        }        
    }

    private onChangePrevious = () : void => {
        if (this.state.paginationRequest.pageNumber>0) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber-1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
        }        
    }

    private changeInput = (e: any) => {
        const value = e.target? e.target.value: e.value;
        if (value === '') {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = value-1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
          return;
        }
        if (value < 1) {
          return;
        }       
      }

    private selectRow = (e: any) => {
        const value = e.target.parentNode? e.target.parentNode.id: e.id;

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            selectedRow:Number(value)
        })
    }

    private sortHeader = (e: any) => {
       
    }

    private changeVisibility= (event: any) => {        
        this.setState({
            ...this.state,
            tabSelected : event.target.id,
        })
    }

    private getHeader = () => {
        const header: Array<{id: string, name: string, visibleTab: string}> = [];        
        header.push({id:HeaderConstant.RULE, name: formatMessage('risk_tab_rules'), visibleTab: HeaderConstant.RULE});
        header.push({id:HeaderConstant.GLOBALCALC, name: formatMessage('risk_tab_globalcalc'), visibleTab: HeaderConstant.GLOBALCALC});            
        return header;
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    riskMappings : state.riskStore.riskMapping,
    riskGlobalScoreConfiguration : state.riskStore.riskGlobalScoreCalculation,
    allScenario : state.riskStore.allScenario,
    screenakey : state.loginStore.screenaapikey
})

export const RiskConfiguration = connect(mapStateToProps, null)(RiskConfigurationClass);