import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { User } from "../../model/user/User";
import { Request } from "../../model/common/Request";
import { createNewUser, updateUser } from "../../store/user/action";

import Select, { ActionMeta, OptionsType } from 'react-select';
import { Group } from "../../model/user/Group";
import { Role } from "../../model/user/Role";
import { OrganizationUnit } from "../../model/user/OrganizationUnit";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import { checkPasswordComplexity } from "../../common/helper/PasswordHelper";

interface IntProps {
    fullGroupList?:Group[],
    fullRoleList?:Role[],
    fullOrganizationList?:OrganizationUnit[],
    visible?:boolean,
    create?:boolean,
    x?:number,
    y?:number,
    user?:User,
    closePopup?:() => void,
    paginationRequest?:PaginationRequest
}

interface IntState {
   userName:string,
   userEmail:string,
   password:string,
   passwordVerify:string,
   userApiKey:string,
   userRole:number[],
   userGroup:number[],
   userOrgUnit:number[],
   userLocked:boolean,
   userDeleted:boolean,
   errors:{ 
             username : string,
             useremail : string,
             password  : string,
             userApiKey : string,
             userOrgUnit : string,
             userRole : string
          }
}

class UserDetailsPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {
            userName:'',
            userEmail:'',
            password:'',
            passwordVerify:'',
            userRole:[],
            userGroup:[],
            userOrgUnit:[],
            userApiKey:'',
            userLocked: false,
            userDeleted: false,
            errors:{
                username  : '',
                useremail : '',
                password : '',
                userApiKey : '',
                userOrgUnit : '',
                userRole : ''
            }
        }
    }

    public componentWillReceiveProps(nextProps: IntProps) {
        if (nextProps.user) {   
            let selectedGroup:number[] = [];
            if (nextProps.user.groups) {
                for (var group of nextProps.user.groups) {
                    selectedGroup.push(group.id);
                }
            }   
            
            let selectedRole:number[] = [];
            if (nextProps.user.roles) {
                for (var role of nextProps.user.roles) {
                    selectedRole.push(role.id);
                }
            } 

            let selectedOrgunit:number[] = [];
            if (nextProps.user.organizationUnits) {
                for (var orgunit of nextProps.user.organizationUnits) {
                    selectedOrgunit.push(orgunit.id);
                }
            } 

            this.setState({
                userName : nextProps.user.userName?nextProps.user.userName:'',
                userEmail : nextProps.user.email?nextProps.user.email:'',
                userApiKey : nextProps.user.apiKey?nextProps.user.apiKey:'',
                userLocked : nextProps.user.locked?nextProps.user.locked:false,
                userDeleted: nextProps.user.deleted?nextProps.user.deleted:false,
                userGroup : selectedGroup,
                userRole : selectedRole,
                userOrgUnit : selectedOrgunit,
                errors:{
                        username  : '',
                        useremail : '',
                        password : '',
                        userApiKey : '',
                        userOrgUnit : '',
                        userRole : ''
                    } 
            })

        } else {
            this.setState({
                userName : '',
                userEmail : '',
                userGroup : [],
                userRole : [],
                userOrgUnit : [],
                userApiKey : '',
                userLocked : false,
                userDeleted : false,
                errors:{
                        username  : '',
                        useremail : '',
                        password : '',
                        userApiKey : '',
                        userOrgUnit : '',
                        userRole : ''
                    } 
            })
        }
           
      }

    public render() {    
        
        let optionGroup:{value: string;label: string;}[] = [];
        let selectedGroup:{value: string;label: string;}[] = [];
        if (this.props.fullGroupList) {
            let i:number = 0;
            for (var group of this.props.fullGroupList) {
                optionGroup.push(
                    {
                        value : String(group.id),
                        label : group.name
                    }
                )

                for (var usergroupid of this.state.userGroup) {
                    if (usergroupid===group.id) {
                        selectedGroup.push (optionGroup[i]);
                    }
                }

                i++;
            }
        }

        let optionRole:{value:string;label:string;}[] = [];
        let selectedRole:{value: string;label: string;}[] = [];
        if (this.props.fullRoleList) {
            let i:number = 0;
            for (var role of this.props.fullRoleList) {
                optionRole.push(
                    {
                        value : String(role.id),
                        label : role.name
                    }
                )

                for (var userroleid of this.state.userRole) {
                    if (userroleid===role.id) {
                        selectedRole.push (optionRole[i]);
                    }
                }

                i++;
            }
        }

        let optionOrgUnit:{value:string;label:string;}[] = [];
        let selectedOrgunit:{value: string;label: string;}[] = [];
        if (this.props.fullOrganizationList) {
            let i:number = 0;
            for (var orgunit of this.props.fullOrganizationList) {                
                optionOrgUnit.push(
                    {
                        value : String(orgunit.id),
                        label : orgunit.name
                    }
                )

                for (var userorgunitid of this.state.userOrgUnit) {
                    if (userorgunitid===orgunit.id) {
                        selectedOrgunit.push (optionOrgUnit[i]);
                    }
                }

                i++;
            }
        }

        let heightPlus:number = 0;

        if (this.state.errors.userOrgUnit) {
            heightPlus +=2.5;
        }

        if (this.state.errors.userRole) {
            heightPlus +=2.5;
        }

        if (this.state.errors.useremail) {
            heightPlus +=2.5;
        }

        if (this.state.errors.username) {
            heightPlus +=2.5;
        }

        if (heightPlus>0) {
            heightPlus+=2;
        }

        let heightCreate:number = 0;
        if (this.props.create) {
            heightCreate +=10;
        }

        return (
            <React.Fragment>    
                      <div className={this.props.visible===true?"d-modal": "c-popover c-lc-popover js-lc-context"} 
                            style={this.props.create?{width: "50rem", height: 44+heightPlus + "rem"}:{width: "50rem", height: 38+heightPlus+heightCreate + "rem"}}>
                           <div className="d-modal__view js-modal-view">
                               <div className="d-modal__head">
                                    <legend className="d-modal__title">
                                        {formatMessage('alert_details_information_title')}
                                    </legend>
                                    <div>                             
                                        <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                            <svg className="o-icon o-icon--close o-icon--prepended">
                                                <title>{IconsEnum.CLOSE.title}</title>
                                                <use href={IconsEnum.CLOSE.url}/>
                                            </svg>
                                        </button>
                                    </div>                
                            </div>

                            <div className="d-modal__view js-modal-view" style={{margin:"1rem"}}> 
                                <div style={{display : 'flex'}} >
                                    <label className="o-label" style={{width:'80px'}}>
                                        {formatMessage('user_username')}*
                                    </label>
                                    <input
                                        className={this.state.errors && this.state.errors["username"]? "o-input error" : "o-input"}
                                        name="username"
                                        placeholder={formatMessage('user_username')}
                                        value= {this.state.userName}
                                        onChange = {this.changeUserName}                                    
                                        style={{marginTop: '2px'}}              
                                        /> 
                                                                               
                                </div>     
                                                       
                                <div style={{display : 'flex'}}>
                                    <label className="o-label" style={{width:'80px'}}>
                                        {formatMessage('user_email')}*
                                    </label>
                                    <input
                                        className={this.state.errors && this.state.errors["useremail"]? "o-input error" : "o-input"}
                                        name="useremail"
                                        placeholder={formatMessage('user_email')}
                                        value= {this.state.userEmail}
                                        onChange = {this.changeUserEmail}  
                                        style={{marginTop: '2px'}}              
                                        />   
                                                                          
                                </div>
                                 
                                <div style={{display : 'flex'}}>
                                    <label className="o-label" style={{width:'80px'}}>
                                        {formatMessage('user_apikey')}
                                    </label>
                                    <input
                                        className={this.state.errors && this.state.errors["userApiKey"]? "o-input error" : "o-input"}
                                        name="userApiKey"
                                        placeholder={formatMessage('user_apikey')}
                                        value= {this.state.userApiKey}
                                        onChange = {this.changeUserApiKey}  
                                        type="password"
                                        style={{marginTop: '2px'}}              
                                        />                                        
                                </div>

                                <div style={{display : 'flex'}}>
                                    <label className="o-label" style={{width:'80px', marginTop:'0.7rem'}}>
                                        {formatMessage('user_locked')}
                                    </label>
                                    <Checkbox  name="userLocked" checked={this.state.userLocked} onClick={this.changeLocked}/>
                                </div>

                                <div style={{display : 'flex'}}>
                                    <label className="o-label" style={{width:'80px', marginTop:'0.7rem'}}>
                                        {formatMessage('user_deleted')}
                                    </label>
                                    <Checkbox  name="userDeleted" checked={this.state.userDeleted} onClick={this.changeDeleted}/>
                                </div>
                                
                                <label className="o-label" style={{marginBottom: '5px'}}>{formatMessage('common_groups')}</label>
                                <Select name="userGroups"                                                                                                                     
                                        value={selectedGroup}
                                        options={optionGroup}
                                        classNamePrefix="basic-multi-select"
                                        onChange={this.changeGroupsSelect}
                                        isMulti
                                />

                                <label className="o-label" style={{marginBottom: '5px'}}>
                                    {formatMessage('common_roles')}*
                                </label>
                                <Select options={optionRole}
                                        name="userRoles" 
                                        value={selectedRole}                                                                             
                                        isMulti
                                        classNamePrefix={this.state.errors && this.state.errors["userRole"]?"basic-multi-select_error":"basic-multi-select"} 
                                        onChange={this.changeRoleSelect}
                                />
                                
                                <label className="o-label" style={{marginBottom: '5px'}} >
                                    {formatMessage('common_organizationunit')}*
                                </label>
                                <Select options={optionOrgUnit}
                                        value={selectedOrgunit}
                                        name="userOrgunits"      
                                        classNamePrefix={this.state.errors && this.state.errors["userOrgUnit"]?"basic-multi-select_error":"basic-multi-select"} 
                                        isMulti                            
                                        onChange={this.changeOrgUnitSelect}
                                />
                                

                                {this.props.create && 
                                    <React.Fragment>
                                        <div style={{display : 'flex'}}>
                                            <label className="o-label" style={{width:'80px'}}>
                                                {formatMessage('user_password')}
                                            </label>
                                            <input
                                                className={this.state.errors && this.state.errors["password"]? "o-input error" : "o-input"}
                                                name="userpassword"
                                                type="password"
                                                placeholder={formatMessage('user_password')}
                                                value= {this.state.password}
                                                onChange = {this.changePassword}  
                                                style={{marginTop: '2px'}}              
                                                /> 
                                            
                                        </div>
                                        <div style={{display : 'flex'}}>
                                            <label className="o-label" style={{width:'80px'}}>
                                                {formatMessage('user_password')}
                                            </label>
                                            <input
                                                className={this.state.errors && this.state.errors["password"]? "o-input error" : "o-input"}
                                                name="userpasswordVerify"
                                                type="password"
                                                placeholder={formatMessage('user_password_verify')}
                                                value= {this.state.passwordVerify}
                                                onChange = {this.changePasswordVerify}  
                                                style={{marginTop: '2px'}}              
                                                />                                             
                                        </div>
                                        
                                    </React.Fragment>
                                }
                            </div>

                            <div style={{display : 'flex'}}>
                                <button className="m-button m-button--small-margin-bottom" onClick={this.props.closePopup} style={{margin: '5px'}}>
                                    {formatMessage('button_cancel')}
                                </button> 
                                
                                <button className="m-button m-button--small-margin-bottom" onClick={this.saveUser} style={{margin: '5px'}}>
                                    {formatMessage('button_save')}
                                </button> 
                            </div>

                            {heightPlus>0 &&
                                <div className="form errors">
                                    {this.state.errors && this.state.errors["username"] &&
                                        <p className="form-errors p" style={{width:'100%'}}>{this.state.errors["username"]}</p>
                                    }  
                                    {this.state.errors && this.state.errors["userOrgUnit"] &&
                                        <p className="form-errors p">{this.state.errors["userOrgUnit"]}</p>
                                    }
                                     {this.state.errors && this.state.errors["userRole"] &&
                                       <p className="form-errors p">{this.state.errors["userRole"]}</p>
                                     }
                                     {this.state.errors && this.state.errors["useremail"] &&
                                        <p className="form-errors p">{this.state.errors["useremail"]}</p>
                                     }  
                                     {this.state.errors && this.state.errors["password"] &&
                                           <p className="form-errors p">{this.state.errors["password"]}</p>
                                     }
                                </div>
                            }

                        </div>     
                    </div>
            </React.Fragment>
        );
    }

    private changeGroupsSelect = (values: OptionsType<{value: string;label: string;}>, actionMeta: ActionMeta<{value: string;label: string;}>):void => {        
        let groups:number[]=[]

        if (values) {
            for (var value of values) {
                groups.push(Number(value.value));
            }
        }

        this.setState({                        
            ...this.state,  
            userGroup : groups                
        });
    }

    private changeLocked = () => {
        this.setState({
            ...this.state,
            userLocked : !this.state.userLocked
        })
    }

    private changeDeleted = () => {
        this.setState({
            ...this.state,
            userDeleted : !this.state.userDeleted
        })
    }    

    private changeRoleSelect = (values: OptionsType<{value: string;label: string;}>, actionMeta: ActionMeta<{value: string;label: string;}>):void => {
        
        let roles:number[]=[]

        if (values) {
            for (var value of values) {
                roles.push(Number(value.value));
            }
        }

        this.setState({                        
            ...this.state,  
            userRole : roles                
        });
    }

    private changeOrgUnitSelect = (values: OptionsType<{value: string;label: string;}>, actionMeta: ActionMeta<{value: string;label: string;}>):void => {
        
        let orgunits:number[]=[]

        if (values) {
            for (var value of values) {
                orgunits.push(Number(value.value));
            }
        }

        this.setState({                        
            ...this.state,  
            userOrgUnit : orgunits                
        });
    }

    private changeUserName = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            userName: event.target.value
        });
    }

    private changeUserEmail = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            userEmail: event.target.value
        });
    }

    private changeUserApiKey = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            userApiKey: event.target.value
        });
    }
    

    private changePassword = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            password: event.target.value
        });
    }

    private changePasswordVerify = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            passwordVerify: event.target.value
        });
    }

    private saveUser = () => {
        
        let noError:boolean = true;
        let errors = {
            username  : '',
            useremail : '',
            password : '',
            userApiKey : '',
            userOrgUnit : '',
            userRole : ''
        };

        if (this.props.create) {
            if (this.state.password!==this.state.passwordVerify || this.state.password==='') { 
                errors.password = formatMessage('password_no_match');              
                noError = false;
            }

            if (!checkPasswordComplexity(this.state.password)) {
                noError = false;
                errors.password = formatMessage('password_complexity');
            }
        }

        let request:Request = new Request();
        let user:User;

        if (this.props.create) {
            user = new User();            
        } else if (this.props.user) {
            user = this.props.user;
        } else {
            return;
        }

        user.email = this.state.userEmail;
        user.userName = this.state.userName;
        user.apiKey = this.state.userApiKey;
       
        if (!this.state.userName || this.state.userName==='') {
            errors.username = formatMessage('user_error_username');
            noError = false;
        }

        if (!this.state.userEmail || this.state.userEmail==='') {
            errors.useremail = formatMessage('user_error_email');
            noError = false;
        }

        if (this.props.create) {
            user.password = this.state.password;
        }

        user.locked = this.state.userLocked;
        user.deleted = this.state.userDeleted;

        // Groups
        user.groups = [];
        for (var groupid of this.state.userGroup) {
            let group:Group = new Group();
            group.id = groupid;
            user.groups.push(group);
        }

        user.roles = [];
        for (var roleid of this.state.userRole) {
            let role:Role = new Role();
            role.id = roleid;
            user.roles.push(role);
        }

        if (user.roles.length===0) {
            errors.userRole = formatMessage('user_error_role');
            noError = false;
        }
        

        user.organizationUnits = [];
        for (var orgunitid of this.state.userOrgUnit) {
            let orgunit:OrganizationUnit = new OrganizationUnit();
            orgunit.id = orgunitid;
            user.organizationUnits.push(orgunit);
        }

        if (user.organizationUnits.length===0) {
            errors.userOrgUnit = formatMessage('user_error_orgunit');
            noError = false;
        }

        request.user = user;

        if (!noError) {
            this.setState({
                ...this.state, 
                errors:errors       
            })

            return;
        }

        if (this.props.create) {
            createNewUser(request, this.props.paginationRequest);
        } else {
            updateUser(request, this.props.paginationRequest);
        }

        if (this.props.closePopup) {
            this.props.closePopup();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    fullGroupList : state.groupStore.fullListOfGroups,
    fullRoleList : state.roleStore.fullListOfRoles,
    fullOrganizationList : state.organizationUnitStore.fullListOfOrganizationUnits
})

export const UserDetailsPopup = connect(mapStateToProps, null)(UserDetailsPopupClass);