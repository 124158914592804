import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IconsEnum } from "../../common/constant/IconsEnum";

import '../../common/css/search.css'
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'

import { formatMessage } from "../../common/translate/Translate";
import { Pagination } from "../../components/pagination/Pagination";

import { PaginationRequest } from "../../model/common/PaginationRequest";
import { DataSets } from "../../model/dataset/DataSets";
import { MatchingResult } from "../../model/screena/MatchingResult";
import { SearchStatistics } from "../../model/screena/SearchStatistics";
import { User } from "../../model/user/User";
import { UserDashBoardPreferences } from "../../model/user/UserDashBoardPreferences";
import { IRootState } from "../../store";
import { cleanSearch, cleanSearchStats, getScreenaDataSets, searchScreenaFullUniqueDataSets } from "../../store/search/action";
import { ResultCard } from "./ResultCard";
import Select, { ActionMeta, OptionsType } from 'react-select';
import { Country } from "../../model/library/Country";
import { SearchConstant } from "../../common/constant/SearchConstant";
import { validateScreenaDateFormat } from "../../common/helper/DateHelper";

interface IntProps {
    isLoading?: boolean,
    fullListOfDatasets?: DataSets[],
    searchStats?: SearchStatistics;

    listOfPersons?: MatchingResult[],
    amountOfPersons?: number,

    countries?: Country[],

    screenaKey?: string,
    currentUser?: User,
    userDashBoardPreferences?: UserDashBoardPreferences

    showTooltip?: (title: string[], textTooltip: string[]) => void
}

interface IntState {
    paginationRequest: PaginationRequest,
    currentClassification: string,
    showAdvancedClassification: boolean,
    showFilterAdvanced: boolean,
    showPrefilterAdvanced: boolean,

    datePrefilter: string,
    dateFields: string[],
    dateAlgo: { value: string, label: string },

    countryPrefilter: string[],
    countryFields: string[],
    countryAlgo: { value: string, label: string },

    selectedDataset: string[],
    selectedEntityType: string[],

    searchName: string,

    errors: {
        dateField: boolean
    }
}

const ScrollingElement = () => {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        function watchScroll() {
            window.addEventListener("scroll", setScroll);
        }

        function setScroll() {
            setScrollY(window.scrollY);
        }

        watchScroll();
        // Remove listener (like componentWillUnmount)
        return () => {
            window.removeEventListener("scroll", setScroll);
        };
    }, []);


    return (
        <div className={scrollY > 200 ? "buttonWrapper" : "buttonWrapper hide"}>
            <button className="buttonTop" onClick={() => {
                window.scrollTo(0, 0);
            }}>
                {formatMessage('search_backToTop')}
            </button>
        </div>
    );
}

class SearchClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        let paginationRequest: PaginationRequest = new PaginationRequest();
        paginationRequest.pageNumber = 0;
        paginationRequest.maxPerPage = 10;
        paginationRequest.calculateSearchStats = true;

        this.state = {
            paginationRequest: paginationRequest,
            currentClassification: "ALL",
            showAdvancedClassification: false,
            showFilterAdvanced: false,
            showPrefilterAdvanced: false,

            datePrefilter: '',
            dateFields: [],
            dateAlgo: { value: 'same_day_month_year', label: formatMessage('same_day_month_year') },

            countryPrefilter: [],
            countryFields: [],
            countryAlgo: { value: 'same_country', label: formatMessage('same_country') },

            selectedDataset: [],
            selectedEntityType: [],

            searchName: '',

            errors: {
                dateField: false
            }
        }

        cleanSearch();
    }

    private typingTimeout: any;
    private savedSearchStats: SearchStatistics | undefined;
    private maxPage: number = 0;
    private callScreenaDataset: boolean = true;

    public render() {

        let options: { value: string, label: string }[] = [];
        let selectedColumns: { value: string; label: string; }[] = [];
        if (this.props.countries) {
            for (var country of this.props.countries) {
                options.push(
                    {
                        value: country.iso2,
                        label: country.name
                    }

                )

                if (this.state.countryPrefilter.indexOf(country.iso2) !== -1) {

                    selectedColumns.push(
                        {
                            value: country.iso2,
                            label: country.name
                        }
                    )
                }
            }
        }

        let selectedCountryFields: { value: string, label: string }[] = [];
        for (var countryField of SearchConstant.countryFields) {
           if (this.state.countryFields.indexOf(countryField.value) !== -1) {
                selectedCountryFields.push(countryField);                    
            }
        }

        let selectedDateFields: { value: string, label: string }[] = [];
        for (var dateField of SearchConstant.dateFields) {
            if (this.state.dateFields.indexOf(dateField.value) !== -1) {
                selectedDateFields.push(dateField);                    
             }
         }

        if (this.props.searchStats) {
            this.maxPage = Math.floor(this.props.searchStats?.numberOfAll / this.state.paginationRequest.maxPerPage);

            if (this.props.searchStats?.numberOfAll / this.state.paginationRequest.maxPerPage > this.maxPage) {
                this.maxPage += 1;
            }
        }

        if (this.callScreenaDataset && this.props.screenaKey) {
            getScreenaDataSets(this.props.screenaKey);
            this.callScreenaDataset = false;
        }

        if (!this.savedSearchStats && this.props.searchStats) {
            if (this.props.listOfPersons && this.props.listOfPersons.length > 0) {
                let matchRes0: MatchingResult = this.props.listOfPersons[0];
                if (matchRes0.sourceData && matchRes0.sourceData.length > 0) {
                    if (matchRes0.sourceData[0].names && matchRes0.sourceData[0].names.length > 0) {
                        if (matchRes0.sourceData[0].names[0].fullName === this.state.searchName) {
                            this.savedSearchStats = this.props.searchStats;
                        }
                    }
                }
            }
        }

        return (
            <div className="searchWrapper">
                <div className="search-form">
                    <div className="search-content">
                        <div className="search-FormBar">
                            <input className="search-Field" value={this.state.searchName} onChange={this.onChangeSearchInput}
                                onKeyPress={this.onKeyPressSearchinput}
                                placeholder={formatMessage('search_placeholder')} />
                            <button className="search-SubmitButton" />
                            <button className="search-ClearButton show" onClick={this.resetSearchName} />
                        </div>
                    </div>
                </div>
                <div className="searchpageContent">
                    <div className="filterWrapper">
                        <div className="filterMain">
                            <div className="filterMain title-bar">
                                <span className="filterMain label">View by</span>
                                {(this.state.selectedDataset.length > 0 || this.state.selectedEntityType.length > 0) &&
                                    <button className="filterWrapper categories reset reset-desktop" onClick={this.resetAdvancedFilter} style={{ marginRight: '20px' }}>
                                        {formatMessage('search_resetFilter')}
                                    </button>
                                }
                            </div>
                            <div className="filterWrapper categories toggle-desktop">
                                <select
                                    id="category"
                                    value={this.state.currentClassification}
                                    onChange={this.changeClassification}
                                >
                                    <option value="">
                                        {formatMessage('search_tabAll')}&nbsp;
                                    </option>
                                    <option value="SANCTION">
                                        {formatMessage('search_tabSanctions')}&nbsp;
                                    </option>     

                                    <option value="PEP">
                                        {formatMessage('search_tabPeps')}&nbsp;
                                    </option> 

                                     <option value="PEP FORMER">
                                        {formatMessage('search_tabPepFormers')}&nbsp;
                                    </option> 

                                     <option value="PEP LINKED">
                                        {formatMessage('search_tabPepLinked')}&nbsp;
                                    </option> 

                                     <option value="WATCHLIST">
                                        {formatMessage('search_tabWatchlist')}&nbsp;
                                    </option> 

                                     <option value="ADVERSE MEDIA">
                                        {formatMessage('search_tabAdverseMedia')}&nbsp;
                                    </option>             
     
                                </select>
                            </div>
                            <ul className="filterWrapper categories ul">
                                <li id="classification_all"
                                    className={this.state.currentClassification === "" || this.state.currentClassification === "ALL" ? "filterWrapper categories li active" : "filterWrapper categories li"}
                                    onClick={this.changeClassification}>
                                    <span id="ALL"
                                        className={this.state.currentClassification === "" || this.state.currentClassification === "ALL" ? "filterWrapper categories span active" : "filterWrapper categories span"}>
                                        {formatMessage('search_tabAll')}
                                    </span>
                                </li>
                                <li id="classification_sanctions"
                                    className={`${this.state.currentClassification === "SANCTION" ? "filterWrapper categories li active" : "filterWrapper categories li"}`}
                                    onClick={this.changeClassification}>
                                    <span id="SANCTION"
                                        className={`${this.state.currentClassification === "SANCTION" ? "filterWrapper categories span active" : "filterWrapper categories span"}`}>
                                        {formatMessage('search_tabSanctions')}
                                    </span>
                                </li>

                                <li id="classification_peps"
                                    className={`${this.state.currentClassification === "PEP" ? "filterWrapper categories li active" : "filterWrapper categories li"}`}
                                    onClick={this.changeClassification}>
                                    <span id="PEP"
                                        className={`${this.state.currentClassification === "PEP" ? "filterWrapper categories span active" : "filterWrapper categories span"}`}>
                                        {formatMessage('search_tabPeps')}
                                    </span>
                                </li>

                                <li id="classification_pepsformer"
                                    className={`${this.state.currentClassification === "PEP FORMER" ? "filterWrapper categories li active" : "filterWrapper categories li"}`}
                                    onClick={this.changeClassification}>
                                    <span id="PEP FORMER"
                                        className={`${this.state.currentClassification === "PEP FORMER" ? "filterWrapper categories span active" : "filterWrapper categories span"}`}>
                                        {formatMessage('search_tabPepFormers')}
                                    </span>
                                </li>

                                <li id="classification_pepslinked"
                                    className={`${this.state.currentClassification === "PEP LINKED" ? "filterWrapper categories li active" : "filterWrapper categories li"}`}
                                    onClick={this.changeClassification}>
                                    <span id="PEP LINKED"
                                        className={`${this.state.currentClassification === "PEP LINKED" ? "filterWrapper categories span active" : "filterWrapper categories span"}`}>
                                        {formatMessage('search_tabPepLinked')}
                                    </span>
                                </li>

                                <li id="classification_watchlists"
                                    className={`${this.state.currentClassification === "WATCHLIST" ? "filterWrapper categories li active" : "filterWrapper categories li"}`}
                                    onClick={this.changeClassification}>
                                    <span id="WATCHLIST"
                                        className={`${this.state.currentClassification === "WATCHLIST" ? "filterWrapper categories span active" : "filterWrapper categories span"}`}>
                                        {formatMessage('search_tabWatchlist')}
                                    </span>
                                </li>

                                <li id="classification_adversemedia"
                                    className={`${this.state.currentClassification === "ADVERSE MEDIA" ? "filterWrapper categories li active" : "filterWrapper categories li"}`}
                                    onClick={this.changeClassification}>
                                    <span id="ADVERSE MEDIA"
                                        className={`${this.state.currentClassification === "ADVERSE MEDIA" ? "filterWrapper categories span active" : "filterWrapper categories span"}`}>
                                        {formatMessage('search_tabAdverseMedia')}
                                    </span>
                                </li>
                            </ul>
                            <div className={this.state.showPrefilterAdvanced ? "filterAdvanced open" : "filterAdvanced"}
                                style={this.state.showPrefilterAdvanced ? { paddingTop: 0, paddingLeft: 0, paddingBottom: '30px' } : { paddingTop: 0, paddingLeft: 0 }}>

                                <div className="filterAdvanced buttons-bar">
                                    <button className={this.state.showPrefilterAdvanced ? "filterWrapper categories toggle-close" : "filterWrapper categories toggle"} onClick={this.setShowPrefIlterAdvanced}>
                                        {formatMessage('search_preFilter')}
                                    </button>
                                    {(this.state.selectedDataset.length > 0 || this.state.selectedEntityType.length > 0) &&
                                        <button className="filterWrapper categories reset" onClick={this.resetAdvancedFilter} style={{ marginRight: '20px' }}>
                                            {formatMessage('search_resetFilter')}
                                        </button>
                                    }

                                </div>
                                {this.state.showPrefilterAdvanced &&
                                    <div className="filterAdvancedDetails" style={{ overflow: 'visible' }}>
                                        <ul className="filterAdvanced list-entities">
                                            <div style={{ display: 'flex', overflow: 'visible', paddingLeft: '10px' }} >
                                                <label className="o-label" style={{ width: '90px', marginTop: '0.5rem' }}>
                                                    {formatMessage('common_entitytype')}
                                                </label>
                                                {this.state.selectedEntityType.indexOf(SearchConstant.INDIVIDUAL) === -1 &&
                                                    <li className="filterAdvanced list-entities li">
                                                        {this.state.selectedEntityType.indexOf(SearchConstant.IDNUMBER) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.LEI) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.BIC) === -1 ?
                                                            <button id="individual"
                                                                className="filterAdvanced list-entities button"
                                                                onClick={this.addEntityTypePreFilter}>
                                                                {formatMessage('search_individual')}
                                                            </button>
                                                            :
                                                            <button id="individual"
                                                                className="filterAdvanced list-entities button disabled">
                                                                {formatMessage('search_individual')}
                                                            </button>
                                                        }
                                                    </li>
                                                }

                                                {this.state.selectedEntityType.indexOf(SearchConstant.ORGANIZATION) === -1 &&
                                                    <li className="filterAdvanced list-entities li">
                                                        {this.state.selectedEntityType.indexOf(SearchConstant.IDNUMBER) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.LEI) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.BIC) === -1 ?
                                                            <button id="organization"
                                                                className="filterAdvanced list-entities button"
                                                                onClick={this.addEntityTypePreFilter}>
                                                                {formatMessage('search_organization')}
                                                            </button>
                                                            :
                                                            <button id="organization"
                                                                className="filterAdvanced list-entities button disabled">
                                                                {formatMessage('search_organization')}
                                                            </button>
                                                        }
                                                    </li>
                                                }

                                                {this.state.selectedEntityType.indexOf(SearchConstant.VESSEL) === -1 &&
                                                    <li className="filterAdvanced list-entities li">
                                                        {this.state.selectedEntityType.indexOf(SearchConstant.IDNUMBER) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.LEI) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.BIC) === -1 ?
                                                            <button id="vessel"
                                                                className="filterAdvanced list-entities button"
                                                                onClick={this.addEntityTypePreFilter}>
                                                                {formatMessage('search_vessel')}
                                                            </button>
                                                            :
                                                            <button id="vessel"
                                                                className="filterAdvanced list-entities button disabled">
                                                                {formatMessage('search_vessel')}
                                                            </button>
                                                        }
                                                    </li>
                                                }

                                                {this.state.selectedEntityType.indexOf(SearchConstant.UNKNOWN) === -1 &&
                                                    <li className="filterAdvanced list-entities li">
                                                        {this.state.selectedEntityType.indexOf(SearchConstant.IDNUMBER) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.LEI) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.BIC) === -1 ?
                                                            <button id="unknown"
                                                                className="filterAdvanced list-entities button"
                                                                onClick={this.addEntityTypePreFilter}>
                                                                {formatMessage('search_unknown')}
                                                            </button>
                                                            :
                                                            <button id="unknown"
                                                                className="filterAdvanced list-entities button disabled">
                                                                {formatMessage('search_unknown')}
                                                            </button>
                                                        }
                                                    </li>
                                                }

                                                {this.state.selectedEntityType.indexOf(SearchConstant.IDNUMBER) === -1 &&
                                                    <li className="filterAdvanced list-entities li">
                                                        {this.state.selectedEntityType.indexOf(SearchConstant.BIC) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.LEI) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.INDIVIDUAL) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.ORGANIZATION) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.VESSEL) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.UNKNOWN) === -1
                                                            ?
                                                            <button id="idnumber"
                                                                className="filterAdvanced list-entities button"
                                                                onClick={this.addEntityTypePreFilter}>
                                                                {formatMessage('search_idnumber')}
                                                            </button>
                                                            :
                                                            <button id="unknown"
                                                                className="filterAdvanced list-entities button disabled">
                                                                {formatMessage('search_idnumber')}
                                                            </button>
                                                        }
                                                    </li>
                                                }

                                                {this.state.selectedEntityType.indexOf(SearchConstant.BIC) === -1 &&
                                                    <li className="filterAdvanced list-entities li">
                                                        {this.state.selectedEntityType.indexOf(SearchConstant.IDNUMBER) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.LEI) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.INDIVIDUAL) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.ORGANIZATION) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.VESSEL) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.UNKNOWN) === -1
                                                            ?
                                                            <button id="bic"
                                                                className="filterAdvanced list-entities button"
                                                                onClick={this.addEntityTypePreFilter}>
                                                                {formatMessage('search_bic')}
                                                            </button>
                                                            :
                                                            <button id="unknown"
                                                                className="filterAdvanced list-entities button disabled">
                                                                {formatMessage('search_bic')}
                                                            </button>
                                                        }
                                                    </li>
                                                }

                                                {this.state.selectedEntityType.indexOf(SearchConstant.LEI) === -1 &&
                                                    <li className="filterAdvanced list-entities li">
                                                        {this.state.selectedEntityType.indexOf(SearchConstant.BIC) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.IDNUMBER) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.INDIVIDUAL) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.ORGANIZATION) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.VESSEL) === -1
                                                            && this.state.selectedEntityType.indexOf(SearchConstant.UNKNOWN) === -1
                                                            ?
                                                            <button id="lei"
                                                                className="filterAdvanced list-entities button"
                                                                onClick={this.addEntityTypePreFilter}>
                                                                {formatMessage('search_lei')}
                                                            </button>
                                                            :
                                                            <button id="unknown"
                                                                className="filterAdvanced list-entities button disabled">
                                                                {formatMessage('search_lei')}
                                                            </button>
                                                        }
                                                    </li>
                                                }
                                            </div>
                                        </ul>
                                        <ul className="filterAdvanced list-datasets">
                                            <div style={{ display: 'contents', overflow: 'visible' }} >
                                                <label className="o-label" style={{ width: '100px', paddingLeft: '10px' }}>
                                                    {formatMessage('common_listcategory')}
                                                </label>
                                                {this.props.fullListOfDatasets && this.props.fullListOfDatasets.map((rowElem, j) => {
                                                    return (
                                                        <React.Fragment>
                                                            {this.state.selectedDataset.indexOf(rowElem.label) === -1 &&
                                                                <li id={"lidst" + j} className="filterAdvanced list-datasets li">
                                                                    <button
                                                                        id={rowElem.label}
                                                                        className="filterAdvanced list-datasets li button"
                                                                        onClick={this.addDatasetPreFilter}>
                                                                        {rowElem.label}
                                                                    </button>
                                                                </li>
                                                            }
                                                        </React.Fragment>
                                                    )
                                                }
                                                )}
                                            </div>
                                        </ul>

                                        <ul className="filterAdvanced list-datasets" style={{ display: 'block', overflow: 'visible' }}>
                                            <div style={{ display: 'flex', overflow: 'visible', paddingLeft: '10px' }} >
                                                <label className="o-label" style={{ width: '100px' }}>
                                                    {formatMessage('common_country')}
                                                </label>
                                                <Select options={options}
                                                    value={selectedColumns}
                                                    name="countrySelect"
                                                    isMulti={true}
                                                    id="countrySelect"
                                                    classNamePrefix="basic-multi-select"
                                                    className="countrySelect"
                                                    onChange={this.changeCountryPrefilter}
                                                />

                                                <Select options={SearchConstant.countryAlgo}
                                                    value={this.state.countryAlgo}
                                                    name="countryAlgoSelect"
                                                    id="countryAlgoSelect"
                                                    classNamePrefix="basic-multi-select"
                                                    className="countryAlgoSelect"
                                                    onChange={this.changeCountryAlgoPrefilter}
                                                />

                                                <Select options={SearchConstant.countryFields}
                                                    value={selectedCountryFields}
                                                    name="countryFieldSelect"
                                                    isMulti={true}
                                                    id="countryFieldSelect"
                                                    classNamePrefix="basic-multi-select"
                                                    className="countrySelectField"
                                                    onChange={this.changeCountryFieldPrefilter}
                                                />
                                            </div>
                                        </ul>

                                        <ul className="filterAdvanced list-datasets" style={{ display: 'block' }}>
                                            <div style={{ display: 'flex', paddingLeft: '10px' }} >
                                                <label className="o-label" style={{ width: '100px' }}>
                                                    {formatMessage('common_date')}
                                                </label>
                                                <div className="search-date">
                                                    <input
                                                        className={this.state.errors && this.state.errors.dateField ? "o-input error" : "o-input"}
                                                        name="groupName"
                                                        placeholder={formatMessage('common_date_format')}
                                                        value={this.state.datePrefilter}
                                                        onChange={this.changeDatePrefilter}
                                                        style={{ marginTop: '2px', marginBottom: '2px', width: '200px' }}
                                                    />
                                                    <button className={this.state.datePrefilter === '' ? "search-DateButton hide" : "search-DateButton show"} onClick={this.resetSearchDate} />
                                                </div>
                                                <Select options={SearchConstant.dateAlgo}
                                                    value={this.state.dateAlgo}
                                                    name="dateAlgoSelect"
                                                    id="dateAlgoSelect"
                                                    classNamePrefix="basic-multi-select"
                                                    className="dateAlgoSelect"
                                                    onChange={this.changeDateAlgoPrefilter}
                                                />

                                                <Select options={SearchConstant.dateFields}
                                                    value={selectedDateFields}
                                                    name="dateFieldSelect"
                                                    isMulti={true}
                                                    id="dateFieldSelect"
                                                    classNamePrefix="basic-multi-select"
                                                    className="dateSelectField"
                                                    onChange={this.changeDateFieldPrefilter}
                                                />
                                            </div>
                                        </ul>

                                        {((this.state.selectedDataset && this.state.selectedDataset.length > 0) ||
                                            (this.state.selectedEntityType && this.state.selectedEntityType.length > 0)) &&
                                            <div className="filterAdvancedSelection">
                                                <h4 className="filterAdvancedSelection title">
                                                    {formatMessage('search_yourselection')}
                                                </h4>

                                                {this.state.selectedEntityType && this.state.selectedEntityType.length > 0 &&
                                                    <ul className="filterAdvancedSelection list-entities">
                                                        <li className="filterAdvancedSelection list-entities li">
                                                            {this.state.selectedEntityType && this.state.selectedEntityType.map((rowElem, j) => {
                                                                return (
                                                                    <div style={{ paddingLeft: '10px' }}>
                                                                        <button id={rowElem} className="filterAdvancedSelection list-entities button" onClick={this.removeEntityTypeFilter} >
                                                                            {formatMessage('search_' + rowElem)}
                                                                        </button>
                                                                    </div>
                                                                );
                                                            })}
                                                        </li>
                                                    </ul>
                                                }

                                                {this.state.selectedDataset && this.state.selectedDataset.length > 0 &&
                                                    <ul className="filterAdvancedSelection list-datasets">
                                                        {this.state.selectedDataset && this.state.selectedDataset.map((rowElem, j) => {
                                                            return (
                                                                <div style={{ paddingLeft: '10px' }}>
                                                                    <li className="filterAdvancedSelection list-datasets li">
                                                                        <button id={rowElem} className="filterAdvancedSelection list-datasets button" onClick={this.removeDatasetFilter}>
                                                                            {rowElem}
                                                                        </button>
                                                                    </li>
                                                                </div>
                                                            );
                                                        })}
                                                    </ul>
                                                }
                                            </div>
                                        }

                                        <ul className="filterAdvanced list-datasets" style={{ display: 'block' }}>
                                            <button className="m-button m-button--small-margin-bottom" disabled={this.searchActive()} onClick={this.onSearchButton} style={{ width: '100px', marginTop: '5px' }}>
                                                {formatMessage('button_search')}
                                            </button>
                                            <button className="m-button m-button--small-margin-bottom" disabled={this.searchActive()} onClick={this.ExportButton} style={{ width: '100px', marginTop: '5px', marginLeft: '10px' }}>
                                                {formatMessage('button_export')}
                                            </button>
                                            <button className="m-button m-button--small-margin-bottom" disabled={this.searchActive()} onClick={this.ExportButtonPDF} style={{ width: '120px', marginTop: '5px', marginLeft: '10px' }}>
                                                {formatMessage('button_export_pdf')}
                                            </button>
                                        </ul>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                    {
                        !(this.props.listOfPersons && this.props.listOfPersons.length > 0) && this.props.searchStats?.numberOfAll === 0 &&
                        <label style={{ fontSize: '36px' }}>
                            {formatMessage('search_no_match')}
                        </label>
                    }

                    {
                        !(this.props.listOfPersons && this.props.listOfPersons.length > 0) && this.props.searchStats?.numberOfAll !== 0 &&
                        <div className="search-img visual-no-search img">
                            <img src={IconsEnum.NOSEARCH.url} alt=" " />
                        </div>
                    }
                    <div className="resultWrapper">
                        {this.props.searchStats && this.props.searchStats.numberOfAll > 0 &&
                            <React.Fragment>
                                <h2 className="main-title">{formatMessage('search_results')}</h2>
                                <div className="pageBar">
                                    <div className="itemsPerPageWrapper">
                                        <span>{formatMessage('search_displaying')}&nbsp;</span>
                                        {(this.props.searchStats.numberOfAll > 10) ?
                                            <React.Fragment>
                                                <select onChange={this.handleMaxPerPageChange} value={this.state.paginationRequest.maxPerPage}
                                                    className="pagination select">
                                                    <option value="10">10</option>
                                                    {(this.props.searchStats.numberOfAll > 10) ? <option value="20">20</option> : null}
                                                    {(this.props.searchStats.numberOfAll > 20) ? <option value="50">50</option> : null}
                                                </select>
                                                <span>&nbsp;{"of"}&nbsp;{this.props.searchStats.numberOfAll}</span>
                                            </React.Fragment>
                                            : this.props.searchStats.numberOfAll}

                                    </div>
                                    <div className="pagination-display" style={{ width: '80%' }}>
                                        {this.maxPage > 1 &&
                                            <Pagination
                                                changeInput={this.changeInput}
                                                onChangeNext={this.onChangeNext}
                                                onChangePrevious={this.onChangePrevious}
                                                maxPage={this.maxPage}
                                                pageNumber={this.state.paginationRequest.pageNumber}
                                                textDisplay={true} />
                                        }
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        {!this.props.searchStats && this.props.listOfPersons && this.props.listOfPersons?.length > 0 &&
                            <React.Fragment>
                                <h2 className="main-title">{formatMessage('search_results')}</h2>
                                <div className="pageBar">
                                    <div className="itemsPerPageWrapper">
                                        <span>{formatMessage('search_displaying')}&nbsp;</span>

                                    </div>
                                    <div className="pagination-display" style={{ width: '80%' }}>
                                        {this.maxPage > 1 &&
                                            <Pagination
                                                changeInput={this.changeInput}
                                                onChangeNext={this.onChangeNext}
                                                onChangePrevious={this.onChangePrevious}
                                                maxPage={this.maxPage}
                                                pageNumber={this.state.paginationRequest.pageNumber}
                                                textDisplay={true} />
                                        }
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        <ul className="resultList ul">
                            {this.props.listOfPersons && this.props.listOfPersons?.map((rowElem, j) => {
                                return <ResultCard
                                    person={rowElem}
                                    searchName={this.state.searchName}
                                    showTooltip={this.props.showTooltip}
                                />
                            })}
                        </ul>
                        {this.props.searchStats && this.props.searchStats.numberOfAll > 0 &&
                            <ScrollingElement />
                        }
                        {this.props.searchStats && this.props.searchStats.numberOfAll > 0 &&
                            <div className="pageBar">
                                <div className="itemsPerPageWrapper">
                                    <span>{formatMessage('search_displaying')}&nbsp;</span>
                                    {(this.props.searchStats.numberOfAll > 10) ?
                                        <React.Fragment>
                                            <select onChange={this.handleMaxPerPageChange} value={this.state.paginationRequest.maxPerPage}
                                                className="pagination select">
                                                <option value="10">10</option>
                                                {(this.props.searchStats.numberOfAll > 10) ? <option value="20">20</option> : null}
                                                {(this.props.searchStats.numberOfAll > 20) ? <option value="50">50</option> : null}
                                            </select>
                                            <span>&nbsp;{"of"}&nbsp;{this.props.searchStats.numberOfAll}</span>
                                        </React.Fragment>
                                        : this.props.searchStats.numberOfAll}
                                </div>
                                <div className="pagination-display" style={{ width: '80%', paddingTop: '20px' }}>
                                    {this.maxPage > 1 &&
                                        <Pagination
                                            changeInput={this.changeInput}
                                            onChangeNext={this.onChangeNext}
                                            onChangePrevious={this.onChangePrevious}
                                            maxPage={this.maxPage}
                                            pageNumber={this.state.paginationRequest.pageNumber}
                                            textDisplay={true} />
                                    }
                                </div>
                            </div>
                        }
                        {!this.props.searchStats && this.props.listOfPersons && this.props.listOfPersons?.length > 0 &&
                            <React.Fragment>
                                <h2 className="main-title">{formatMessage('search_results')}</h2>
                                <div className="pageBar">
                                    <div className="itemsPerPageWrapper">
                                        <span>{formatMessage('search_displaying')}&nbsp;</span>
                                    </div>
                                    <div className="pagination-display" style={{ width: '80%' }}>
                                        {this.maxPage > 1 &&
                                            <Pagination
                                                changeInput={this.changeInput}
                                                onChangeNext={this.onChangeNext}
                                                onChangePrevious={this.onChangePrevious}
                                                maxPage={this.maxPage}
                                                pageNumber={this.state.paginationRequest.pageNumber}
                                                textDisplay={true} />
                                        }
                                    </div>
                                </div>
                            </React.Fragment>
                        }

                    </div>
                </div >
            </div >
        )
    }

    private searchActive = (): boolean => {

        if (this.state.errors.dateField) {
            return true;
        }

        if (this.state.searchName === '') {
            return true;
        }

        return false;
    }

    private changeCountryPrefilter = (values: OptionsType<{ value: string; label: string; }>, actionMeta: ActionMeta<{ value: string; label: string; }>): void => {

        let countryPref: string[] = [];

        for (let i = 0; i < values.length; i++) {
            countryPref.push(values[i].value);
        }

        this.setState({
            ...this.state,
            countryPrefilter: countryPref
        });
    }

    private changeCountryFieldPrefilter = (values: OptionsType<{ value: string; label: string; }>, actionMeta: ActionMeta<{ value: string; label: string; }>): void => {

        let countryPref: string[] = [];

        for (let i = 0; i < values.length; i++) {
            countryPref.push(values[i].value);
        }

        this.setState({
            ...this.state,
            countryFields: countryPref
        });
    }


    private changeDateFieldPrefilter = (values: OptionsType<{ value: string; label: string; }>, actionMeta: ActionMeta<{ value: string; label: string; }>): void => {

        let dateFieldPref: string[] = [];

        for (let i = 0; i < values.length; i++) {
            dateFieldPref.push(values[i].value);
        }

        this.setState({
            ...this.state,
            dateFields: dateFieldPref
        });
    }

    private changeCountryAlgoPrefilter = (value: { value: string; label: string; } | null, actionMeta: ActionMeta<{ value: string; label: string; }>) => {
        if (value) {
            this.setState({
                ...this.state,
                countryAlgo: value
            });
        }
    }

    private changeDateAlgoPrefilter = (value: { value: string; label: string; } | null, actionMeta: ActionMeta<{ value: string; label: string; }>) => {
        if (value) {
            this.setState({
                ...this.state,
                dateAlgo: value
            });
        }
    }

    private handleMaxPerPageChange = (e: any) => {
        let paginationRequest: PaginationRequest = this.state.paginationRequest;
        paginationRequest.pageNumber = 0;
        paginationRequest.maxPerPage = e.target.value;

        if (this.props.currentUser && this.props.currentUser.organizationUnits.length > 0 && this.props.currentUser.organizationUnits[0].defaultSearchScore) {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification, paginationRequest,
                this.props.currentUser.organizationUnits[0].defaultSearchScore, this.props.screenaKey
                , false, false, this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        } else {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification, paginationRequest,
                Number.NaN, this.props.screenaKey
                , false, false, this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        }


        this.setState({
            ...this.state,
            paginationRequest: paginationRequest
        })
    }

    private onChangePrevious = () => {
        let paginationRequest: PaginationRequest = this.state.paginationRequest;
        paginationRequest.pageNumber -= 1

        if (this.props.currentUser && this.props.currentUser.organizationUnits.length > 0 && this.props.currentUser.organizationUnits[0].defaultSearchScore) {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification, paginationRequest,
                this.props.currentUser.organizationUnits[0].defaultSearchScore,
                this.props.screenaKey
                , false, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        } else {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification, paginationRequest, Number.NaN,
                this.props.screenaKey
                , false, false, this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        }


        this.setState({
            ...this.state,
            paginationRequest: paginationRequest
        })
    }

    private onChangeNext = () => {
        let paginationRequest: PaginationRequest = this.state.paginationRequest;
        paginationRequest.pageNumber += 1

        if (this.props.currentUser && this.props.currentUser.organizationUnits.length > 0 && this.props.currentUser.organizationUnits[0].defaultSearchScore) {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification, paginationRequest,
                this.props.currentUser.organizationUnits[0].defaultSearchScore, this.props.screenaKey
                , false, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        } else {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification, paginationRequest,
                Number.NaN, this.props.screenaKey
                , false, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        }

        this.setState({
            ...this.state,
            paginationRequest: paginationRequest
        })
    }

    private changeInput = (e: any) => {
        let paginationRequest: PaginationRequest = this.state.paginationRequest;
        paginationRequest.pageNumber = e;

        if (this.props.currentUser && this.props.currentUser.organizationUnits.length > 0 && this.props.currentUser.organizationUnits[0].defaultSearchScore) {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification, paginationRequest,
                this.props.currentUser.organizationUnits[0].defaultSearchScore, this.props.screenaKey
                , false, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        } else {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification, paginationRequest,
                Number.NaN, this.props.screenaKey
                , false, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        }


        this.setState({
            ...this.state,
            paginationRequest: paginationRequest
        })
    }

    private resetAdvancedFilter = () => {

        let paginationRequest: PaginationRequest = this.state.paginationRequest;
        paginationRequest.pageNumber = 0;

        if (this.props.currentUser && this.props.currentUser.organizationUnits.length > 0 && this.props.currentUser.organizationUnits[0].defaultSearchScore) {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value,
                this.state.countryPrefilter, this.state.countryAlgo.value, [], [],
                this.state.currentClassification, paginationRequest
                , this.props.currentUser.organizationUnits[0].defaultSearchScore,
                this.props.screenaKey
                , false, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        } else {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value,
                this.state.countryPrefilter, this.state.countryAlgo.value, [], [],
                this.state.currentClassification, paginationRequest, Number.NaN,
                this.props.screenaKey
                , false, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        }

        this.setState({
            ...this.state,
            selectedDataset: [],
            selectedEntityType: [],
            datePrefilter:'',
            countryPrefilter:[],            
            paginationRequest: paginationRequest,
            errors : {
                dateField : false
            }
        })
    }

    private addEntityTypePreFilter = (e: any) => {

        if (!this.state.selectedEntityType.includes(e.target.id)) {
            let entityTypeFilter: string[] = this.state.selectedEntityType;
            entityTypeFilter.push(e.target.id);

            let paginationRequest: PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = 0;

            this.setState({
                ...this.state,
                selectedEntityType: entityTypeFilter,
                paginationRequest: paginationRequest
            })
        }

    }

    private removeEntityTypeFilter = (e: any) => {
        if (this.state.selectedEntityType.includes(e.target.id)) {

            let entityTypeFilter: string[] = [];

            for (let i = 0; i < this.state.selectedEntityType.length; i++) {
                if (this.state.selectedEntityType[i] !== e.target.id) {
                    entityTypeFilter.push(this.state.selectedEntityType[i]);
                }
            }

            let paginationRequest: PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = 0;

            if (this.props.currentUser && this.props.currentUser.organizationUnits.length > 0 && this.props.currentUser.organizationUnits[0].defaultSearchScore) {
                searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value,
                    this.state.countryPrefilter, this.state.countryAlgo.value, entityTypeFilter,
                    this.state.selectedDataset, this.state.currentClassification, paginationRequest,
                    this.props.currentUser.organizationUnits[0].defaultSearchScore
                    , this.props.screenaKey
                    , false, false , this.props.currentUser?.userName,
                    this.state.dateFields, this.state.countryFields);
            } else {
                searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value,
                    this.state.countryPrefilter, this.state.countryAlgo.value, entityTypeFilter,
                    this.state.selectedDataset, this.state.currentClassification, paginationRequest, Number.NaN
                    , this.props.screenaKey
                    , false, false , this.props.currentUser?.userName,
                    this.state.dateFields, this.state.countryFields);
            }


            this.setState({
                ...this.state,
                selectedEntityType: entityTypeFilter,
                paginationRequest: paginationRequest
            })

        }
    }

    private addDatasetPreFilter = (e: any) => {
        if (!this.state.selectedDataset.includes(e.target.id)) {
            let datasetFilter: string[] = this.state.selectedDataset;
            datasetFilter.push(e.target.id);

            let paginationRequest: PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = 0;

            this.setState({
                ...this.state,
                selectedDataset: datasetFilter,
                paginationRequest: paginationRequest
            })
        }
    }

    private removeDatasetFilter = (e: any) => {
        if (this.state.selectedDataset.includes(e.target.id)) {

            let datasetFilter: string[] = [];

            for (let i = 0; i < this.state.selectedDataset.length; i++) {
                if (this.state.selectedDataset[i] !== e.target.id) {
                    datasetFilter.push(this.state.selectedDataset[i]);
                }
            }

            let paginationRequest: PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = 0;

            if (this.props.currentUser && this.props.currentUser.organizationUnits.length > 0 && this.props.currentUser.organizationUnits[0].defaultSearchScore) {
                searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value,
                    this.state.countryPrefilter, this.state.countryAlgo.value, this.state.selectedEntityType,
                    datasetFilter, this.state.currentClassification, paginationRequest,
                    this.props.currentUser.organizationUnits[0].defaultSearchScore,
                    this.props.screenaKey
                    , false, false , this.props.currentUser?.userName,
                    this.state.dateFields, this.state.countryFields);
            } else {
                searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value,
                    this.state.countryPrefilter, this.state.countryAlgo.value, this.state.selectedEntityType,
                    datasetFilter, this.state.currentClassification, paginationRequest, Number.NaN,
                    this.props.screenaKey
                    , false, false , this.props.currentUser?.userName,
                    this.state.dateFields, this.state.countryFields);
            }

            this.setState({
                ...this.state,
                selectedDataset: datasetFilter
            })

        }
    }

    private changeDatePrefilter = (e: any) => {

        let errors = this.state.errors;
        if (e.target.value === '' || validateScreenaDateFormat(e.target.value)) {
            errors.dateField = false;
        } else {
            errors.dateField = true;
        }

        this.setState({
            ...this.state,
            datePrefilter: e.target.value,
            errors: errors
        })
    }

    private changeClassification = (e: any) => {
        cleanSearchStats();
        this.savedSearchStats = undefined;

        let paginationRequest: PaginationRequest = this.state.paginationRequest;
        paginationRequest.pageNumber = 0;

        if (this.props.currentUser && this.props.currentUser.organizationUnits.length > 0 && this.props.currentUser.organizationUnits[0].defaultSearchScore) {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter, this.state.countryAlgo.value, [],
                [], e.target.id, paginationRequest, this.props.currentUser.organizationUnits[0].defaultSearchScore
                , this.props.screenaKey
                , false, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        } else {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter, this.state.countryAlgo.value, [],
                [], e.target.id, paginationRequest, Number.NaN, this.props.screenaKey
                , false, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        }

        this.setState({
            ...this.state,
            currentClassification: e.target.id,
            paginationRequest: paginationRequest,
            selectedDataset: [],
            selectedEntityType: [],
        });

    }

    private setShowPrefIlterAdvanced = (e: any) => {
        this.setState({
            ...this.state,
            showPrefilterAdvanced: !this.state.showPrefilterAdvanced
        })
    }

    private resetSearchName = () => {
        cleanSearch();

        let paginationRequest: PaginationRequest = this.state.paginationRequest;
        paginationRequest.pageNumber = 0;

        this.savedSearchStats = undefined;
        this.maxPage = 0;
        this.setState({
            ...this.state,
            searchName: '',
            selectedDataset: [],
            selectedEntityType: [],
            paginationRequest: paginationRequest,
            currentClassification: "",
            datePrefilter: '',
            countryPrefilter: []
        })
    }


    private resetSearchDate = () => {
        this.setState({
            ...this.state,
            datePrefilter: '',
            errors: {
                dateField: false
            }
        })
    }

    private onKeyPressSearchinput = (event: any) => {

        if (event.code === "Enter" || event.code === "NumpadEnter") {
            let paginationRequest: PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = 0;
            this.savedSearchStats = undefined;
            if (this.props.currentUser && this.props.currentUser.organizationUnits.length > 0 && this.props.currentUser.organizationUnits[0].defaultSearchScore) {
                searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                    this.state.countryAlgo.value, this.state.selectedEntityType,
                    this.state.selectedDataset, this.state.currentClassification,
                    paginationRequest, this.props.currentUser.organizationUnits[0].defaultSearchScore, this.props.screenaKey
                    , false, false , this.props.currentUser?.userName,
                    this.state.dateFields, this.state.countryFields);
            } else {
                searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                    this.state.countryAlgo.value, this.state.selectedEntityType,
                    this.state.selectedDataset, this.state.currentClassification,
                    paginationRequest, Number.NaN, this.props.screenaKey
                    , false, false , this.props.currentUser?.userName,
                    this.state.dateFields, this.state.countryFields);
            }
        }
    }

    private onSearchButton = (event: any) => {
        let paginationRequest: PaginationRequest = this.state.paginationRequest;
        paginationRequest.pageNumber = 0;
        this.savedSearchStats = undefined;
        if (this.props.currentUser && this.props.currentUser.organizationUnits.length > 0 && this.props.currentUser.organizationUnits[0].defaultSearchScore) {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification,
                paginationRequest, this.props.currentUser.organizationUnits[0].defaultSearchScore, this.props.screenaKey
                , false, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        } else {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification,
                paginationRequest, Number.NaN, this.props.screenaKey
                , false, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        }
    }

    private ExportButton = (event: any) => {
        let paginationRequest: PaginationRequest = this.state.paginationRequest;
        paginationRequest.pageNumber = 0;
        this.savedSearchStats = undefined;
        if (this.props.currentUser && this.props.currentUser.organizationUnits.length > 0 && this.props.currentUser.organizationUnits[0].defaultSearchScore) {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification,
                paginationRequest, this.props.currentUser.organizationUnits[0].defaultSearchScore, this.props.screenaKey
                , true, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        } else {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification,
                paginationRequest, Number.NaN, this.props.screenaKey
                , true, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        }
    }

    private ExportButtonPDF = (event: any) => {
        let paginationRequest: PaginationRequest = this.state.paginationRequest;
        paginationRequest.pageNumber = 0;
        this.savedSearchStats = undefined;
        if (this.props.currentUser && this.props.currentUser.organizationUnits.length > 0 && this.props.currentUser.organizationUnits[0].defaultSearchScore) {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification,
                paginationRequest, this.props.currentUser.organizationUnits[0].defaultSearchScore, this.props.screenaKey
                , false, true , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        } else {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification,
                paginationRequest, Number.NaN, this.props.screenaKey
                , false, true , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        }
    }

    private onChangeSearchInput = (e: any) => {
        if (e.target.value === '') {
            cleanSearch();
        }

        // Clears the previously set timer.
        // clearTimeout(this.typingTimeout);

        // Reset the timer, to make the http call after 100MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
        // this.typingTimeout = setTimeout(this.callSearch, 500);

        let paginationRequest: PaginationRequest = this.state.paginationRequest;
        paginationRequest.pageNumber = 0;

        this.setState({
            ...this.state,
            searchName: e.target.value,
            paginationRequest: paginationRequest
        })
    }

    private callSearch = () => {

        let paginationRequest: PaginationRequest = this.state.paginationRequest;
        paginationRequest.pageNumber = 0;
        this.savedSearchStats = undefined;

        if (this.props.currentUser && this.props.currentUser.organizationUnits.length > 0 && this.props.currentUser.organizationUnits[0].defaultSearchScore) {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification,
                paginationRequest, this.props.currentUser.organizationUnits[0].defaultSearchScore, this.props.screenaKey
                , false, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        } else {
            searchScreenaFullUniqueDataSets(this.state.searchName, this.state.datePrefilter, this.state.dateAlgo.value, this.state.countryPrefilter,
                this.state.countryAlgo.value, this.state.selectedEntityType,
                this.state.selectedDataset, this.state.currentClassification,
                paginationRequest, Number.NaN, this.props.screenaKey
                , false, false , this.props.currentUser?.userName,
                this.state.dateFields, this.state.countryFields);
        }

    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
    userDashBoardPreferences: state.userStore.userDashBoardPreferences,
    screenaKey: state.loginStore.screenaapikey,
    currentUser: state.userStore.currentUser,

    countries: state.countryStore.fullListOfCountries,

    fullListOfDatasets: state.searchScreenaDatasetStore.datasets,
    listOfPersons: state.searchScreenaDatasetStore.listOfPersons,
    searchStats: state.searchScreenaDatasetStore.searchStats,
})

export const Search = connect(mapStateToProps, null)(SearchClass);
