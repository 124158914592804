import React from "react";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { formatMessage } from "../../common/translate/Translate";

interface IntProps {    
    display:string,
    id:string
}

interface IntState {
    
}

export class HeaderColumnWithoutSort extends React.Component<IntProps, IntState> {

    public render() {
        return (
            <th key={this.props.id} className={`c-table__head-data c-table__head-data--sortable js-sortable-head`}  >                               
                <span id={this.props.id} className="c-table__sort" style={{paddingBottom:'4px'}}>                     
                    {formatMessage(this.props.display).toUpperCase()}
                </span>
            </th>
         );
    }
}