import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { User } from "../../model/user/User";
import { UserPreferences } from "../../model/user/UserPreferences";
import { Request } from "../../model/common/Request";
import { updateUserDashboardPreferences, updateUserPreferences } from "../../store/user/action";
import Select, { ActionMeta, OptionsType } from 'react-select';
import { HeaderConstant } from "../../common/constant/HeaderConstant";
import { UserDashBoardPreferences } from "../../model/user/UserDashBoardPreferences";
import { isUserHasPermission } from "../../common/helper/PermissionHelper";
import { Permissions } from "../../common/constant/Permissions";
import { ApiKey } from "../../model/screena/ApiKey";

interface IntProps {
    visible?: boolean,
    x?: number,
    y?: number,
    apikey?: ApiKey,
    screenaKey?: string,
    closePopup?: () => void,
    user?: User,
    userDashBoardPreference?: UserDashBoardPreferences
}

interface IntState {
    amountRecordsPerPage: number,
    amountRecordsPerPageTxt: string,
    tabSelected: string,
    userDashBoardPreference: UserDashBoardPreferences,
    errors: {
        amountRecordsPerPage: string
    }
}

class UserPreferencesPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)

        let amountOfRecordPerPageUser: number = 10;

        if (this.props.user && this.props.user.userPreferences) {
            amountOfRecordPerPageUser = this.props.user.userPreferences.maxAmountOfRowForGrid;
        }

        if (this.props.userDashBoardPreference) {
            this.state = {
                tabSelected: HeaderConstant.GENERAL,
                amountRecordsPerPageTxt: String(amountOfRecordPerPageUser),
                amountRecordsPerPage: amountOfRecordPerPageUser,
                userDashBoardPreference: this.props.userDashBoardPreference,
                errors: {
                    amountRecordsPerPage: ''
                }
            }
        } else {
            this.state = {
                tabSelected: HeaderConstant.GENERAL,
                amountRecordsPerPageTxt: String(amountOfRecordPerPageUser),
                amountRecordsPerPage: amountOfRecordPerPageUser,
                userDashBoardPreference: new UserDashBoardPreferences(),
                errors: {
                    amountRecordsPerPage: ''
                }
            }
        }

    }

    public componentWillReceiveProps(nextProps: IntProps) {
        if (nextProps.userDashBoardPreference) {
            this.setState({
                ...this.state,
                userDashBoardPreference: nextProps.userDashBoardPreference,
            });
        }
    }

    public render() {

        const options = [
            { value: 'alertId', label: formatMessage('alert_alertId') },
            { value: 'alertName', label: formatMessage('alert_alertName') },
            { value: 'alertDescription', label: formatMessage('alert_alertDescription') },
            { value: 'alertScore', label: formatMessage('alert_alertScore') },
            { value: 'alertPriority', label: formatMessage('alert_alertPriority') },
            { value: 'alertMessageType', label: formatMessage('alert_alertMessageType') },
            { value: 'alertTrxnReference', label: formatMessage('alert_alertTrxnReference') },
            { value: 'alertTrxnAmount', label: formatMessage('alert_alertTrxnAmount') },
            { value: 'alertDateTime', label: formatMessage('alert_alertDateTime') },
            { value: 'alertSourceName', label: formatMessage('alert_alertSourceName') },
            { value: 'alertType', label: formatMessage('alert_alertType') },
            { value: 'alertStatus', label: formatMessage('alert_alertStatus') },
            { value: 'alertAssignTo', label: formatMessage('alert_alertAssignedTo') },
            { value: 'alertMatchScore', label: formatMessage('alert_alertMatchScore') },
            { value: 'alertTargetName', label: formatMessage('alert_alertTargetName') }
        ]

        let selectedColumns: { value: string; label: string; }[] = [];
        if (this.state.userDashBoardPreference && this.state.userDashBoardPreference.listOfFieldsToDisplay) {
            for (var alertColumn of this.state.userDashBoardPreference.listOfFieldsToDisplay) {
                selectedColumns.push(
                    {
                        value: String(alertColumn),
                        label: formatMessage('alert_' + alertColumn)
                    }
                )
            }
        }

        let header: Array<{ id: string, name: string, visibleTab: string, closeAble: boolean }> = this.getHeader();

        if (this.props.user && this.props.user.userPreferences) {
            if (this.state.amountRecordsPerPage !== this.props.user.userPreferences.maxAmountOfRowForGrid) {
                this.setState({
                    ...this.state,
                    amountRecordsPerPage: this.props.user.userPreferences.maxAmountOfRowForGrid
                })
            }
        }

        return (

            <div className={this.props.visible === true ? "d-modal" : "c-popover c-lc-popover js-lc-context"} style={{ width: "50rem", height: "20rem" }}>

                <div className="d-modal__view js-modal-view">
                    <div className="d-modal__head">
                        <legend className="d-modal__title">
                            {formatMessage('alert_userpreferences')}
                        </legend>
                        <div>
                            <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                <svg className="o-icon o-icon--close o-icon--prepended">
                                    <title>{IconsEnum.CLOSE.title}</title>
                                    <use href={IconsEnum.CLOSE.url} />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <nav className="c-tabs__nav">
                        <ul className="c-tabs__list">
                            {header.map((head, index) => {
                                return (
                                    <li className="c-tabs__title" key={index}>
                                        <button className={`c-tabs__link js-tabs-link ${this.state.tabSelected === head.visibleTab ? 'is-active' : ''}`}
                                            id={head.id}
                                            name={head.id} onClick={this.changeVisibility}>
                                            <span id={head.id}>{head.name}</span>
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>

                    {this.state.tabSelected === HeaderConstant.GENERAL &&
                        <div className="d-modal__view js-modal-view" style={{ margin: "1rem" }}>
                            <div style={{ display: 'flex' }}>
                                <label className="o-label" style={{ width: '160px', fontWeight:'bold' }}>
                                    {formatMessage('user_amountofRecordsPerPage')}
                                </label>
                                <input
                                    className="o-input"
                                    name="amountofRecordsPerPage"
                                    placeholder={formatMessage('user_amountofRecordsPerPage')}
                                    value={this.state.amountRecordsPerPageTxt}
                                    onChange={this.changeAmountOfRecordsPerPage}
                                    style={{ marginTop: '2px' }}
                                />
                            </div>
                        </div>
                    }

                    {this.state.tabSelected === HeaderConstant.ALERTS &&
                        <div className="d-modal__view js-modal-view" style={{ margin: "1rem" }}>
                            <div style={{ display: 'flex' }}>
                                <label className="o-label" style={{ width: '160px', fontWeight:'bold' }}>
                                    {formatMessage('alertStatus_search')}
                                </label>
                                <Select options={options}
                                    value={selectedColumns}
                                    name="alertColumns"
                                    isMulti
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={this.changeAlertFieldDisplay}
                                    style={{ width: '80px' }}
                                />
                            </div>
                        </div>
                    }

                    <div style={{ display: 'flex' }}>
                        <button className="m-button m-button--small-margin-bottom" onClick={this.props.closePopup} style={{ margin: '5px' }}>
                            {formatMessage('button_cancel')}
                        </button>

                        <button className="m-button m-button--small-margin-bottom" onClick={this.saveUserPreferences} style={{ margin: '5px' }}>
                            {formatMessage('button_save')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    private changeAlertFieldDisplay = (values: OptionsType<{ value: string; label: string; }>, actionMeta: ActionMeta<{ value: string; label: string; }>): void => {

        let userDBPref: UserDashBoardPreferences = new UserDashBoardPreferences();

        userDBPref.listOfFieldsToDisplay = [];

        for (let i = 0; i < values.length; i++) {
            userDBPref.listOfFieldsToDisplay.push(values[i].value);
        }

        this.setState({
            ...this.state,
            userDashBoardPreference: userDBPref
        });
    }

    private changeAmountOfRecordsPerPage = (event: { target: { value: any; }; }): void => {

        let amountOfRecord: number = Number.parseInt(event.target.value);

        if (Number.isNaN(amountOfRecord)) {
            this.setState({
                ...this.state,
                amountRecordsPerPageTxt: event.target.value
            });
        } else {
            this.setState({
                ...this.state,
                amountRecordsPerPage: amountOfRecord,
                amountRecordsPerPageTxt: String(amountOfRecord)
            });
        }
    }

    private changeVisibility = (event: any) => {
       
        this.setState({
            ...this.state,
            tabSelected: event.target.id
        })
    }

    private saveUserPreferences = () => {
        if (this.props.user) {
            let user: User = this.props.user;
            if (!user.userPreferences) {
                user.userPreferences = new UserPreferences();
            }
            user.userPreferences.maxAmountOfRowForGrid = this.state.amountRecordsPerPage;

            let request: Request = new Request();
            request.user = user;
            request.userPreferences = user.userPreferences;

            updateUserPreferences(request);

            if (this.props.closePopup) {
                this.props.closePopup();
            }

            if (this.state.tabSelected === HeaderConstant.ALERTS) {
                let request: Request = new Request();
                request.userDashBoardPreferences = this.state.userDashBoardPreference;
                request.user = this.props.user;
                updateUserDashboardPreferences(request);
            }
        }
    }

    private getHeader = () => {
        const header: Array<{ id: string, name: string, visibleTab: string, closeAble: boolean }> = [];

        header.push({ id: HeaderConstant.GENERAL, name: formatMessage('main_tab_header_general'), visibleTab: HeaderConstant.GENERAL, closeAble: false });
        if (isUserHasPermission(this.props.user, Permissions.ALERTS)) {
            header.push({ id: HeaderConstant.ALERTS, name: formatMessage('main_tab_header_alerts'), visibleTab: HeaderConstant.ALERTS, closeAble: false });
        }

        return header;
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
    user: state.userStore.currentUser,
    userDashBoardPreference: state.userStore.userDashBoardPreferences,
    apikey: state.searchScreenaDatasetStore.apiKeyStat,
    screenaKey: state.loginStore.screenaapikey,
})

export const UserPreferencesPopup = connect(mapStateToProps, null)(UserPreferencesPopupClass);