import { Alert } from "../alerts/Alert";
import { Comment } from "../alerts/Comment";
import { WorkflowStatus } from "../alerts/WorkflowStatus";
import { DataSets } from "../dataset/DataSets";
import { DataSetsFolder } from "../dataset/DataSetsFolder";
import { Job } from "../job/Job";
import { Person } from "../persons/Person";
import { SavedSearch } from "../savedsearch/SavedSearch";
import { MatchingRule } from "../screening/MatchingRule";
import { Group } from "../user/Group";
import { OrganizationUnit } from "../user/OrganizationUnit";
import { Role } from "../user/Role";
import { User } from "../user/User";
import { UserDashBoardPreferences } from "../user/UserDashBoardPreferences";
import { UserPreferences } from "../user/UserPreferences";
import { PaginationRequest } from "./PaginationRequest";
import { RequestDataObject } from "./RequestDataObject";
import { RequestMetaData } from "./RequestMetaData";

export class Request {
    public savedSearch!:SavedSearch;

    public alert!:Alert;
    public person!:Person;

    public alertNextStatus!:WorkflowStatus;

    public comment!:Comment;

    public job!:Job;

    public queries!:RequestDataObject[];
    public targetData!:RequestDataObject[];
    public requestHeader!:RequestMetaData;

    // Dataset Management
    public dataset!:DataSets;
    public datasetFolder!:DataSetsFolder;

    // User Management
    public user!:User;
    public previousPassword!:string;
    public userPreferences!:UserPreferences;
    public userDashBoardPreferences!:UserDashBoardPreferences;
    public group!:Group;
    public role!:Role;
    public organizationUnit!:OrganizationUnit;

    // Pagination
    public paginationRequest!:PaginationRequest;

    // Screena Fields
    public datasets!:DataSets[];

    // MAtching Configuration
    public matchingRules!: MatchingRule[];

    // Workflow Status
    public workflowStatus!:WorkflowStatus;


}