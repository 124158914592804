import { logout } from "../../store/login/action";
import { deleteCookie } from "./CookieHelper";

export const manageAxiosError = (error: any) => {
    console.log("Axios Error " + error.response?.data.status);
    if (error.response?.data?.status===401) {
        RedirectToLginPage();
    }
  
}

export const manageAxiosErrorScreena = (error: any) => {
    console.log("Axios Erreor Screena " + error.response?.data.status);
    
}

export const RedirectToLginPage = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('user');
    logout();
    deleteCookie("SESSION");
    window.location.replace("/");  
}