export const english = {
    button_search: 'Search',
    button_cancel: 'Cancel',
    button_save: 'Save',
    button_create: 'Create',
    button_upload : 'Upload',
    button_import : 'Import',
    button_delete : 'Delete',
    button_export : 'Export',
    button_import_dataset : 'Import dataset',
    button_plus : '+',
    button_create_folder : 'Create Folder',
    button_next:'Next',
    button_previous:'Previous',
    button_export_pdf: 'Export as PDF',
    button_ok: 'Ok',

    // Import
    import_successfull: 'Import successful',
    import_processing: 'Processing import',
    
    // Login form
    login_username: 'User Name',
    login_password: 'Password',
    login_button: 'Login',

    // Main Tabs
    main_tab_header_search: 'Search',
    main_tab_header_general: 'General',
    main_tab_header_alerts: 'Alerts',
    main_tab_header_risks: 'Risks',
    main_tab_header_admin: 'Administration',
    main_tab_header_datasets: 'Datasets',
    main_tab_header_customer: 'Customer',
    main_tab_header_watchlist: 'Watchlist',

    // Comment add
    comment_text : 'Comment...',

    // Admin Context
    admin_context_edit : 'Edit',
    admin_context_delete : 'Delete',
    admin_context_start : 'Start',

    // Alerts Tab
    alert_alertName: 'Alert Name',
    alert_alertDescription: 'Description',
    alert_alertId: 'ID',
    alert_alertScore: 'Alert Score',
    alert_alertPriority: 'Alert Priority',
    alert_alertMessageType: 'Message Type',
    alert_alertTrxnReference: 'Transaction Reference',
    alert_alertTrxnAmount: 'Transaction Amount',
    alert_alertDateTime: 'Date/Time',
    alert_alertSourceName: 'Alerted',
    alert_alertType: 'Type',
    alert_alertStatus: 'Status',
    alert_alertMatchScore: 'Match Score',
    alert_alertTargetName: 'Highest Hit',
    alert_alertAssignedTo: 'Assigned To',
    alert_alertAssignTo: 'Assigned To',

    // Context menu
    alert_context_showmatch_details: 'Show match details',
    alert_context_showcustomer_details: 'Show customer details',
    alert_context_showwatchlist_details: 'Show watchlist details',

    // Alert Details
    alert_details_information_title: 'Details',
    alert_details_creationDate: 'Creation Date',
    alert_details_updatedDate: 'Update Date',
    alert_details_createdBy: 'Created By',
    alert_details_updatedBy: 'Updated By',
    alert_details_id: 'Id',
    alert_userpreferences : 'User Preferences',

    alert_status_open: 'Open',
    alert_status_close: 'Close',

    // Alert Search
    keyword_filter: 'Filters',
    keyword_search: 'Keyword Matches',
    keyword_search_placeholder: 'Enter Keyword...',
    alertid_search: 'Alert ID is',
    alertid_search_placeholder: 'Enter Alert ID...',
    datebetween_search: 'Date is between',
    datebetween_dateformat_search: 'DD/MM/YYYY',
    alertSearch_title: 'Alert Dashboard',
    alertStatus_search: 'Status',
    datasets_search: 'Datasets',

    saved_search_name: 'Name',
    keyword_alerts: 'Alerts',

    // Alert Search
    savedsearch_context_save: 'Save',
    savedsearch_context_delete: 'Delete',
    savedsearch_context_rename: 'Rename',

    // Person Details
    person_details_fullName: 'Fullname',
    person_details_surname: 'Surname',
    person_details_givenName: 'Given Name',
    person_details_fatherName: 'Father Name',
    person_details_dob: 'Date Of Birth',
    person_details_dobuild: 'Date Of Build',
    person_details_doregistry: 'Date Of Registry',
    person_details_nationality: 'Nationality',
    person_details_dataid: 'Data ID',
    person_details_pobs: 'Place of Birth',
    person_details_pors: 'Place of Registry',
    person_details_idDoc: 'Identification Document',
    person_details_sex: 'Sex',
    person_details_entityType: 'Entity Type',
    person_details_keywords: 'Keywords',
    person_risk_score: 'Score',
    person_details_location: 'Address',
    person_details_titles: 'Titles',
    person_details_name: 'Name',
    person_details_alias: 'Aliases',
    person_details_programs: 'Programs',
    person_details_sources: 'Sources',
    person_details_addinfo: 'Additional Information',
    person_details_contactinfo: 'Contact Information',
    person_details_digitalcurrency: 'Digital Currency',
    person_details_flags : 'Flags',
    person_details_bic: 'BIC',
    person_details_lei: 'LEI',
    person_details_categories: 'Category',
    person_details_positions: 'Position',
    person_details_risks: 'Risks',
    person_details_links: 'Links',
    
    // Person Score card
    person_scorecard_aggregate: 'Aggregate',
    person_scorecard_risk : 'Risk',
    person_scorecard_risk_since : 'Risk since',
    person_scorecard_openalerts : 'Open Alerts',
    person_scorecard_opencases : 'Open Cases',

    // Risk display
    risk_name: 'Name',
    risk_type: 'Type',
    risk_since: 'Since',
    risk_openAlerts: 'Open Alerts',
    risk_score: 'Risk Score',
    risk_aggregate: 'Risk Aggregate',
    risk_accountNumber: 'Account Number',
    risk_lastUpdate: 'Last Update',
    risk_status: 'Status',
    risk_investigatedby: 'Investigated By',
    risk_alertAge: 'Alert Age',
    // Risk
    risk_category : 'Category',
    risk_subCategory : 'Subcategory',

    alertPopup_tab_header_wkfcomment: 'Workflow',
    alertPopup_tab_header_comment: 'Comment',
    alertPopup_tab_header_transactions: 'Transactions',
    alertPopup_tab_header_files : 'Files',

    // Administration tab
    admin_main_user : 'Users',
    admin_main_group : 'Groups',
    admin_main_role : 'Roles',
    admin_main_dataset : 'Datasets',
    admin_main_organization_unit : 'Organization Units',
    admin_main_scheduler : 'Scheduler',
    admin_main_audits : 'Audits',
    admin_main_risk : 'Risks',
    admin_main_screening : 'Screening',
    admin_main_workflowstatus : 'Workflow Status',

    // Jobs
    jobs_cronexpression : 'Cron Expression',
    jobs_active : 'Active',
    job_action_created : 'Job Created',
    job_action_updated : 'Job Updated',
    job_action_deleted : 'Job Deleted',
    job_action_started : 'Job started',
    job_lastexecutiontime : 'Last Execution Time',
    job_nextexecutiontime : 'Next Execution Time',

    job_type_matching : 'Matching',
    job_type_export : 'Export',
    job_type_import : 'Import',

    jobs_source : 'Source',
    jobs_profile : 'Profile',

    // Dataset
    admin_dataset_label : 'Label',
    admin_dataset_description : 'Description',
    admin_dataset_id : 'Id',
    admin_dataset_created : 'Created',
    admin_dataset_organization : 'Organization',

    // Common
    common_createdBy : 'Created By',
    common_createdOn : 'Created On',
    common_updatedBy : 'Updated By',
    common_updatedOn : 'Updated On',
    common_id : 'ID',
    common_id_placeholder : 'Enter ID...',
    common_dataid : 'Data ID',
    common_dataid_placeholder : 'Enter Data ID...',
    common_name : 'Name',
    common_description : 'Description',
    common_email : 'Email',
    common_groups : 'Groups',
    common_roles : 'Roles',
    common_users : 'Users',
    common_organizationunit : 'Organization Units',
    common_permissions: 'Permissions',
    common_type: 'Type',
    common_classification: 'Classification',
    common_date : 'Date',
    common_date_format : 'YYYY-MM-DD',
    common_country : 'Country',
    common_operation : 'Operation',
    common_score:'Score',
    common_apikey:'ApiKey',
    common_searchtreshold:'Search threshold',
    common_listcategory :'List Category',
    common_entitytype : 'Entity Type',
    common_amountOfRequest : 'Number of requests',
    
    // Risk Configuration
    risk_conf_category : 'Category',
    risk_conf_scenario : 'Scenario',
    risk_conf_fieldname : 'Field name',
    risk_conf_riskCategory : 'Risk Category',
    risk_conf_riskSubCategory : 'Risk Subcategory',
    risk_conf_entityType : 'Entity type',
    risk_conf_operation : 'Operation',
    risk_conf_profileName : 'Profile Name',
    risk_conf_riskValueMappingDataset : 'Dataset',
    risk_conf_score : 'Score',
    risk_conf_executionorder : 'Execution order',
    risk_conf_dataId : 'Name',
    risk_conf_droolsscenario : 'Drools Scenario',
    risk_conf_weight : 'Weight',
    risk_tab_rules: 'Rules',
    risk_tab_globalcalc: 'Category calculation',
  
    // User
    user_username : 'Login',
    user_email : 'Email',
    user_password : 'Password',
    user_previous_password : 'Previous password',
    user_apikey : 'ApiKey',
    user_locked : 'Locked',
    user_deleted : 'Deleted',
    user_password_verify : 'Verify user password',
    user_action_created : 'User created',
    user_action_updated : 'User updated',
    user_action_notupdated : 'User not updated',
    user_action_deleted : 'User deleted',

    user_error_username : 'You must enter an user name',
    user_error_email : 'You must enter an email',
    user_error_orgunit : 'A user must belong to an Organization Unit',
    user_error_role : 'A user must belong to a Role',

    // User Preferences
    user_menu_show_user_preferences : 'User Preferences',
    user_menu_show_change_password : 'Change password',
    user_menu_logout : 'Logout',
    
    user_amountofRecordsPerPage : 'Amount of records per page',

    // User password
    password_no_match: 'The passwords provided do not match',
    password_complexity: 'The password must contains at least 8 Characters with One uppercase, one lowercase, one digit and one special character',
    password_mandatory: 'You must enter the two passwords',
    title_change_password:'Change password',

    // DataSet
    dataset_organization : 'Organization Unit',
    dataset_action_created : 'Dataset Created',
    dataset_action_updated : 'Dataset Updated',
    dataset_sourceofdata : 'Source of data',
    dataset_search_title: 'Dataset Search',
    dataset_action_deleted: 'Dataset Deleted',

    // Orgunit
    orgunit_code : 'Code',
    orgunit_action_created : 'Organization unit created',
    orgunit_action_updated : 'Organization unit updated',
    orgunit_action_deleted : 'Organization unit deleted',

    // Role
    role_action_updated : 'Role updated',
    role_action_created : 'Role created',
    role_action_deleted : 'Role deleted',

    // Group
    group_action_created : 'Group created',
    group_action_updted  : 'Group updated',
    group_action_deleted : 'Group deleted',

    // Audit
    audit_action : 'Action',
    audit_entity : 'Entity',
    audit_value : 'Value',
    audit_username : 'User Name',
    audit_record : 'Record',
    audit_rule : 'Rule',
    audit_scenario : 'Scenario',
    audit_hits : 'Hits',
    audit_field : 'Field',
    audit_status : 'Status',
    audit_started : 'Started',
    audit_ended : 'Ended',
    audit_errors : 'Errors',

    audit_tab_header_user : 'User',
    audit_tab_header_screena_crawl : 'Crawl',
    audit_tab_header_screena_application : 'Application',
    audit_tab_header_screena_import : 'Import',
    audit_tab_header_screena_tasks : 'Tasks',
    audit_tab_header_screena_watchlist_import : 'Watchlist Import',
    audit_tab_header_scheduler_execution : 'Scheduler Execution',
    audit_tab_header_segrate_configuration : 'SegRate Configuration',
    audit_tab_header_segrate_entityrisk : 'SegRate Entity Risk',
    audit_tab_header_segrate_rulehit : 'SegRate Rule Hit',

    // Workflow audit
    workflow_audit_nodata : 'No workflow audit trace',
    workflow_closeSatus: 'Close',
    workflow_assignToUser: 'Assign to user',
    workflow_assignToGroup: 'Assign to group',
    workflow_keepCurrentStatus: 'Keep current status',
    workflow_nextSteps : 'Next Steps',
    workflow_created: 'Workflow Status Created',
    workflow_updated: 'Workflow Status Updated',
    workflow_deleted: 'Workflow status Deleted',

    // Comment audit
    comment_nodata : 'No comment data',

    // File 
    file_nodata : 'No file associated',

    // Audit Task
    audit_task_totalcount : 'Total count',
    audit_task_processed : 'Processed',
    audit_task_message : 'Message',
    audit_task_progress : 'Progress',
    audit_task_duration : 'Duration',
    audit_task_start : 'Start',
    audit_task_end : 'End',
    audit_task_source : 'Source',

    // Transaction
    transaction_baseamount : 'Base amount',
    transaction_localamount : 'Local amount',
    transaction_originalamount : 'Original amount',

    // Digital Currency
    digitalcurrency_currency : 'Currency',
    digitalcurrency_identifier : 'Identifier',

    // Account
    account_number : 'Number',
    account_bankIban : 'Bank IBAN',
    account_fullAccountNumber : 'Full Account Number',
    account_openDate : 'Open Date',
    account_closeDate : 'Close Date',    

    // Identification DOcument
    iddoc_number : 'Number',
    iddoc_country : 'Country',
    iddoc_type : 'Type',
    iddoc_dateofissue: 'Date of Issue',
    iddoc_dateofexpiry: 'Date of Expiry',

    // Location
    location_country : 'Country',
    location_zipcode : 'Postal Code',
    location_pobox : 'Postal Box',
    location_city : 'City',
    location_state : 'State',
    location_street : 'Street',

    // Customer tab
    customer_tab_header_alerts : 'Alerts',
    customer_tab_header_accounts : 'Accounts',
    customer_tab_header_trxns : 'Transactions',
    customer_tab_header_persons : 'Persons',
    customer_tab_header_files : 'Files',
    customer_tab_header_comments : 'Comments',
    customer_tab_header_risks : 'Risks',

    // Watchlist Tab
    watchlist_tab_header_keywords : 'Keywords',
    watchlist_tab_header_sources : 'Sources',
    watchlist_tab_header_sources_path : 'Path',
    watchlist_tab_header_sources_summary : 'Summary',
    watchlist_tab_header_persons : 'Persons',
    watchlist_tab_header_comments : 'Comments',
    watchlist_tab_header_addinfos : 'Additional Information',

    // Matching Rule
    matchingRule_list : 'Matching Rule list',
    matchingRule_alertDefinition : 'Alert Definition',
    matchingRule_priority : 'Priority',
    matchingRule_dataId : 'Data Id',
    matchingRule_datasets : 'Customer Source Dataset',
    matchingRule_alert_datasets : 'Alert Dataset',
    matchingRule_apikey : 'Api Key Source',
    matchingRule_profile : 'Profile',
    matchingRule_permutation : 'Permutation',
    matchingRule_truncation : 'Truncation',
    matchingRule_alertStatus : 'Status',
    matchingRule_targetDataset : 'Target Datasets',
    matchingRule_treshold : 'Threshold',

    // Sex mapping
    sex_m : 'Male',
    sex_f : 'Female',
    sex_u : 'Unknown',

    // Search
    search_resetFilter : 'Reset filters',
    search_advancedFilter : 'Advanced filters',
    search_tabAll : 'All',
    search_tabSanctions : 'Sanctions',
    search_tabPeps : 'PEPs',
    search_tabPepFormers : 'PEPs Former',
    search_tabPepLinked :'PEPs Linked',
    search_tabWatchlist: 'Watchlist',
    search_tabAdverseMedia: 'Adverse Media',
    search_tabWanted : 'Wanted',
    search_individual : 'Individual',
    search_organization : 'Organization',
    search_vessel : 'Vessel',
    search_unknown : 'Unknown',
    search_hideallsecondaryattr : 'Hide all secondary attributes',
    search_whyisitshown : 'Why is this shown?',
    search_normalized : 'Normalized',
    search_searched : 'Searched',
    search_returned : 'Returned',
    search_yourselection : 'Your selection',
    search_displaying : 'Displaying',
    search_backToTop : 'Back to top',
    search_score: 'Score',
    search_results: 'Results',
    search_no_match: ' Search completed with no match',
    search_bic: 'BIC',
    search_idnumber: 'Identification Number',
    search_lei: 'LEI',
    search_preFilter: 'Advanced search',
    search_placeholder: 'Search for an individual, an organization, or a vessel',

    // Search Algos
    same_day_month_year: 'Same Day/Month/Year',
    same_month_year:'Same Month/Year',
    same_year:'Same Year',
    same_quadrennium:'Same Quadrennium (source date +/-2 year)',
    same_decade:'Same Decade (source date +/-5 year)',

    same_country:'Same Country',
    same_subregion:'Same Subregion',
    same_region:'Same Region'
}
