import { User } from "../../model/user/User"
import { Constants } from "./constant"
import { UserLoginStore } from "./type"

const init: UserLoginStore = {
    loginResponse : new User(),
    screenaapikey : '',
    jwtToken: '',
    errorMessage:'',
    errorCode:''
} 
    
export const loginResponseReducer = (state: UserLoginStore = init, action: any): UserLoginStore => {    
    switch (action.type) {
        case Constants.LOGIN:
            return {
				...state,
				loginResponse: action.payload.user
            }
        case Constants.AUTHENTICATE:
            localStorage.setItem('jwt', 'Bearer ' + action.payload.token);            
            return {
                ...state,
                jwtToken: action.payload.token
            }  
            
        case Constants.SETAUTHENTICATE:
            localStorage.setItem('jwt', 'Bearer ' + action.payload);            
            return {
                ...state,
                jwtToken: action.payload
            }  
          
        case Constants.FAILEDAUTHENTICATE:
            return {
                ...state,
                errorMessage: action.payload.data.message,
                errorCode: action.payload.data.status
            } 
            
        case Constants.SCREENAAPIKEY:
            return {
                ...state,
                screenaapikey: action.payload.screenaApiKey
            }
        default:
            return {
				...state,
			}
    }
}