import { DataSets } from "../dataset/DataSets";
import { IdentificationDocument } from "./IdentificationDocument";
import { Mrz } from "./Mrz";
import { Name } from "./Name";
import { NormDate } from "./NormDate";
import { Location } from "./Location";
import { Comment } from "../alerts/Comment";
import { RiskCategory } from "./RiskCategory";
import { ContactInformation } from "./ContactInformation";
import { DigitalCurrency } from "./DigitalCurrency";
import { MatchingSource } from "../screena/MatchingSource";
import { MatchingField } from "../screena/MatchingField";

export class Person {

    public id!:number;

	public dataID!:string;
	public entityType!:string;
	public type!:string;
	
	public names!:Name[];
	public sex!:string;
	public originalScript!:string;

	public bic!:string;
	public lei!:string;

	public bics!:string[];
	public leis!:string[];
	
	public score!:number;
	public riskSince!:string;
	
	public datesOfRegistry!:NormDate[];
	public placesOfRegistry!:Location[]; 
	
	public cultureCodes!:string[];
	
    public datesOfBuild!:NormDate[];
	public flags!:Location[];
	public nationalities!:Location[];
	public datesOfBirth!:NormDate[];
	public addresses!:Location[];
	public placesOfBirth!:Location[];
	public mrz!:Mrz[];
	public identityDocuments!:IdentificationDocument[];
	public contactInformation!:ContactInformation[];
	public digitalCurrencies!:DigitalCurrency[];

	public active!:boolean;
	
	public dataset!:DataSets;
	
	public keywords!:string[];
	public sources!:string[] | MatchingSource[];
	
    public threshold!:number;
	public label!:string;
	public revision!:number;
	
	public creationDate!:string;
	public created!:string;
	public createdBy!:string;
	
	public updatedDate!:string;
	public updated!:string;
	public updatedBy!:string;

	public deleted!:string;
	public deceased!:string;

	public comments!:Comment[];
	public risks!:RiskCategory[];

	public titles!:string[];
	public additionalInformations!:string[] | MatchingField[];
}