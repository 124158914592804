import React from "react";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { File } from "../../model/file/File";
import { downloadFile } from "../../store/files/action";

interface IntProps {
    file?:File
}

interface IntState {

}

export class RowCardFile extends React.Component<IntProps, IntState> {

    public render() {
        return (
            <div className={`entity-card js-horizontal-card`}>
                    <div className="entity-card__data" style={{marginLeft:'2rem'}} onClick={this.downloadFile}>
                        <div>
                            <p  className="row-card-paragraph" style={{fontWeight: 'bold'}}>
                                {this.props.file?this.props.file.fileName:''}
                            </p>                           
                        </div>                                            
                        <p className="row-card-paragraph">
                            <span className={"o-badge o-badge--small"}>
                                {this.props.file && this.props.file.createdBy}                               
                            </span>                      
                        </p>

                        <p className="row-card-paragraph">
                            <span className={"o-badge o-badge--small"}>                               
                                 {this.props.file && this.props.file.creationDate}  
                            </span>                      
                        </p>
                    </div>                    
                </div>  
        );
    }

    private downloadFile = () => {
        if (this.props.file) {
            downloadFile(String(this.props.file.id), this.props.file.fileName);
        }
    }

}