import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { formatMessage } from "../../common/translate/Translate";
import { IRootState } from "../../store";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { getAlertExportAsCSV, getPaginatedAlert, getTotalAmountOfAlert } from "../../store/alerts/action";
import { Alert } from "../../model/alerts/Alert";
import { Person } from "../../model/persons/Person";
import { Name } from "../../model/persons/Name";
import { User } from "../../model/user/User";
import { deleteSavedSearch, getSavedSearch, saveSavedSearch, updateSavedSearch } from "../../store/savedsearch/action";
import { SavedSearchContextMenu } from "./SavedSearchContextMenu";
import { SavedSearch } from "../../model/savedsearch/SavedSearch";
import { SearchPairValue } from "../../model/savedsearch/SearchPairValue";
import { Request } from "../../model/common/Request";
import { SavedSearchNamePopup } from "./SavedSearchNamePopup";
import { AlertFieldsConstant } from "../../common/constant/AlertFieldsConstant";
import { UpdatedSearchContextMenu } from "./UpdatedSearchContextMenu";
import Select, { ActionMeta, OptionsType } from 'react-select';
import { WorkflowStatus } from "../../model/alerts/WorkflowStatus";
import { DataSets } from "../../model/dataset/DataSets";
import { Group } from "../../model/user/Group";
import { validateRadarDateFormat } from "../../common/helper/DateHelper";


interface IntProps {
    currentUser?:User,
    listOfAlertSavedSearch?:SavedSearch[],
    fullListOfDatasets?:DataSets[],
    fullListOfUsers?:User[],
    fullListOfGroups?:Group[],
    selectedWorkflowId?:number,

    savePaginationRequest?: (pagintaionRequest:PaginationRequest) => void
}

interface IntState {
    keywordSearch: string,
    alertDate1Search: string,
    alertDate2Search: string,
    alertIdSearch: string,
    alertStatusSearch: string,
    assignToGroups : number[],
    assignToUsers : number[],

    contextMenuVisible:boolean,
    contextMenuX:number,
    contextMenuY:number,

    savedSearchName:string,
    savedSearchPopupVisible:boolean,

    updatedSearchName:string,
    updatedSearchSelectedId:number,
    updatedSearchPopupVisible:boolean,
    updatedSearchContextMenuVisible:boolean,

    errors : {
        filterDate1:boolean,
        filterDate2:boolean
    }
}

const options = [
    { value: 'Open', label: formatMessage('alert_status_open') },
    { value: 'Close', label: formatMessage('alert_status_close') }
  ]

class AlertSearchClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = ({
            keywordSearch:'',
            alertDate1Search: '',
            alertDate2Search: '',
            alertIdSearch:'',
            alertStatusSearch:'',
            contextMenuVisible: false,
            contextMenuX:0,
            contextMenuY:0,
            savedSearchPopupVisible:false,
            savedSearchName:'',
            updatedSearchName:'',
            updatedSearchPopupVisible:false,
            updatedSearchSelectedId:-1,
            updatedSearchContextMenuVisible:false,
            assignToGroups:[],
            assignToUsers:[],
            errors : {
                filterDate1 : false,
                filterDate2 : false
            }
        });   
    }

    public render() {

        let optionsDatasets:{value: string;label: string;}[] = [];
        if (this.props.fullListOfDatasets) {
            for (var dataset of this.props.fullListOfDatasets) {
                optionsDatasets.push(
                    {
                        value : String(dataset.id),
                        label : dataset.label
                    }
                )
            }
        }

        let optionGroup:{value: string;label: string;}[] = [];        
        if (this.props.fullListOfGroups) {
            for (var group of this.props.fullListOfGroups) {
                optionGroup.push(
                    {
                        value : String(group.id),
                        label : group.name
                    }
                )
            }
        }

        let optionUsers:{value: string;label: string;}[] = [];        
        if (this.props.fullListOfUsers) {
            for (var user of this.props.fullListOfUsers) {
                optionUsers.push(
                    {
                        value : String(user.id),
                        label : user.userName
                    }
                )
            }
        }

        return (
            <React.Fragment>   
                <SavedSearchContextMenu x={this.state.contextMenuX} y={this.state.contextMenuY} visible={this.state.contextMenuVisible} saveSavedSearch={this.onSaveSearchPopup}/> 
                <UpdatedSearchContextMenu x={this.state.contextMenuX} y={this.state.contextMenuY} visible={this.state.updatedSearchContextMenuVisible} 
                                            saveSavedSearch={this.onSaveSearchPopup}
                                            deleteSavedSearch={this.deleteSavedSearch}
                                            renameSavedSearch={this.renameSavedSearch}
                                        />                 
                <SavedSearchNamePopup visible={this.state.savedSearchPopupVisible} savePopup={this.onSaveSearch} setName={this.setSavedSearchName} cancelPopup={this.onCancelSavedSearch}/>
                <main className="c-main overflow-hidden" style={{height: "90%", marginTop: '5px'}}>                                               
                   <div className="c-form__field" style={{height: "80%", margin: '15px'}}>                    
                            <label className="o-label title"> {formatMessage('keyword_filter')}</label>
                            {this.props.listOfAlertSavedSearch &&  this.props.listOfAlertSavedSearch.map((rowElem, j) => {                                   
                                            return (
                                                    <React.Fragment>
                                                        <dl className={`d-field--search d-field--inline`}>
                                                            <dt className="d-field__term">
                                                                <label className="o-label" onClick={this.loadSavedSearch} id={String(j)} style={{cursor:'pointer'}}>{rowElem.name}</label>
                                                            </dt>
                                                            <dl className="d-field__value">
                                                                <button id={String(rowElem.id)} className="o-more-btn o-more-btn--horizontal o-more-btn--thin" onClick={this.onUpdateSearchDisplayContextMenu}>
                                                                    ...
                                                                </button>
                                                            </dl>
                                                        </dl>
                                                    </React.Fragment>                                     
                                                );
                                }
                            )}   
                           
                            <div style={{marginTop:'5px', borderTop:'0px solid #000', paddingTop:'10px'}}>
                                <label className="o-label">{formatMessage('keyword_search')}</label>
                                <input
                                    className="o-input"
                                    name="keyword"
                                    placeholder={formatMessage('keyword_search_placeholder')}
                                    value= {this.state.keywordSearch}
                                    onChange = {this.changeKeywordSearch} 
                                    onKeyPress = {this.inputKeyPress} 
                                    style={{marginTop: '2px'}}              
                                    /> 

                                <label className="o-label">{formatMessage('alertid_search')}</label>
                                <input
                                    className="o-input"
                                    name="alertid"
                                    placeholder={formatMessage('alertid_search_placeholder')}
                                    value= {this.state.alertIdSearch}
                                    onChange = {this.changeAlertIdSearch}  
                                    onKeyPress = {this.inputKeyPress} 
                                    style={{marginTop: '2px'}}              
                                    /> 

                                <label className="o-label">{formatMessage('alertStatus_search')}</label>
                                <Select options={options}
                                        name="alertStatus"                                                                              
                                        isMulti
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={this.changeAlertStatusSearch}
                                />
                               
                                <label className="o-label">{formatMessage('datebetween_search')}</label>
                                <div style={{display : 'flex'}} >
                                    <input
                                        className={this.state.errors && this.state.errors.filterDate1 ? "o-input error" : "o-input"}
                                        name="alertDate1Search"
                                        placeholder={formatMessage('datebetween_dateformat_search')}
                                        value= {this.state.alertDate1Search}
                                        onChange = {this.handleChangeDate1}  
                                        onKeyPress = {this.inputKeyPress} 
                                        style={{marginTop: '2px', marginRight:'3px', flex : '50%'}}              
                                    /> 
                                    <input
                                        className={this.state.errors && this.state.errors.filterDate2 ? "o-input error" : "o-input"}
                                        name="alertDate2Search"
                                        placeholder={formatMessage('datebetween_dateformat_search')}
                                        value= {this.state.alertDate2Search}
                                        onChange = {this.handleChangeDate2}  
                                        onKeyPress = {this.inputKeyPress} 
                                        style={{marginTop: '2px', flex : '50%'}}              
                                    /> 
                                 </div>

                                <label className="o-label" style={{marginBottom: '5px'}} >{formatMessage('common_groups')}</label>
                                <Select name="assignToGroups"                                                                                                                     
                                                className="basic-multi-select"                        
                                                options={optionGroup}
                                                classNamePrefix="select"
                                                onChange={this.changeGroupsSelect}
                                                isMulti
                                        />

                                <label className="o-label" style={{marginBottom: '5px'}} >{formatMessage('common_users')}</label>
                                <Select name="assignToUsers"                                                                                                                     
                                        className="basic-multi-select"                        
                                        options={optionUsers}
                                        classNamePrefix="select"
                                        onChange={this.changeUsersSelect}
                                        isMulti
                                />
                        </div>
                    </div>             

                    <div style={{marginTop: '5px', width:'100%', paddingLeft:'20px'}}>
                        <button className="m-button m-button--small-margin-bottom" onClick={this.onSubmit} style={{marginTop: '5px', width:'90%'}}>
                            {formatMessage('button_search')}
                        </button>
                        <button className="m-button m-button--small-margin-bottom" onClick={this.onExport} style={{marginTop: '5px', width:'90%'}}>
                            {formatMessage('button_export')}
                        </button>      

                        <button className="m-button m-button--small-margin-bottom" onClick={this.onSaveSearchPopup} style={{marginTop: '5px', width:'90%'}}>
                            {formatMessage('button_save')}
                        </button>                                              
                    </div>
                </main>  

            </React.Fragment>
        );
    }

    private loadSavedSearch = (event: any):void => {

        if (this.props.listOfAlertSavedSearch) {
            let savedSearch:SavedSearch = this.props.listOfAlertSavedSearch[Number(event.target.id)];

            let alertIdSearch!:string;
            let alertKeywordSearch!:string;
            let alertdate1Search!:string;
            let alertdate2Search!:string;

            if (savedSearch.searchFields) {
                for (let i:number=0; i<savedSearch.searchFields.length; i++) {
                    if (savedSearch.searchFields[i].field===AlertFieldsConstant.SEARCH_ALERTID) {
                        alertIdSearch = savedSearch.searchFields[i].valueStr;
                    }

                    if (savedSearch.searchFields[i].field===AlertFieldsConstant.SEARCH_KEYWORD) {
                        alertKeywordSearch = savedSearch.searchFields[i].valueStr;
                    }

                    if (savedSearch.searchFields[i].field===AlertFieldsConstant.SEARCH_DATE1) {
                        alertdate1Search = savedSearch.searchFields[i].valueStr;
                    }

                    if (savedSearch.searchFields[i].field===AlertFieldsConstant.SEARCH_DATE1) {
                        alertdate2Search = savedSearch.searchFields[i].valueStr;
                    }
                }

                this.setState({                        
                    ...this.state,      
                    alertIdSearch: alertIdSearch?alertIdSearch:'',
                    keywordSearch: alertKeywordSearch?alertKeywordSearch:'',
                    alertDate1Search: alertdate1Search?alertdate1Search:'',
                    alertDate2Search: alertdate2Search?alertdate2Search:''
                });
            }
        }
        
    }

    private changeGroupsSelect = (values: OptionsType<{value: string;label: string;}>, actionMeta: ActionMeta<{value: string;label: string;}>):void => {        
        let groups:number[]=[]

        if (values) {
            for (var value of values) {               
                groups.push(Number(value.value));
            }
        }

        this.setState({                        
            ...this.state,  
            assignToGroups : groups                
        });

    }

    private changeUsersSelect = (values: OptionsType<{value: string;label: string;}>, actionMeta: ActionMeta<{value: string;label: string;}>):void => {        
        let users:number[]=[]

        if (values) {
            for (var value of values) {               
                users.push(Number(value.value));
            }
        }

        this.setState({                        
            ...this.state,  
            assignToUsers : users                
        });
    }

    private changeKeywordSearch = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            keywordSearch: event.target.value,
            contextMenuVisible: false
        });
    }

    private changeAlertIdSearch = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            alertIdSearch: event.target.value,
            contextMenuVisible: false
        });
    }

    private changeAlertStatusSearch = (values: OptionsType<{value: string;label: string;}>, actionMeta: ActionMeta<{value: string;label: string;}>):void => {
        

        let value:string='';

        if (values.length===1) {
            value = values[0].value;
        }

        this.setState({                        
            ...this.state,      
            alertStatusSearch: value,
            contextMenuVisible: false
        });
    }

    private handleChangeDate1  = (event: { target: { value: any; }; }):void => {
        let errors = this.state.errors;

        if (event.target.value==='' || validateRadarDateFormat(event.target.value) ) {
            errors.filterDate1 = false;
        } else {
            errors.filterDate1 = true;
        }

        this.setState({                        
            ...this.state,      
            alertDate1Search: event.target.value,
            contextMenuVisible: false,
            errors: errors
        });
    }  
    
    private handleChangeDate2  = (event: { target: { value: any; }; }):void => {
        let errors = this.state.errors;

        if (event.target.value==='' || validateRadarDateFormat(event.target.value) ) {
            errors.filterDate2 = false;
        } else {
            errors.filterDate2 = true;
        }

        this.setState({                        
            ...this.state,      
            alertDate2Search: event.target.value,
            contextMenuVisible: false,
            errors: errors
        });       
    }  

    private onButtonDisplayContextMenu = (event: any)  => {
        event.preventDefault();            
        this.setState({
            ...this.state,
            contextMenuVisible: !this.state.contextMenuVisible,
            contextMenuX: event.pageX ,
            contextMenuY: event.pageY ,           
        })
    }

    private removeAllMenu = (event: any)  => {      
        this.setState({
            ...this.state,
            contextMenuVisible: false,
            updatedSearchPopupVisible:false,
            savedSearchPopupVisible: false
        })
    }

    // Update search
    private onUpdateSearchDisplayContextMenu = (event:any) => {
        event.preventDefault();  
        this.setState({
            ...this.state,
            contextMenuX: event.pageX ,
            contextMenuY: event.pageY ,  
            contextMenuVisible: false,
            savedSearchPopupVisible: false,
            updatedSearchPopupVisible: false,
            updatedSearchSelectedId: Number(event.target.id),
            updatedSearchContextMenuVisible:!this.state.updatedSearchContextMenuVisible
        })
    }

    private deleteSavedSearch = () => {
        deleteSavedSearch(String(this.state.updatedSearchSelectedId));

        if (this.props.currentUser) {
            getSavedSearch(this.props.currentUser.id);
        } 

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            savedSearchPopupVisible: false,
            updatedSearchPopupVisible:false,
            updatedSearchContextMenuVisible:false
        })
    }

    private renameSavedSearch = () => {
        let savedSearch:SavedSearch=new SavedSearch();

        if ( this.props.listOfAlertSavedSearch) {
            for (var searchSv of this.props.listOfAlertSavedSearch) {
                if (searchSv.id === this.state.updatedSearchSelectedId) {
                    savedSearch = searchSv;
                }
            }
        }
        
        if (!savedSearch.id) {
            return;
        }

        savedSearch.name = this.state.savedSearchName;

        let request:Request = new Request();
        request.savedSearch = savedSearch;

        updateSavedSearch(request);

        if (this.props.currentUser) {
            getSavedSearch(this.props.currentUser.id);
        } 

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            savedSearchPopupVisible: false,
            updatedSearchPopupVisible:false,
            updatedSearchContextMenuVisible:false
        })
    }

    // Save Search
    private onSaveSearchPopup = (event:any) => {
        this.setState({
            ...this.state,
            contextMenuVisible: false,
            savedSearchPopupVisible: true,
            updatedSearchPopupVisible:false,
            updatedSearchContextMenuVisible:false
        })
    }

    private setSavedSearchName = (name:string) => {
        this.setState({
            ...this.state,            
            savedSearchName: name
        })
    }
 
    private onSaveSearch = () => {

        let savedSearch:SavedSearch = new SavedSearch();
        savedSearch.name = this.state.savedSearchName;
        savedSearch.searchFields = [];

        if (this.props.currentUser) {
            savedSearch.userId = this.props.currentUser?.id;
        }
        
        if (this.state.alertIdSearch!=='') {
            let searchPairValueAlertId:SearchPairValue = new SearchPairValue();
            searchPairValueAlertId.field = AlertFieldsConstant.SEARCH_ALERTID;
            searchPairValueAlertId.valueStr = this.state.alertIdSearch;
            savedSearch.searchFields.push(searchPairValueAlertId);
        }

        if (this.state.keywordSearch!=='') {
            let searchPairValueAlertKeyword:SearchPairValue = new SearchPairValue();
            searchPairValueAlertKeyword.field = AlertFieldsConstant.SEARCH_KEYWORD;
            searchPairValueAlertKeyword.valueStr = this.state.keywordSearch;
            savedSearch.searchFields.push(searchPairValueAlertKeyword);
        }

        if (this.state.alertDate1Search!=='') {
            let searchPairValueAlertDate1:SearchPairValue = new SearchPairValue();
            searchPairValueAlertDate1.field = AlertFieldsConstant.SEARCH_DATE1;
            searchPairValueAlertDate1.valueStr = this.state.alertDate1Search;
            savedSearch.searchFields.push(searchPairValueAlertDate1);
        }

        if (this.state.alertDate2Search!=='') {
            let searchPairValueAlertDate2:SearchPairValue = new SearchPairValue();
            searchPairValueAlertDate2.field = AlertFieldsConstant.SEARCH_DATE2;
            searchPairValueAlertDate2.valueStr = this.state.alertDate2Search;
            savedSearch.searchFields.push(searchPairValueAlertDate2);
        }

        if (this.state.alertStatusSearch!=='') {
            let searchPairValueAlertStatus:SearchPairValue = new SearchPairValue();
            searchPairValueAlertStatus.field = AlertFieldsConstant.SEARCH_STATUS;
            searchPairValueAlertStatus.valueStr = this.state.alertStatusSearch;
            savedSearch.searchFields.push(searchPairValueAlertStatus);
        }

        let request:Request = new Request();
        request.savedSearch = savedSearch;

        saveSavedSearch(request);

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            savedSearchPopupVisible: false          
        })
    }

    private onCancelSavedSearch = () => {
        this.setState({
            ...this.state,
            contextMenuVisible: false,
            savedSearchPopupVisible: false          
        })
    }

    private onExport = () : void => {
        let paginationRequest:PaginationRequest = new PaginationRequest();
        let alert:Alert = new Alert();
        let setAlert:boolean = false;
        if (this.state.alertIdSearch!=='') {
            alert.id = Number(this.state.alertIdSearch);
            setAlert = true;
        }

        if (this.state.keywordSearch!=='') {
            let source:Person = new Person();
            source.names = [];
            let name:Name = new Name();
            name.fullName = this.state.keywordSearch;    
            source.names.push(name);
            alert.source = source;
            setAlert = true;
        }

        if (this.state.alertDate1Search!=='') {
            paginationRequest.date1 = this.state.alertDate1Search;
            paginationRequest.dateRangeField = 'alertDTG';
            setAlert = true;
        }

        if (this.state.alertDate2Search!=='') {
            paginationRequest.date2 = this.state.alertDate2Search;
            paginationRequest.dateRangeField = 'alertDTG';
            setAlert = true;
        }

        if (this.state.alertStatusSearch!=='') {
            let wkfStatus:WorkflowStatus = new WorkflowStatus();
            if (this.state.alertStatusSearch==='Open') {
                wkfStatus.closeStatus = false;
            }

            if (this.state.alertStatusSearch==='Close') {
                wkfStatus.closeStatus = true;
            }

            alert.status = wkfStatus;
            setAlert = true;
        }

        if (setAlert) {
            paginationRequest.alert = alert;
        }        
        paginationRequest.maxPerPage = 10;
      
        getAlertExportAsCSV(paginationRequest);
    }

    private onSubmit = () : void => {
        let paginationRequest:PaginationRequest = new PaginationRequest();
        let alert:Alert = new Alert();
        let setAlert:boolean = false;
        if (this.state.alertIdSearch!=='') {
            alert.id = Number(this.state.alertIdSearch);
            setAlert = true;
        }

        if (this.state.keywordSearch!=='') {          
            paginationRequest.keyword = this.state.keywordSearch;
            
        }

        if (this.state.alertDate1Search!=='') {
            paginationRequest.date1 = this.state.alertDate1Search;
            paginationRequest.dateRangeField = 'alertDTG';
            setAlert = true;
        }

        if (this.state.alertDate2Search!=='') {
            paginationRequest.date2 = this.state.alertDate2Search;
            paginationRequest.dateRangeField = 'alertDTG';
            setAlert = true;
        }

        if (this.state.assignToUsers.length>0) {
            paginationRequest.userFilter = this.state.assignToUsers;
            setAlert = true;
        }

        if (this.state.assignToGroups.length>0) {
            paginationRequest.groupFilter = this.state.assignToGroups;
            setAlert = true;
        }

        if (this.state.alertStatusSearch!=='') {
            let wkfStatus:WorkflowStatus = new WorkflowStatus();
            if (this.state.alertStatusSearch==='Open') {
                wkfStatus.closeStatus = false;
            }

            if (this.state.alertStatusSearch==='Close') {
                wkfStatus.closeStatus = true;
            }

            alert.status = wkfStatus;
            setAlert = true;
        } else if (this.props.selectedWorkflowId && this.props.selectedWorkflowId!==-1) {
            let wkfStatus: WorkflowStatus = new WorkflowStatus();
            wkfStatus.id = this.props.selectedWorkflowId;
            alert.status = wkfStatus;
            setAlert = true;
        }

        if (setAlert) {
            paginationRequest.alert = alert;
        }        

        paginationRequest.pageNumber = 0;
        paginationRequest.maxPerPage = 10;

        if (this.props.savePaginationRequest) {
            this.props.savePaginationRequest(paginationRequest);
        }

        getTotalAmountOfAlert(paginationRequest);
        getPaginatedAlert(paginationRequest);
        
    }

    private inputKeyPress = (e:React.KeyboardEvent<HTMLInputElement>) : void => {        
        if (e.key === 'Enter') {
            this.onSubmit();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,    
    currentUser : state.userStore.currentUser,
    listOfAlertSavedSearch : state.savedSearchStore.listOfAlertSavedSearch,
    fullListOfDatasets : state.datasetStore.fullListOfDatasets,
    fullListOfUsers : state.userStore.fullListOfUsers,
    fullListOfGroups : state.groupStore.fullListOfGroups
})

export const AlertSearch = connect(mapStateToProps, null)(AlertSearchClass);
        