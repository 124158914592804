import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { IRootState } from "../../store";
import { Person } from "../../model/persons/Person";

interface IntProps {
    watchListPerson?: Person
}

interface IntState {
  
}

export class WatchlistAddInfosClass extends React.Component<IntProps, IntState> {

    public render() {
        return (
            <React.Fragment>
                {this.props.watchListPerson &&
                    <div className="d-modal__view js-modal-view">
                        <div className="grids-display">
                            <table className="c-table js-table">
                                <tbody className="c-table__body">
                                    {this.props.watchListPerson && this.props.watchListPerson.additionalInformations && this.props.watchListPerson.additionalInformations.map((rowElem, j) => {
                                        return (
                                            <tr key={'row_' + j} id={'row_' + j} className={`c-table__row js-table-row`}>
                                                <td className="c-table__data c-table__smallfont" style={{width:'80rem'}}>   
                                                    <span style={{whiteSpace:'break-spaces'}}>
                                                      {typeof rowElem === 'string' && rowElem}  
                                                      {typeof rowElem !== 'string' && rowElem.value}  
                                                    </span>                                                 
                                                </td>
                                            </tr>
                                        );
                                    }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
})

export const WatchlistAddInfos = connect(mapStateToProps, null)(WatchlistAddInfosClass);