import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { formatMessage } from "../../common/translate/Translate";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { IRootState } from "../../store";
import { Pagination } from "../../components/pagination/Pagination";
import { HeaderConstant } from "../../common/constant/HeaderConstant";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { Audit } from "../../model/audit/Audit";
import { getApplicationPaginatedAudit, getApplicationTotalAmountOfAudit, getSchedulerPaginatedAudit, getSchedulerTotalAmountOfAudit, getScreenaApplicationAudit, getScreenaApplicationAuditCount, getScreenaCrawlAudit, getScreenaCrawlAuditCount, getScreenaTaskAudit, getScreenaTaskAuditCount, getScreenaWatchListErrorLogSummaryImportAudit, getScreenaWatchListErrorLogSummaryImportAuditCount, getScreenaWatchListImportAsCSV, getScreenaWatchListSummaryImportAudit, getSegRateConfigurationAudit, getSegRateConfigurationAuditCount, getSegRateEntityRiskAudit, getSegRateEntityRiskAuditCount, getSegRateRuleHitAudit, getUserPaginatedAudit, getUserTotalAmountOfAudit } from "../../store/audit/action";
import { User } from "../../model/user/User";
import { isUserHasPermission } from "../../common/helper/PermissionHelper";
import { Permissions } from "../../common/constant/Permissions";

interface IntProps {
    isLoading?:Boolean,
    audits?:Audit[],
    totalAmountOfAudits?:number,
    screenakey?:string,
    currentUser?: User
}

interface IntState {
    paginationRequest : PaginationRequest,
    selectedRow:number,
    contextMenuX:number,
    contextMenuY:number,
    contextMenuVisible:boolean,    

    tabSelected:string
}

class AuditsClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        let paginationRequest:PaginationRequest = new PaginationRequest();
        paginationRequest.pageNumber = 0;
        paginationRequest.maxPerPage = 10;

        this.state = {
            paginationRequest : paginationRequest,
            selectedRow: -1,
            contextMenuX: -1,
            contextMenuY: -1,
            contextMenuVisible: false,
            tabSelected: HeaderConstant.GENERAL
        }

        getApplicationPaginatedAudit(this.state.paginationRequest);
        getApplicationTotalAmountOfAudit(this.state.paginationRequest);
    }

    private maxPage:number = 0;

    public render() {
        
        if (this.props.totalAmountOfAudits) {
            this.maxPage = Math.ceil(this.props.totalAmountOfAudits / this.state.paginationRequest.maxPerPage);           
        } else {
            this.maxPage = 0;
        }
        
        let header:Array<{id: string, name: string, visibleTab: string}> = this.getHeader();

        return (
            <React.Fragment> 
                <nav className="c-tabs__nav">
                    <ul className="c-tabs__list">
                        {header.map((head, index) => {
                        return (
                        <li className="c-tabs__title" key={index}>
                            <button className={`c-tabs__link js-tabs-link ${this.state.tabSelected === head.visibleTab ? 'is-active' : ''}`}
                            id={head.id}
                            name={head.id} onClick={this.changeVisibility}>
                            <span id={head.id}>{head.name}</span>
                            </button>
                        </li>
                        )
                        })}
                    </ul>
                </nav>
                <div className="audit-grids" onContextMenu={this.onGridDisplayContextMenu}>  
                    <div className="grids-display">                        
                        <table className="c-table js-table" style={{height:'100%'}}>
                            <thead className={`c-table__head`}>
                                <tr>
                                    {HeaderConstant.SCREENA_TASKS===this.state.tabSelected &&
                                        <React.Fragment>
                                            <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="type" display="common_type"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="status" display="audit_status"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="source" display="audit_task_source"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="totalCount" display="audit_task_totalcount"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="processed" display="audit_task_processed"/>                                           
                                            <HeaderColumn sortHeader={this.sortHeader} id="duration" display="audit_task_duration"/>                                            
                                            <HeaderColumn sortHeader={this.sortHeader} id="startDT" display="audit_task_start"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="endDT" display="audit_task_end"/>
                                        </React.Fragment>
                                    }

                                    {(HeaderConstant.SCREENA_IMPORT===this.state.tabSelected) &&
                                        <React.Fragment>
                                            <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="field" display="audit_field"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="value" display="audit_value"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="dataset" display="audit_task_source"/>                                            
                                            <HeaderColumn sortHeader={this.sortHeader} id="startDT" display="audit_task_start"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="endDT" display="audit_task_end"/>
                                        </React.Fragment>
                                    }

                                    {(HeaderConstant.SCREENA_WATCHLISTIMPORT===this.state.tabSelected) &&
                                        <React.Fragment>
                                            <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="record" display="audit_record"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="processed" display="audit_task_processed"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="status" display="audit_status"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="dataset" display="audit_task_source"/>                                            
                                            <HeaderColumn sortHeader={this.sortHeader} id="startDT" display="audit_task_start"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="endDT" display="audit_task_end"/>
                                        </React.Fragment>
                                    }

                                    {(HeaderConstant.SEGRATE_RULE_HIT===this.state.tabSelected) &&
                                        <React.Fragment>
                                            <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="scenario" display="audit_scenario"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="rule" display="audit_rule"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="value" display="audit_hits"/>                                            
                                        </React.Fragment>
                                    }

                                    {(HeaderConstant.SCREENA_CRAWL===this.state.tabSelected) &&
                                        <React.Fragment>
                                            <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="action" display="audit_action"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="source" display="audit_task_source"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="createdOn" display="common_createdOn"/>                                            
                                        </React.Fragment>
                                    }

                                    {(HeaderConstant.SCHEDULER_EXECUTION===this.state.tabSelected) &&
                                        <React.Fragment>
                                            <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="action" display="audit_action"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="started" display="audit_started"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="ended" display="audit_ended"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="error" display="audit_errors"/>
                                        </React.Fragment>
                                    }

                                    {HeaderConstant.SCREENA_TASKS!==this.state.tabSelected 
                                        && HeaderConstant.SCREENA_IMPORT!==this.state.tabSelected 
                                        && HeaderConstant.SCREENA_WATCHLISTIMPORT!==this.state.tabSelected 
                                        && HeaderConstant.SEGRATE_RULE_HIT!==this.state.tabSelected 
                                        && HeaderConstant.SCREENA_CRAWL!==this.state.tabSelected 
                                        && HeaderConstant.SCHEDULER_EXECUTION!==this.state.tabSelected 
                                        && <React.Fragment>
                                            <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="action" display="audit_action"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="entity" display="audit_entity"/>                                    

                                            <HeaderColumn sortHeader={this.sortHeader} id="createdBy" display="common_createdBy"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="createdOn" display="common_createdOn"/>
                                           </React.Fragment>
                                    }
                                                                                                     
                                </tr>
                            </thead>
                            <tbody className="c-table__body">
                                {this.props.audits &&  this.props.audits.map((rowElem, j) => {  
                                    if (HeaderConstant.SCREENA_TASKS===this.state.tabSelected) {
                                        return (
                                            <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} onClick={this.selectRow}
                                               >
                                                <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.action}</td>                                                                                                
                                                <td className="c-table__data c-table__smallfont">{rowElem.status}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.datasetSource}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.totalCount}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.processed}</td>                                              
                                                <td className="c-table__data c-table__smallfont">{rowElem.duration}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.startDT}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.endDT}</td> 
                                            </tr>
                                        );
                                    } else if (HeaderConstant.SCREENA_IMPORT===this.state.tabSelected) {
                                        return (
                                            <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} onClick={this.selectRow}
                                               >
                                                <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.field}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.missingValue}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.datasetSource}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.startDT}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.endDT}</td>                                                 
                                            </tr>
                                        );
                                    } else if (HeaderConstant.SCREENA_WATCHLISTIMPORT===this.state.tabSelected) {
                                        return (
                                            <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} onClick={this.selectRow}
                                               >
                                                <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.totalCount}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.processed}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.status}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.datasetSource}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.startDT}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.endDT}</td>                                                 
                                            </tr>
                                        );
                                    } else if (HeaderConstant.SEGRATE_RULE_HIT===this.state.tabSelected) {
                                        return (
                                            <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} onClick={this.selectRow}
                                               >
                                                <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.action}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.entityDescription}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.amountOfHits}</td>                                                 
                                            </tr>
                                        );
                                    } else if (HeaderConstant.SCREENA_CRAWL===this.state.tabSelected) {
                                        return (
                                            <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} onClick={this.selectRow}
                                               >
                                                <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.action}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.datasetId}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.created}</td>                                                 
                                            </tr>
                                        );
                                    } else if (HeaderConstant.SCHEDULER_EXECUTION===this.state.tabSelected) {
                                        return (
                                            <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} onClick={this.selectRow}
                                               >
                                                <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.action}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.started}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.ended}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.error}</td>
                                            </tr>
                                        );
                                    } else {
                                        return (
                                            <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} onClick={this.selectRow}
                                               >
                                                <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.action}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.entityDescription}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.createdBy}</td> 
                                                <td className="c-table__data c-table__smallfont">{rowElem.created}</td> 
                                            </tr>
                                        );
                                    }
                                       
                                    }                          
                                )}
                            </tbody>                            
                        </table> 
                    </div>
                    <div className="pagination-display">
                        {!Number.isNaN(this.maxPage) && this.maxPage>0 && HeaderConstant.SEGRATE_RULE_HIT!==this.state.tabSelected &&
                            <Pagination 
                                    changeInput={this.changeInput} 
                                    onChangeNext={this.onChangeNext} 
                                    onChangePrevious={this.onChangePrevious} 
                                    maxPage={this.maxPage} 
                                    pageNumber={this.state.paginationRequest.pageNumber}/> 
                        }
                    </div>  
                </div>  
            </React.Fragment>
        );
    }

    private downloadWatchlistAudit = () => {
        if (this.props.screenakey) {
            getScreenaWatchListImportAsCSV(this.props.screenakey);
        }        
    }

    private changeVisibility= (event: any) => {  
        let paginationRequest:PaginationRequest = this.state.paginationRequest;
        paginationRequest.pageNumber = 0;

        this.refreshAuditPage(event.target.id);
        this.setState({
            ...this.state,
            tabSelected : event.target.id,
            paginationRequest : paginationRequest
        })
    }

    private refreshAuditPage = (auditTab:string) => {
        if (auditTab === HeaderConstant.SCREENA_CRAWL && this.props.screenakey) {
            getScreenaCrawlAudit(this.state.paginationRequest, this.props.screenakey);
            getScreenaCrawlAuditCount(this.state.paginationRequest, this.props.screenakey);
        }

        if (auditTab === HeaderConstant.SCREENA_APPLICATION && this.props.screenakey) {
            getScreenaApplicationAudit(this.state.paginationRequest, this.props.screenakey);
            getScreenaApplicationAuditCount(this.state.paginationRequest, this.props.screenakey);
        }

        if (auditTab === HeaderConstant.SCREENA_WATCHLISTIMPORT && this.props.screenakey) {
            // getScreenaWatchListImportAudit(this.state.paginationRequest, this.props.screenakey);
            // getScreenaWatchListImportAuditCount(this.state.paginationRequest, this.props.screenakey);
            getScreenaWatchListSummaryImportAudit(this.props.screenakey);
            this.maxPage = Number.NaN;
        }

        if (auditTab === HeaderConstant.SCREENA_IMPORT && this.props.screenakey) {
            //getScreenaImportAudit(this.state.paginationRequest, this.props.screenakey);
            //getScreenaImportAuditCount(this.state.paginationRequest, this.props.screenakey);
            getScreenaWatchListErrorLogSummaryImportAuditCount(this.state.paginationRequest, this.props.screenakey);
            getScreenaWatchListErrorLogSummaryImportAudit(this.state.paginationRequest, this.props.screenakey);
        }

        if (auditTab === HeaderConstant.SCREENA_TASKS && this.props.screenakey) {
            getScreenaTaskAudit(this.state.paginationRequest, this.props.screenakey);
            getScreenaTaskAuditCount(this.state.paginationRequest, this.props.screenakey);
        }

        if (auditTab === HeaderConstant.USER) {
            getUserPaginatedAudit(this.state.paginationRequest);
            getUserTotalAmountOfAudit(this.state.paginationRequest);
        }

        if (auditTab === HeaderConstant.GENERAL) {
            getApplicationPaginatedAudit(this.state.paginationRequest);
            getApplicationTotalAmountOfAudit(this.state.paginationRequest);
        }

        if (auditTab === HeaderConstant.SCHEDULER_EXECUTION) {
            getSchedulerPaginatedAudit(this.state.paginationRequest);
            getSchedulerTotalAmountOfAudit(this.state.paginationRequest);
        }

        if (auditTab === HeaderConstant.SEGRATE_CONFIGURATION && this.props.screenakey) {
            getSegRateConfigurationAudit(this.state.paginationRequest, this.props.screenakey);
            getSegRateConfigurationAuditCount(this.state.paginationRequest, this.props.screenakey);
        }

        if (auditTab === HeaderConstant.SEGRATE_ENTITY_RISK && this.props.screenakey) {
            getSegRateEntityRiskAudit(this.state.paginationRequest, this.props.screenakey);
            getSegRateEntityRiskAuditCount(this.state.paginationRequest, this.props.screenakey);
        }

        if (auditTab === HeaderConstant.SEGRATE_RULE_HIT && this.props.screenakey) {
            getSegRateRuleHitAudit( this.props.screenakey);            
        }
        
    }

    private getHeader = () => {
        const header: Array<{id: string, name: string, visibleTab: string}> = [];       
        
        if (isUserHasPermission(this.props.currentUser, Permissions.AUDIT_MANAGEMENT)) {
            header.push({id:HeaderConstant.GENERAL, name: formatMessage('main_tab_header_general'), visibleTab: HeaderConstant.GENERAL});
            header.push({id:HeaderConstant.USER, name: formatMessage('audit_tab_header_user'), visibleTab: HeaderConstant.USER});
            header.push({id:HeaderConstant.SCHEDULER_EXECUTION, name: formatMessage('audit_tab_header_scheduler_execution'), visibleTab: HeaderConstant.SCHEDULER_EXECUTION});
        }

        if (isUserHasPermission(this.props.currentUser, Permissions.SCREENA_AUDITS)) { 
            //header.push({id:HeaderConstant.SCREENA_WATCHLISTIMPORT, name: formatMessage('audit_tab_header_screena_watchlist_import'), visibleTab: HeaderConstant.SCREENA_WATCHLISTIMPORT});
            //header.push({id:HeaderConstant.SCREENA_IMPORT, name: formatMessage('audit_tab_header_screena_import'), visibleTab: HeaderConstant.SCREENA_IMPORT});
            //header.push({id:HeaderConstant.SCREENA_TASKS, name: formatMessage('audit_tab_header_screena_tasks'), visibleTab: HeaderConstant.SCREENA_TASKS});        
        }

        if (isUserHasPermission(this.props.currentUser, Permissions.SEGRATE_AUDITS)) {
            header.push({id:HeaderConstant.SEGRATE_CONFIGURATION, name: formatMessage('audit_tab_header_segrate_configuration'), visibleTab: HeaderConstant.SEGRATE_CONFIGURATION});
            header.push({id:HeaderConstant.SEGRATE_ENTITY_RISK, name: formatMessage('audit_tab_header_segrate_entityrisk'), visibleTab: HeaderConstant.SEGRATE_ENTITY_RISK});
            header.push({id:HeaderConstant.SEGRATE_RULE_HIT, name: formatMessage('audit_tab_header_segrate_rulehit'), visibleTab: HeaderConstant.SEGRATE_RULE_HIT});
        }

        return header;
    }

    private onGridDisplayContextMenu = (event: any)  => {
        event.preventDefault();            
        this.setState({
            ...this.state,
            contextMenuVisible: true,
            contextMenuX: event.pageX ,
            contextMenuY: event.pageY ,
        })
    }

    private onChangeNext = () : void => {      
        if (this.state.paginationRequest.pageNumber<this.maxPage) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber+1;
            this.setState({
                ...this.state,              
                contextMenuVisible: false,
                paginationRequest:paginationRequest                
            })
        } 
        
        this.refreshAuditPage(this.state.tabSelected);
    }

    private onChangePrevious = () : void => {
        if (this.state.paginationRequest.pageNumber>0) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber-1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
        }  
        
        this.refreshAuditPage(this.state.tabSelected);
    }

    private changeInput = (e: any) => {
        const value = e;
        if (value !== '') {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = value;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
            this.refreshAuditPage(this.state.tabSelected);
          return;
        }
        if (value < 1) {
          return;
        }       
    }

    private selectRow = (e: any) => {
        const value = e.target.parentNode? e.target.parentNode.id: e.id;

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            selectedRow:Number(value)
        })
    }

    private sortHeader = (e: any) => {        
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    audits:state.auditStore.listOfAudits,
    totalAmountOfAudits: state.auditStore.amountOfAudit,
    screenakey : state.loginStore.screenaapikey,
    currentUser: state.userStore.currentUser
})

export const Audits = connect(mapStateToProps, null)(AuditsClass);


