import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { formatMessage } from "../../common/translate/Translate";
import { IRootState } from "../../store";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Alert } from "../../model/alerts/Alert";
import { Person } from "../../model/persons/Person";
import { Name } from "../../model/persons/Name";
import { User } from "../../model/user/User";
import { getSavedSearch } from "../../store/savedsearch/action";
import { SavedSearch } from "../../model/savedsearch/SavedSearch";
import { AlertFieldsConstant } from "../../common/constant/AlertFieldsConstant";
import Select, { ActionMeta, OptionsType } from 'react-select';
import { WorkflowStatus } from "../../model/alerts/WorkflowStatus";
import { getAllDatasets } from "../../store/dataset/action";
import { DataSets } from "../../model/dataset/DataSets";
import { cleanLatestSearches, getResultOfDataSetSearchCount, getResultOfDataSetSearch, getScreenaDataSetsRecord, getScreenaDataSetsRecordCount, getResultOfDataSetSearchAsCSV, getResultOfScreenaDataSetSearchAsCSV } from "../../store/searchdataset/action";
import { Request } from "../../model/common/Request";
import { MatchingData } from "../../model/screena/MatchingData";
import { MatchingName } from "../../model/screena/MatchingName";
import { DatasetConstant } from "../../common/constant/DatasetConstant";
import { Account } from "../../model/account/Account";

interface IntProps {
    currentUser?: User,
    listOfAlertSavedSearch?: SavedSearch[],
    fullListOfDatasets?: DataSets[],
    screenaKey?: string,
    selectDataset?: (datasetId: number, datasettype:string) => void;
    setPageNumber?: (pageNumber: number) => void,
    refreshDone?: () => void,
    pageNumber?: number;
    sortField?:string;
    sortOrder?:string;
    refresh?: boolean;
}

interface IntState {
    keywordSearch: string,
    alertDate1Search: string,
    alertDate2Search: string,
    idSearch: string,
    dataidSearch: string,
    alertStatusSearch: string,
    datasetIdSearch: number[],
    datasetType : string,
    datasetLabel : string,
    datasetSourceOfData: number,
    contextMenuVisible: boolean,
    contextMenuX: number,
    contextMenuY: number,

    savedSearchName: string,
    savedSearchPopupVisible: boolean,

    searchParameterChanged: boolean,

    updatedSearchName: string,
    updatedSearchSelectedId: number,
    updatedSearchPopupVisible: boolean,
    updatedSearchContextMenuVisible: boolean
}

const options = [
    { value: 'Open', label: formatMessage('alert_status_open') },
    { value: 'Close', label: formatMessage('alert_status_close') }
]

class DatasetsSearchClass extends React.Component<IntProps, IntState> {


    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = ({
            keywordSearch: '',
            alertDate1Search: '',
            alertDate2Search: '',
            idSearch: '',
            dataidSearch: '',
            datasetLabel: '',
            alertStatusSearch: '',
            contextMenuVisible: false,
            contextMenuX: 0,
            contextMenuY: 0,
            datasetIdSearch: [],
            datasetSourceOfData: 0,
            datasetType: '',
            savedSearchPopupVisible: false,
            savedSearchName: '',
            searchParameterChanged: false,
            updatedSearchName: '',
            updatedSearchPopupVisible: false,
            updatedSearchSelectedId: -1,
            updatedSearchContextMenuVisible: false,
        });

        if (this.props.currentUser) {
            getSavedSearch(this.props.currentUser.id);
        }

        getAllDatasets();
    }


    private currentPageNumber: number = -1;
    private currentSortField: string = '';
    private currentSortOrder: string = '';

    public render() {

        let optionsDatasets: { value: string; label: string; sourceOfData:number; datasetType:string; }[] = [];
        if (this.props.fullListOfDatasets) {
            for (var dataset of this.props.fullListOfDatasets) {
                optionsDatasets.push(
                    {
                        value: String(dataset.id),
                        label: dataset.label,
                        sourceOfData: dataset.sourceOfData,
                        datasetType: dataset.type
                    }
                )
            }
        }

        if (this.currentPageNumber !== this.props.pageNumber) {

            if (this.props.pageNumber) {
                this.currentPageNumber = this.props.pageNumber;
            } else {
                this.currentPageNumber = 0;
            }
           this.onSubmit();
        }

        if (this.props.sortField) {
            let dosubmit:boolean = false;
            if (this.props.sortField!==this.currentSortField) {
                this.currentSortField = this.props.sortField;
                dosubmit = true;
            }

            if (this.props.sortOrder && this.props.sortOrder!==this.currentSortOrder) {
                this.currentSortOrder = this.props.sortOrder;
                dosubmit = true;
            }

            if (dosubmit) {
                this.onSubmit();
            }
        }

        if (this.props.refresh && this.props.refresh===true) {
            this.onSubmit();
        }

        return (
            <React.Fragment>
                <main className="c-main overflow-hidden" style={{ height: "100%", marginTop: '5px' }}>
                    <div className="c-form__field" style={{ height: "80%", margin: '15px' }}>
                        <div className="d-modal__head">
                            <legend className="d-modal__title" onClick={this.removeAllMenu}>
                                {formatMessage('dataset_search_title')}
                            </legend>
                           
                        </div>
                        {this.props.listOfAlertSavedSearch && this.props.listOfAlertSavedSearch.map((rowElem, j) => {
                            return (
                                <React.Fragment>
                                    <dl className={`d-field--search d-field--inline`}>
                                        <dt className="d-field__term">
                                            <label className="o-label" onClick={this.loadSavedSearch} id={String(j)}>{rowElem.name}</label>
                                        </dt>
                                        <dl className="d-field__value">
                                            <button id={String(rowElem.id)} className="o-more-btn o-more-btn--horizontal o-more-btn--thin" onClick={this.onUpdateSearchDisplayContextMenu}>
                                                ...
                                            </button>
                                        </dl>
                                    </dl>
                                </React.Fragment>
                            );
                        }
                        )}

                        <label className="o-label">{formatMessage('datasets_search')}</label>
                        <Select options={optionsDatasets}
                            name="datasetSelect"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.changeDatasetSearch}
                        />

                        <div style={{ marginTop: '5px', borderTop: '2px solid #000', paddingTop: '10px' }}>
                            <label className="o-label">{formatMessage('keyword_search')}</label>
                            <input
                                className="o-input"
                                name="keyword"
                                placeholder={formatMessage('keyword_search_placeholder')}
                                value={this.state.keywordSearch}
                                onChange={this.changeKeywordSearch}
                                onKeyPress={this.inputKeyPress}
                                style={{ marginTop: '2px' }}
                            />

                            {this.state.datasetType!==DatasetConstant.WATCHLIST &&
                                <React.Fragment>
                                    <label className="o-label">{formatMessage('common_id')}</label>
                                    <input
                                        className="o-input"
                                        name="idSearch"
                                        placeholder={formatMessage('common_id_placeholder')}
                                        value={this.state.idSearch}
                                        onChange={this.changeIdSearch}
                                        onKeyPress={this.inputKeyPress}
                                        style={{ marginTop: '2px' }}
                                    />
                                </React.Fragment>
                            }

                            <label className="o-label">{formatMessage('common_dataid')}</label>
                            <input
                                className="o-input"
                                name="dataidSearch"
                                placeholder={formatMessage('common_dataid_placeholder')}
                                value={this.state.dataidSearch}
                                onChange={this.changeDataIdSearch}
                                onKeyPress={this.inputKeyPress}
                                style={{ marginTop: '2px' }}
                            />

                            {this.state.datasetSourceOfData===DatasetConstant.ALERTS &&
                                <React.Fragment>
                                    <label className="o-label">{formatMessage('alertStatus_search')}</label>
                                    <Select options={options}
                                        name="alertStatus"
                                        isMulti
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={this.changeAlertStatusSearch}
                                    />
                                </React.Fragment>
                            }

                            <label className="o-label">{formatMessage('datebetween_search')}</label>
                            <div style={{ display: 'flex' }} >
                                <input
                                    className="o-input"
                                    name="alertDate1Search"
                                    placeholder={formatMessage('datebetween_dateformat_search')}
                                    value={this.state.alertDate1Search}
                                    onChange={this.handleChangeDate1}
                                    onKeyPress={this.inputKeyPress}
                                    style={{ marginTop: '2px', flex: '50%' }}
                                />
                                <input
                                    className="o-input"
                                    name="alertDate2Search"
                                    placeholder={formatMessage('datebetween_dateformat_search')}
                                    value={this.state.alertDate2Search}
                                    onChange={this.handleChangeDate2}
                                    onKeyPress={this.inputKeyPress}
                                    style={{ marginTop: '2px', flex: '50%' }}
                                />
                            </div>
                        </div>
                    </div>
                    <button className="m-button m-button--small-margin-bottom" onClick={this.onSubmit} style={{ marginTop: '5px' }}>
                        {formatMessage('button_search')}
                    </button>
                    {this.state.datasetType!==DatasetConstant.WATCHLIST && this.state.datasetSourceOfData!==DatasetConstant.ALERTS &&
                        <button className="m-button m-button--small-margin-bottom" onClick={this.onExport} style={{ marginTop: '5px' }}>
                            {formatMessage('button_export')}
                        </button>
                    }
                </main>

            </React.Fragment>
        );
    }

    private loadSavedSearch = (event: any): void => {
        if (this.props.listOfAlertSavedSearch) {
            let savedSearch: SavedSearch = this.props.listOfAlertSavedSearch[Number(event.target.id)];

            let alertIdSearch!: string;
            let alertKeywordSearch!: string;
            let alertdate1Search!: string;
            let alertdate2Search!: string;

            if (savedSearch.searchFields) {
                for (let i: number = 0; i < savedSearch.searchFields.length; i++) {
                    if (savedSearch.searchFields[i].field === AlertFieldsConstant.SEARCH_ALERTID) {
                        alertIdSearch = savedSearch.searchFields[i].valueStr;
                    }

                    if (savedSearch.searchFields[i].field === AlertFieldsConstant.SEARCH_KEYWORD) {
                        alertKeywordSearch = savedSearch.searchFields[i].valueStr;
                    }

                    if (savedSearch.searchFields[i].field === AlertFieldsConstant.SEARCH_DATE1) {
                        alertdate1Search = savedSearch.searchFields[i].valueStr;
                    }

                    if (savedSearch.searchFields[i].field === AlertFieldsConstant.SEARCH_DATE1) {
                        alertdate2Search = savedSearch.searchFields[i].valueStr;
                    }
                }

                this.setState({
                    ...this.state,
                    idSearch: alertIdSearch ? alertIdSearch : '',
                    keywordSearch: alertKeywordSearch ? alertKeywordSearch : '',
                    alertDate1Search: alertdate1Search ? alertdate1Search : '',
                    alertDate2Search: alertdate2Search ? alertdate2Search : ''
                });
            }
        }

    }

    private changeKeywordSearch = (event: { target: { value: any; }; }): void => {
        this.setState({
            ...this.state,
            keywordSearch: event.target.value,
            searchParameterChanged: true,
            contextMenuVisible: false
        });
    }

    private changeIdSearch = (event: { target: { value: any; }; }): void => {
        this.setState({
            ...this.state,
            idSearch: event.target.value,
            searchParameterChanged: true,
            contextMenuVisible: false
        });
    }

    private changeDataIdSearch = (event: { target: { value: any; }; }): void => {
        this.setState({
            ...this.state,
            dataidSearch: event.target.value,
            searchParameterChanged: true,
            contextMenuVisible: false
        });
    }

    private changeAlertStatusSearch = (values: OptionsType<{ value: string; label: string; }>, actionMeta: ActionMeta<{ value: string; label: string; }>): void => {
        let value: string = '';

        if (values.length === 1) {
            value = values[0].value;
        }

        this.setState({
            ...this.state,
            alertStatusSearch: value,
            searchParameterChanged: true,
            contextMenuVisible: false
        });
    }

    private changeDatasetSearch = (value: { value: string; label: string; sourceOfData:number; datasetType:string} | null): void => {

        let datasetIdSearch: number[] = [];
        datasetIdSearch.push(Number(value?.value));

        let sourceOfData:number = 0;
        if (value?.sourceOfData) {
            sourceOfData = value?.sourceOfData;
        }

        let datasetType:string =''
        if (value?.datasetType) {
            datasetType = value?.datasetType;
        }

        let datasetLabel:string =''
        if (value?.label) {
            datasetLabel = value?.label;
        }

        if (this.props.selectDataset) {
            this.props.selectDataset(datasetIdSearch[0], datasetType);
        }

        cleanLatestSearches();
        let paginationRequest: PaginationRequest = new PaginationRequest();
        let person: Person = new Person();
        let alert: Alert = new Alert();
        let account: Account = new Account();

        let screenaDataSet: DataSets[] = [];

        let matchingData: MatchingData = new MatchingData();
        let datasetScreena: DataSets = new DataSets();

        if (datasetIdSearch && datasetIdSearch.length > 0) {
            let searchDataset : DataSets = new DataSets();
            searchDataset.id = Number(datasetIdSearch);
            if (DatasetConstant.ALERTS===sourceOfData) {
                alert.dataset = searchDataset ;
            } else  if (DatasetConstant.ACCOUNT===sourceOfData) {
                account.dataset = searchDataset;
            } else {
                person.dataset = searchDataset;
                datasetScreena.label = datasetLabel;
                datasetScreena.records = [];
            }
        } else {            
            return;
        }

        paginationRequest.maxPerPage = 10;
        paginationRequest.pageNumber = 0;

       if (this.props.setPageNumber) {
          this.props.setPageNumber(0);
       }
       
       if (datasetType==='Watchlist' && this.props.screenaKey) {
            let request: Request = new Request();
            request.paginationRequest = paginationRequest;
            datasetScreena.records=[];
            datasetScreena.records.push(matchingData);

            screenaDataSet.push(datasetScreena);
            request.datasets = screenaDataSet;

            getScreenaDataSetsRecordCount(request, this.props.screenaKey);
            getScreenaDataSetsRecord(request, this.props.screenaKey);

        } else {
            if (DatasetConstant.ALERTS===sourceOfData) {
                paginationRequest.alert = alert;
            } else  if (DatasetConstant.ACCOUNT===sourceOfData) {
                paginationRequest.account = account;
            } else {
                paginationRequest.person = person;
            }

            getResultOfDataSetSearch(paginationRequest, sourceOfData);
            getResultOfDataSetSearchCount(paginationRequest, sourceOfData);

        }
        
        this.setState({
            ...this.state,
            datasetIdSearch: datasetIdSearch,
            datasetSourceOfData: sourceOfData,
            datasetType: datasetType,
            datasetLabel : datasetLabel,
            searchParameterChanged: false,
            contextMenuVisible: false,

            keywordSearch: '',
            alertDate1Search: '',
            alertDate2Search: '',
            idSearch: '',
            dataidSearch: '',
        });
    }

    private handleChangeDate1 = (event: { target: { value: any; }; }): void => {
        this.setState({
            ...this.state,
            alertDate1Search: event.target.value,
            searchParameterChanged: true,
            contextMenuVisible: false
        });
    }

    private handleChangeDate2 = (event: { target: { value: any; }; }): void => {
        this.setState({
            ...this.state,
            alertDate2Search: event.target.value,
            searchParameterChanged: true,
            contextMenuVisible: false
        });
    }

    private onButtonDisplayContextMenu = (event: any) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            contextMenuVisible: !this.state.contextMenuVisible,
            contextMenuX: event.pageX,
            contextMenuY: event.pageY,
        })
    }

    private removeAllMenu = (event: any) => {
        this.setState({
            ...this.state,
            contextMenuVisible: false,
            updatedSearchPopupVisible: false,
            savedSearchPopupVisible: false
        })
    }

    // Update search
    private onUpdateSearchDisplayContextMenu = (event: any) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            contextMenuX: event.pageX,
            contextMenuY: event.pageY,
            contextMenuVisible: false,
            savedSearchPopupVisible: false,
            updatedSearchPopupVisible: false,
            updatedSearchSelectedId: Number(event.target.id),
            updatedSearchContextMenuVisible: !this.state.updatedSearchContextMenuVisible
        })
    }

    private onSubmit = (): void => {
        if (this.props.refreshDone) {
            this.props.refreshDone();
        }
        
        this.onSubmitSearch(false);
    }

    private onExport = (): void => {
        this.onSubmitSearch(true);
    }

    private onSubmitSearch = (exportAsCsv:boolean): void => {
        cleanLatestSearches();
        let paginationRequest: PaginationRequest = new PaginationRequest();
        let person: Person = new Person();
        let alert: Alert = new Alert();
        let account: Account = new Account();

        let screenaDataSet: DataSets[] = [];

        let matchingData: MatchingData = new MatchingData();
        let datasetScreena: DataSets = new DataSets();

        if (this.state.datasetIdSearch && this.state.datasetIdSearch.length > 0) {
            let searchDataset : DataSets = new DataSets();
            searchDataset.id = Number(this.state.datasetIdSearch);
            if (DatasetConstant.ALERTS===this.state.datasetSourceOfData) {
                alert.dataset = searchDataset ;
            } else  if (DatasetConstant.ACCOUNT===this.state.datasetSourceOfData) {
                account.dataset = searchDataset;
            } else {
                person.dataset = searchDataset;
                datasetScreena.label = this.state.datasetLabel;
                datasetScreena.records = [];

            }
        } else {            
            return;
        }

        if (this.state.idSearch !== '') {
            person.id = Number(this.state.idSearch);
            account.id = Number(this.state.idSearch);
            alert.id = Number(this.state.idSearch);
            matchingData.id = this.state.idSearch;
        }

        if (this.state.dataidSearch !== '') {
            person.dataID = this.state.dataidSearch;
            account.dataId = this.state.dataidSearch;
            matchingData.dataID = this.state.dataidSearch;
        }

        if (this.state.keywordSearch !== '') {
            if (DatasetConstant.ALERTS===this.state.datasetSourceOfData) {
                paginationRequest.keyword=this.state.keywordSearch;
            } else  if (DatasetConstant.ACCOUNT===this.state.datasetSourceOfData) {
                account.fullAccountNumber = this.state.keywordSearch;
             account.accountNumber = this.state.keywordSearch;
            } else {
                person.names = [];
                let name: Name = new Name();
                name.fullName = this.state.keywordSearch;
                person.names.push(name);

                // Matching Data
                matchingData.names = [];
                let matchingName : MatchingName = new MatchingName();
                matchingName.fullName = this.state.keywordSearch;
                matchingData.names.push(matchingName);
            }
            
        }

        if (this.state.alertDate1Search !== '') {
            paginationRequest.date1 = this.state.alertDate1Search;            
        }

        if (this.state.alertDate2Search !== '') {
            paginationRequest.date2 = this.state.alertDate2Search;
        }

        if (this.state.alertDate2Search !== '' || this.state.alertDate1Search !== '') {
            if (DatasetConstant.ALERTS===this.state.datasetSourceOfData) {
                paginationRequest.dateRangeField = 'alertDTG';
            } else {            
                paginationRequest.dateRangeField = 'updateDtg';
            }
        }

        if (this.state.alertStatusSearch !== '') {
            let wkfStatus: WorkflowStatus = new WorkflowStatus();
            if (this.state.alertStatusSearch === 'Open') {
                wkfStatus.closeStatus = false;
            }

            if (this.state.alertStatusSearch === 'Close') {
                wkfStatus.closeStatus = true;
            }

            alert.status = wkfStatus;
        }

        paginationRequest.maxPerPage = 10;

        if (this.props.pageNumber && !this.state.searchParameterChanged) {
            paginationRequest.pageNumber = this.props.pageNumber;
        } else if (this.props.pageNumber) {
           
            paginationRequest.pageNumber = 0;

            if (this.props.setPageNumber) {
                this.props.setPageNumber(0);
            }           
        }

        if (this.currentSortField!=='') {
            paginationRequest.sortField = this.currentSortField;
        }

        if (this.currentSortOrder!=='') {
            paginationRequest.sortOrder = this.currentSortOrder;
        }

        if (this.state.datasetType==='Watchlist' && this.props.screenaKey) {
            let request: Request = new Request();
            request.paginationRequest = paginationRequest;
            datasetScreena.records=[];
            datasetScreena.records.push(matchingData);

            screenaDataSet.push(datasetScreena);
            request.datasets = screenaDataSet;

            getScreenaDataSetsRecordCount(request, this.props.screenaKey);
            getScreenaDataSetsRecord(request, this.props.screenaKey);

            if (exportAsCsv) {
                getResultOfScreenaDataSetSearchAsCSV(datasetScreena.label, this.props.screenaKey);
            }

        } else {
            if (DatasetConstant.ALERTS===this.state.datasetSourceOfData) {
                paginationRequest.alert = alert;
            } else  if (DatasetConstant.ACCOUNT===this.state.datasetSourceOfData) {
                paginationRequest.account = account;
            } else {
                paginationRequest.person = person;
            }

            getResultOfDataSetSearch(paginationRequest, this.state.datasetSourceOfData);
            getResultOfDataSetSearchCount(paginationRequest, this.state.datasetSourceOfData);

            if (exportAsCsv) {
                getResultOfDataSetSearchAsCSV(paginationRequest);
            }
        }

        this.setState({
            ...this.state,
            searchParameterChanged: false,            
        });

    }

    private inputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            this.onSubmit();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
    currentUser: state.userStore.currentUser,
    fullListOfDatasets: state.datasetStore.fullListOfDatasets,
    screenaKey: state.loginStore.screenaapikey
})

export const DatasetsSearch = connect(mapStateToProps, null)(DatasetsSearchClass);
