import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { Person } from "../../model/persons/Person";
import { IRootState } from "../../store";
import { Pagination } from "../../components/pagination/Pagination";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { Transaction } from "../../model/transactions/Transaction";
import { getPaginatedTransactionsLinkedToPerson } from "../../store/transactions/action";

interface IntProps {
    person?:Person,
    transactions?:Transaction[],
    totalAmountOfTransactions?:number
}

interface IntState {
    paginationRequest : PaginationRequest,
}

export class CustomerTransactionsClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);
        
        let paginationRequest:PaginationRequest = new PaginationRequest();
        paginationRequest.pageNumber = 0;
        paginationRequest.maxPerPage = 10;

        this.state = {
            paginationRequest : paginationRequest          
        }
    }

    private maxPage:number = 0;


     public render() {   
        if (this.props.totalAmountOfTransactions) {
            this.maxPage = Math.ceil(this.props.totalAmountOfTransactions / this.state.paginationRequest.maxPerPage);           
        } else {
            this.maxPage = 0;
        } 
        
        return ( 
                <React.Fragment>   
                    {this.props.person &&
                         <div className="d-modal__view js-modal-view">
                           <div className="grids-display">
                                <table className="c-table js-table">
                                    <thead className={`c-table__head`}>
                                        <tr>
                                            <HeaderColumn sortHeader={this.sortHeader} id="transactionId" display="common_id"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="transactionDate" display="common_date"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="transactionDataId" display="common_dataid"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="transactionType" display="common_type"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="transactionBaseAmount" display="transaction_baseamount"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="transactionLocalAmount" display="transaction_localamount"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="transactionOriginalAmount" display="transaction_originalamount"/>
                                        </tr>
                                    </thead>
                                    <tbody className="c-table__body">
                                        {this.props.transactions &&  this.props.transactions.map((rowElem, j) => {                                   
                                                return (
                                                        <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row`}>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.transactionDate}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.dataId}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.transactionType}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.baseAmount}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.localAmount}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.originalAmount}</td>
                                                        </tr>
                                                    );
                                            }                          
                                        )}
                                    </tbody>                            
                                </table> 
                        </div>
                        <div className="pagination-display">
                            {!Number.isNaN(this.maxPage) && this.maxPage>0 && 
                                    <Pagination 
                                                changeInput={this.changeInput} 
                                                onChangeNext={this.onChangeNext} 
                                                onChangePrevious={this.onChangePrevious} 
                                                maxPage={this.maxPage} 
                                                pageNumber={this.state.paginationRequest.pageNumber}/>     
                            } 
                        </div> 
                    </div>  
                    }                  
                </React.Fragment>
        );
    }

    private onChangeNext = () : void => {      
        if (this.state.paginationRequest.pageNumber<this.maxPage) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber+1;
            this.setState({
                ...this.state,              
                paginationRequest:paginationRequest                
            })
            getPaginatedTransactionsLinkedToPerson(paginationRequest);
        }        
    }

    private onChangePrevious = () : void => {
        if (this.state.paginationRequest.pageNumber>0) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber-1;
            this.setState({
                ...this.state,
                paginationRequest:paginationRequest
            })
            getPaginatedTransactionsLinkedToPerson(paginationRequest);
        }        
    }

    private changeInput = (e: any) => {
        const value = e;
        if (value !== '') {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = value;
            this.setState({
                ...this.state,
                paginationRequest:paginationRequest
            })
            getPaginatedTransactionsLinkedToPerson(paginationRequest);
          return;
        }
        if (value < 1) {
          return;
        }       
      }

      private sortHeader = (e: any) => {

        let paginationRequest:PaginationRequest = this.state.paginationRequest;

        let previousFieldOrder:string = paginationRequest.sortField;
       
        if (paginationRequest.sortField===previousFieldOrder) {
            if (paginationRequest.sortOrder==='DESC') {
                paginationRequest.sortOrder = 'ASC';    
            } else {
                paginationRequest.sortOrder = 'DESC';
            }
        } else {
            paginationRequest.sortOrder = 'DESC';
        }
        
        this.setState({
                ...this.state,
                paginationRequest:paginationRequest
        })
        getPaginatedTransactionsLinkedToPerson(paginationRequest);
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    totalAmountOfTransactions : state.transactionStore.amountOfTransactions,
    transactions : state.transactionStore.listOfTransactions,
    person : state.personStore.currentPerson
})

export const CustomerTransactions = connect(mapStateToProps, null)(CustomerTransactionsClass);