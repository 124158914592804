import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { Person } from "../../model/persons/Person";
import { IRootState } from "../../store";
import { HeaderColumn } from "../../components/grid/HeaderColumn";

interface IntProps {
    person?:Person,
    totalAmountOfPersons?:number
}

interface IntState {
    
}

export class CustomerRisksClass extends React.Component<IntProps, IntState> {

    public render() {   
       
        return ( 
                <React.Fragment>   
                    {this.props.person &&
                        <div className="grids-display">
                            <table className="c-table js-table">
                                <thead className={`c-table__head`}>
                                    <tr>
                                        <HeaderColumn sortHeader={this.sortHeader} id="category" display="risk_category"/>
                                        <HeaderColumn sortHeader={this.sortHeader} id="score" display="risk_score"/>                                        
                                    </tr>
                                </thead>
                                <tbody className="c-table__body">
                                    {this.props.person && this.props.person.risks &&  this.props.person.risks.map((rowElem, j) => {
                                            return (
                                                    <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row`}>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.category}</td>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.weightedScore}</td>            
                                                    </tr>
                                                );
                                        }
                                    )}
                                </tbody>                            
                        </table>                       
                        </div>
                    }                  
                </React.Fragment>
        );
    }

    private sortHeader = (e: any) => {
        
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,    
    person : state.personStore.currentPerson,
})

export const CustomerRisks = connect(mapStateToProps, null)(CustomerRisksClass);